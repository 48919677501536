import { Injectable } from '@angular/core';
import Speech from 'speak-tts';
import { Platform } from '@angular/cdk/platform';
import { AppLocalStorage } from 'src/app/util/app-local-storage-utils';

@Injectable({
  providedIn: 'root'
})
export class SpeechService {

  speech: any;
  language: any;
  isEdgeBrowser: boolean;

  public allowAudio: boolean = false;

  constructor(private platform: Platform) {
    this.isEdgeBrowser = this.platform.EDGE; // or IOS, SAFARI, ANDROID, etc.
  }

  async init() {
    this.speech = await new Speech()
    if (this.speech.hasBrowserSupport()) {
      await this.speech.init({
        'volume': 1,
        'lang': 'en-US',
        'rate': 1,
        'pitch': 1,
        'splitSentences': true,
        'listeners': {
          'onvoiceschanged': (voices) => {
            // console.log("Event voiceschanged", voices)
          }
        }
      }).catch(e => {
        console.error("An error occured while initializing : ", e)
      })
    }
  }

  async read(data: any) {
    if (this.allowAudio) {
      await this.setLanguage();
      await this.chooseVoice();
      await this.speech.speak({
        text: data,
      }).catch(e => {
        console.error("An error occurred :", e)
      })
    }
  }

  async setLanguage() {
    this.language = AppLocalStorage.getLanguage();
    if (this.language === "ar") {
      this.speech.setLanguage('ar-SA');
    } else if (this.language === "ru") {
      this.speech.setLanguage('ru-RU');
    } else {
      this.speech.setLanguage('en-US');
    }
  }

  async stopRead() {
    try {
      this.speech.cancel();
    } catch (e) { }
  }

  async chooseVoice() {
    if (this.language === "ar") {
      try {
        await this.speech.setVoice("Majed");
      } catch (error) {
        // console.error("Error setting voice for Arabic: ", error);
        try {
          await this.speech.setVoice("Microsoft Salma Online (Natural) - Arabic (Egypt)");
        } catch (error) {
          // console.error("Error setting voice for Arabic: ", error);
          await this.speech.setVoice("default voice for Arabic");
        }
      }
    } else if (this.language === "ru") {
      try {
        await this.speech.setVoice("Google русский");
      } catch (error) {
        // console.error("Error setting voice for Russian: ", error);
        try {
          await this.speech.setVoice("Microsoft Dmitry Online (Natural) - Russian (Russia)");
        } catch (error) {
          // console.error("Error setting voice for Russian: ", error);
          await this.speech.setVoice("default voice for Russian");
        }
      }
    } else {
      try {
        await this.speech.setVoice("Google US English");
      } catch (error) {
        // console.error("Error setting voice for English: ", error);
        try {
          await this.speech.setVoice("Microsoft Guy Online (Natural) - English (United States)");
        } catch (error) {
          // console.error("Error setting voice for English: ", error);
          await this.speech.setVoice("default voice for English");
        }
      }
    }
  }

  async chooseVoice2() {
    try {
      if (this.language === "ar") {
        await this.speech.setVoice("Majed");
      } else if (this.language === "ru") {
        await this.speech.setVoice("Google русский" || "Microsoft Ekaterina Online - Russian (Russia)");
      } else {
        await this.speech.setVoice("Google UK English Male") || await this.speech.setVoice("Microsoft Guy Online (Natural) - English (United States)");
      }

    } catch (error) {
      if (this.language === "ar") {
        await this.speech.setVoice("Majed");
      } else if (this.language === "ru") {
        await this.speech.setVoice("Google русский" || "Microsoft Ekaterina Online - Russian (Russia)");
      } else {
        await this.speech.setVoice("Google UK English Male" || "Microsoft Guy Online (Natural) - English (United States)");
      }
    }
  }

}
