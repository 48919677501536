<div class="full-height">
	<div class="full-height filters-and-table-wrapper">
		<div class="filter-content">
			<!-- <app-alarms-filter [selectedAlarmHistory]="selectedAlarmHistory" (changeHeight)="changeTableSize()">
			</app-alarms-filter> -->
		</div>
		<div class="alarms-row">
			<div class="alarms-content">
				<div class="alarm-history">
					<div class="table-container" id="table-container">
						<table mat-table aria-describedby="alarm-history" matSort [dataSource]="alarmHistoryDataSource"
							*ngIf="alarmHistoryDataSource && alarmHistoryDataSource.length > 0; else noActions">
							<ng-container matColumnDef="select">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
									<input class="checkbox-input select-all" type="checkbox" [checked]="resetChecked"
										(change)='checkAllAlarms()'>
								</th>
								<td mat-cell *matCellDef="let alarmsHistory"><input class="checkbox-input" type="checkbox"
										[checked]="selectAllAlarms" (change)='onCheckboxChange()'></td>
							</ng-container>

							<ng-container matColumnDef="alarmId">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> {{
									"ANALYTICAL_ALARM_HISTORY.ALARM_ID" | translate}}
								</th>
								<td mat-cell class="alarmId-column" *matCellDef="let alarmsHistory">
									{{alarmsHistory.shortAlarmId || alarmsHistory.shortAlarmId}}</td>
							</ng-container>
							<ng-container matColumnDef="Alarm Types">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> {{
									"ANALYTICAL_ALARM_HISTORY.TYPE" | translate}}
								</th>
								<td mat-cell class="alarmType-column" *matCellDef="let alarmsHistory"
									matTooltip="{{alarmsHistory.alarmType}}"> {{alarmsHistory.alarmType}} </td>
							</ng-container>
							<ng-container matColumnDef="Sites">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{
									"ANALYTICAL_ALARM_HISTORY.SITE" | translate}}
								</th>
								<td mat-cell class="sites-column" *matCellDef="let alarmsHistory"> {{alarmsHistory.branch.branchName}}
								</td>
							</ng-container>
							<ng-container matColumnDef="Operator">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> {{
									"ANALYTICAL_ALARM_HISTORY.ASSIGNED_TO" | translate}}
								</th>
								<td mat-cell class="assignedTo-column" *matCellDef="let alarmsHistory"
									matTooltip="{{getOperatorName(alarmsHistory)}}">
									{{getOperatorName(alarmsHistory)}}</td>
							</ng-container>
							<ng-container matColumnDef="Severity">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> {{
									"ANALYTICAL_ALARM_HISTORY.SEVERITY" | translate}}</th>
								<td mat-cell class="severity-column" *matCellDef="let alarmsHistory"> {{alarmsHistory.severity}} </td>
							</ng-container>
							<ng-container matColumnDef="timeAndDate">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> {{
									"ANALYTICAL_ALARM_HISTORY.TIME_AND_DATE" | translate}}</th>
								<td mat-cell class="incidentTime-column" *matCellDef="let alarmsHistory">
									{{getFormatedTime(alarmsHistory?.activationTime,appConstants.DATE_TIME_FORMAT)}}
								</td>
							</ng-container>
							<!-- Last Action Time Column -->
							<ng-container matColumnDef="lastActionTime">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> {{
									"ANALYTICAL_ALARM_HISTORY.LAST_ACTION_TIME" | translate}}</th>
								<td mat-cell class="lastActionTime-column" *matCellDef="let alarmsHistory">
									{{getFormatedTime(alarmsHistory?.lastactiontime,appConstants.DATE_TIME_FORMAT)}}
								</td>
							</ng-container>
							<ng-container matColumnDef="status">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> {{
									"ANALYTICAL_ALARM_HISTORY.STATUS" | translate}}</th>
								<td mat-cell class="status-column" *matCellDef="let alarmsHistory"> {{alarmsHistory.status}} </td>
							</ng-container>
							<ng-container matColumnDef="ViewDetails">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
								</th>
								<td align="center" mat-cell class="details-column" *matCellDef="let element">
									<a [href]="" (click)="getAlarmInfo(element.alarmId)" class="report-icon">
										<img class="view-icon" alt="file" [src]="utility.getImagePath('view.png')">
									</a>
								</td>
							</ng-container>
							<ng-container matColumnDef="chat">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
								</th>
								<td align="center" mat-cell class="details-column" *matCellDef="let element">
									<a [href]="" (click)="getChatHistory(element)" class="report-icon">
										<img class="chat-icon" alt="chat" [src]="utility.getImagePath('chat.png')">
									</a>
								</td>
							</ng-container>
							<ng-container matColumnDef="Report">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
								</th>
								<td align="center" mat-cell class="report-column" *matCellDef="let element">
									<a [href]="" (click)="getAlarmReport(element.alarmId)" class="report-icon">
										<img class="file-icon" alt="file" [src]="utility.getImagePath('file.png')">
									</a>
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"
								[ngClass]="{'highlight': selectedAlarmHistoryId == row.shortAlarmId}" (click)="highlight(row)"></tr>
						</table>
						<ng-template #noActions>
							<div class="box-content-no-data">
								{{'ALARM_HISTORY.NO_DATA' | translate}}
							</div>
						</ng-template>
					</div>
					<mat-paginator [hidden]="!(alarmHistoryDataSource && alarmHistoryDataSource.length > 0)"
						id="pagination-container" class="report-paginator analytical-dashboard-paginator"
						[length]="analyticalDashboard.alarmCount" [pageSizeOptions]="appConstants.PAGINATION_OPTION"
						(page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
				</div>
			</div>
		</div>
	</div>
	<div class="alarms-map" *ngIf="!chatAlarm">
		<app-map (doneLoading)="mapLoaded()"></app-map>
	</div>

	<div class="chat-users" *ngIf="chatAlarm">
		<app-chat-users [alarm]=chatAlarm (ChatBoxCancelled)="showMap()"></app-chat-users>
	</div>
</div>