<mat-accordion [class.set-alarm-position]="closeAll">
	<mat-expansion-panel [expanded]="true" class="expansion-box" (opened)="opened()" (closed)="closed()">
		<mat-expansion-panel-header class="header">
			<mat-panel-title>
				<div class="box-title d-flex align-items-center">
					<div class="box-title-icon d-flex align-items-center">
						<img [src]="utility.getImagePath('list.png')" alt="list">
					</div>
					<div class="ml-2">
						{{'ALARM_HISTORY.TITLE' | translate}}
					</div>
				</div>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="table-container" [class.full-height-for-error]="!(alarms && alarms.length>0)">
			<table mat-table aria-describedby="alarms" [dataSource]="alarms"
				*ngIf="alarms && alarms.length>0; else noAlarmsFound">
				<ng-container matColumnDef="alertId">
					<th scope="col" mat-header-cell *matHeaderCellDef> {{'ALARM_HISTORY.ALARM_ID' | translate}}</th>
					<td mat-cell *matCellDef="let element" class="alarmId">{{ element.shortAlarmId || element.id}}</td>
				</ng-container>
				<ng-container matColumnDef="type">
					<th scope="col" mat-header-cell *matHeaderCellDef> {{'ALARM_HISTORY.ALARM_TYPE' | translate}} </th>
					<td mat-cell *matCellDef="let element" class="alarmType" matTooltip="{{element.type}}">
						{{element.type}}</td>
				</ng-container>
				<ng-container matColumnDef="severity">
					<th scope="col" mat-header-cell *matHeaderCellDef> {{'ALARM_HISTORY.SEVERITY' | translate}} </th>
					<td mat-cell *matCellDef="let element" class="alarmSeverity">{{element.severity}}</td>
				</ng-container>

				<ng-container matColumnDef="activationTime">
					<th scope="col" mat-header-cell *matHeaderCellDef> {{'ALARM_HISTORY.DATE_TIME' | translate}} </th>
					<td mat-cell *matCellDef="let element" class="activationTime"> 
					{{getStringDate(element.activationTime)}}</td>
				</ng-container>

				<ng-container matColumnDef="status">
					<th scope="col" mat-header-cell *matHeaderCellDef> {{'ALARM_HISTORY.STATUS' | translate}}</th>
					<td mat-cell *matCellDef="let element" class="alarmStatus">{{element.status}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
			<ng-template #noAlarmsFound>
				<div class="box-content-no-data">
					{{'ALARM_HISTORY.NO_DATA' | translate}}
				</div>
			</ng-template>
		</div>
		<mat-paginator [hidden]="!(alarms && alarms.length > 0)" class="report-paginator" [length]="alarmService.alarmCount"
			[pageSizeOptions]="appConstants.PAGINATION_OPTION" (page)="onPaginateChange($event)"
			showFirstLastButtons></mat-paginator>
	</mat-expansion-panel>
</mat-accordion>