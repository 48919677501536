<div>
	<div *ngIf='!shouldShowMap'><a class="close"
			(click)="close()"><img class="close-icon" alt="close"
				[src]="utility.getImagePath('close.png')"></a>
	</div>

	<div *ngIf="shouldShowMap">
		<app-map *ngIf="!containZone" shouldShowSearchInput="true"></app-map>
		<app-zone *ngIf="containZone"></app-zone>
	</div>
	<div class="assets-viewer">
		<app-pdf-viewer [hidden]="!shouldShowPDF"
			[url]="assetUrl"
			class="pdf-viewer-cmp"></app-pdf-viewer>
		<app-img-viewer [hidden]="!shouldShowImg"
			[url]="assetUrl" class="pdf-viewer-cmp"></app-img-viewer>
	</div>
</div>