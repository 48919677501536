import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Alarm } from 'src/models/alarm.model';
import { AlarmService } from '../services/alarm.service';
import { DateUtil } from '../util/date-util';
import { AuthenticationService } from '../services/authentication.service';
import { SocketService } from '../services/socket.service';
import { Subscription } from 'rxjs';
import { AppConstants } from '../constants/app-constants';
import { Utility } from '../util/app-utils';
import { UserSessionStateService } from '../services/user-session-state.service';
import { TranslateService } from '@ngx-translate/core';

enum AlarmSortField {
	location = 'location',
	time = 'time',
	severity = 'severity',
	status = 'status'
}
@Component({
	selector: 'app-alarms',
	templateUrl: './alarms.component.html',
	styleUrls: ['./alarms.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlarmsComponent implements OnInit, OnDestroy {
	alarms: Alarm[] = [];
	selectedAlarmId: string;
	unAttentedAlarmCount: any;
	escalatedAlarmCount: any;
	searchText = '';
	alarmListSubscription: Subscription;
	alarmChangeSubscription: Subscription;
	alarmStatusSubscription: Subscription;
	alarmSortFields = AlarmSortField;
	asc = true;
	toggleOnAlarmListChange = true;
	utility = Utility;
	confidenceMonitoringMode: boolean;
	getConfidenceMonitoringSubscriber: any;
	public isThermal: boolean = false;
	public isContainZone: boolean = false;
	newAlarm = this.alarmService.onNewAlarmEvent;

	constructor(public alarmService: AlarmService,
		public authService: AuthenticationService,
		private socketService: SocketService,
		private changeDetector: ChangeDetectorRef,
		public translate: TranslateService,
		private userSessionStateService: UserSessionStateService) { }

	async ngOnInit() {
		this.escalatedAlarmCount = await this.alarmService.getEscalatedAlarmsCount();
		this.unAttentedAlarmCount = await this.alarmService.getUnAttendedAlarmsCount();
		this.confidenceMonitoringMode = this.userSessionStateService.getConfidenceMonitoringState();
		this.alarmListChangeListener();
		this.alarmSelectedListener();
		this.alarmStatusChangeListener();
		this.alarmService.fetchAlarmsList(this.selectedAlarmId);
		this.getConfidenceMonitoringSubscriber = this.userSessionStateService.getConfidenceMonitorigChangeNotification().subscribe((state) => {
			this.confidenceMonitoringMode = state;
			if (state) {
				this.selectedAlarmId = null;
				this.refreshComponent();
			} else {
				this.alarmService.selectAlarm(this.alarmService.selectedAlarm);
			}
		});
	}

	ngOnDestroy() {
		Utility.Unsubscribe(this.getConfidenceMonitoringSubscriber);
		this.alarmChangeSubscription = Utility.Unsubscribe(this.alarmChangeSubscription);
		this.alarmListSubscription = Utility.Unsubscribe(this.alarmListSubscription);
		// this.socketService.closeSocket();
	}

	alarmListChangeListener() {
		this.alarmListSubscription = this.alarmService.getOnAlarmListChangedListener()
			.subscribe(async (alarms: Alarm[]) => {
				this.escalatedAlarmCount = await this.alarmService.getEscalatedAlarmsCount();
				this.unAttentedAlarmCount = await this.alarmService.getUnAttendedAlarmsCount();
				this.toggleOnAlarmListChange = !this.toggleOnAlarmListChange;
				this.alarms = alarms;
				this.asc = true;
				this.sortAlarms(this.alarmSortFields.time);
				this.refreshComponent();
			});
	}

	alarmSelectedListener() {
		this.alarmChangeSubscription = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
			this.userSessionStateService.modifyZoneState(false);
			this.selectedAlarmId = alarm ? alarm.id : null;
			this.isThermal = this.alarmService.isThermalAlarm(alarm);
			this.isContainZone = this.alarmService.isContainZone(alarm);
			this.userSessionStateService.modifyThermalState(this.isThermal);
			// this.userSessionStateService.modifyZoneState(this.isContainZone);
			this.userSessionStateService.mapToggleButtonState(this.isContainZone);
			this.refreshComponent();
		});
	}

	alarmStatusChangeListener() {
		this.alarmService.getChangeAlarmStatusNotification().subscribe((selectedAlarmId) => {
			// Search for selected alarm and change it status
			this.alarms.forEach(alarm => {
				if (alarm.id === selectedAlarmId) {
					alarm.status = this.alarmService.getAlarmStatus();
				}
			});
			this.refreshComponent();
		});
	}

	trackByAlarmId(index: number, alarm: Alarm) {
		return alarm.id;
	}

	/**
	 * Fetches alarm list from the server.
	 * @param searchText Text to search in the alarms,=
	 * @param sortBy Sort by field.
	 * @param sortDir Sort direction.
	 */
	getAlarms() {
		this.alarmService.fetchAlarmsList(this.selectedAlarmId);
	}

	/**
	 * Returns time in format dd MMM yyyy | H:mm:ss a
	 * @param activationTime Input date.
	 */
	getDate(activationTime: any) {
		return DateUtil.getFormattedDate(activationTime, AppConstants.DATE_TIME_FORMAT, this.authService.getUserTimezone());
	}

	/**
	 * Triggered when an alarm is clicked.
	 * @param alarm Alarm object
	 */
	selectAlarm(alarm: Alarm) {
		const isOperator = this.authService.hasRole(AppConstants.Operator);
		// Commenting this stop selection condition due to #295 Staging- After map search, Selected alarm is not triggered.
		// stop selecting same alarm again
		// if (this.selectedAlarmId !== alarm.id) {
		this.alarmService.selectAlarm(alarm);
		// }
		if (this.confidenceMonitoringMode && isOperator) {
			this.userSessionStateService.modifyConfidenceMonitoringState(false);
		}
	}
	/**
	 * Changes the sort order
	 * @param order sort order
	 */
	changeOrder(order: string) {
		if (order === 'asc') {
			return 'dsc';
		} else {
			return 'asc';
		}
	}

	/**
	 * Sorts alarm according to the field.
	 * @param sortField Field to sort alarms.
	 */
	sortAlarms(sortField: AlarmSortField) {
		this.asc = !this.asc;
		this.alarms = this.alarms.sort((alarm1: Alarm, alarm2: Alarm) => {
			let item1, item2;
			switch (sortField) {
				case AlarmSortField.location:
					item1 = alarm1.branch.name.toUpperCase();
					item2 = alarm2.branch.name.toUpperCase();
					break;
				case AlarmSortField.status:
					item1 = alarm1.status.toUpperCase();
					item2 = alarm2.status.toUpperCase();
					break;
				case AlarmSortField.severity:
					item1 = alarm1.severity.toUpperCase();
					item2 = alarm2.severity.toUpperCase();
					break;
				case AlarmSortField.time:
					item1 = alarm1.activationTime;
					item2 = alarm2.activationTime;
					break;
			}
			if (this.asc === true) {
				return item1 < item2 ? -1 : 1;
			} else {
				return item1 > item2 ? -1 : 1;
			}
		});

		this.refreshComponent();
	}

	/**
	 * We are manually detecting changes to improve performance of page.
	 * Please call this function whenever you make changes to the alarm list.
	 */
	refreshComponent() {
		this.changeDetector.markForCheck();
	}

	getTextToShowBelowIcon(type: string) {
		type = type.trim();
		return type.length > 5 ? type.substring(0, 4) + '...' : type;
	}
	toggleAudio() {
		this.alarmService.allowAudio = !this.alarmService.allowAudio;
	}
}
