import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Utility } from '../../../app/util/app-utils';
import { QuestionService } from './question.service';
import { AlarmService } from '../../services/alarm.service';
import { UserSessionStateService } from '../../services/user-session-state.service';
import { SocketService } from '../../services/socket.service';
import { SpeechService } from '../../services/speech.service';
import { WorkflowService } from '../../services/workflow.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../analytical-dashboard/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from "src/app/services/authentication.service";
import { AppConstants } from "src/app/constants/app-constants";

@Component({
	selector: 'app-question',
	templateUrl: './question.component.html',
	styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit, OnDestroy {

	@Input() question: any;
	@Input() workflowComplete: boolean;
	utility = Utility;
	comment: string = '';

	// Used to identify previous alarm selection. Used to save workflow state.
	previousAlarmId: string;

	// Used to identify questions uniquely.
	// Currently we calculate base 64 of question.
	questionKey: any;

	public socketTypeSubscription: Subscription;

	constructor(
		private questionService: QuestionService,
		private alarmService: AlarmService,
		private userSessionStateService: UserSessionStateService,
		private socketService: SocketService,
		private speechService: SpeechService,
		private workflowService: WorkflowService,
		public dialog: MatDialog,
		public translate: TranslateService,
		private authenticationService: AuthenticationService
	) {
		this.speechService.init();
	}


	ngOnInit() {
		this.refreshLastQuestionState();
		// Socket Type Subscriber for alarm verified by guard
		// this.socketTypeSubscription = this.alarmService.socketType_Observable().subscribe((socketType) => {
		// 	if (socketType === "NotAllowedAction") {
		// 		//show warning message
		// 		const title = this.translate.instant('WORKFLOW.WARNING_TITLE');
		// 		let message = this.translate.instant('WORKFLOW.WARNING_MESSAGE');
		// 		const confirmText = this.translate.instant('WORKFLOW.PROCEED');
		// 		const cancelText = this.translate.instant('WORKFLOW.CANCEL');
		// 		const typeOfDialogBox = 1;
		// 		const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
		// 		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
		// 			data: dialogData
		// 		});
		// 		dialogRef.afterClosed().subscribe(dialogResult => {
		// 			if (dialogResult) {
		// 				this.questionService.proceed_workflow("Proceed", "ForceWorkflow", "", "ForceWorkflow");
		// 			}
		// 		});
		// 	} else {
		// 		// Do nothing
		// 	}
		// })
	}

	ngOnDestroy() {
		this.saveWorkflowState();
		// this.socketTypeSubscription.unsubscribe();
	}

	refreshLastQuestionState() {
		const selectedAlarmId = this.alarmService.selectedAlarm.id;
		this.previousAlarmId = selectedAlarmId;

		// Workflow has been completed hence destroy the question state
		if (this.workflowComplete) {
			this.userSessionStateService.deleteWorkflowQuestionState(selectedAlarmId);
			return;
		}

		this.questionKey = btoa(unescape(encodeURIComponent(JSON.stringify(this.question))));
		const questionState = this.userSessionStateService.getWorkflowQuestionState(selectedAlarmId);
		if (questionState) {
			// if (questionState && questionState.key === this.questionKey) {
			// User was seeing same question and need to restore it's state
			const state = questionState.state;
			this.question = state.question;
			this.workflowComplete = state.workflowComplete;
			this.comment = state.comment;
		}
	}

	saveWorkflowState() {
		const state = {
			question: this.question,
			workflowComplete: this.workflowComplete,
			comment: this.comment
		};
		this.userSessionStateService.saveWorkflowQuestionState(this.previousAlarmId, this.questionKey, state);
	}

	/**
	 * Sets the answer for question and posts the answer to the API.
	 *
	 * @param buttonText Title of button which has been clicked.
	 */
	buttonClicked(buttonText: string) {
		this.question.disabledBtns = true
		this.questionService.requestData.action = buttonText;
		const isOperator = this.authenticationService.hasRole(
			AppConstants.Operator);
		if (isOperator && buttonText === "Escalate") {
			this.questionService.questionChangedListener.next(null);
		}

		if (buttonText === "False" || buttonText === "Resolve") {
			this.questionService.questionChangedListener.next(null);
		}

		if (this.question.comment === true) {
			this.questionService.requestData.comment = this.comment;
		}

		if (this.question.type === 'normal' || this.question.type === 'question' || this.question.type === 'action') {
			this.questionService.requestData.answer = buttonText;
		} else {
			this.questionService.requestData.answer = {
				'id': this.question.selectedId,
				'name': this.getNameForSelectedId(this.question.selectedId)
			};
		}
		this.questionService.requestData.label = this.question.label
		this.questionService.answerToQuestion();
		this.comment = '';
	}

	/**
	 * Returns name associated with the id.
	 *
	 * @param selectedId Selected ID.
	 */
	getNameForSelectedId(selectedId: string) {
		let selectedName = '';
		this.question.data.forEach(element => {
			if (element.id === selectedId) {
				selectedName = element.name;
			}
		});
		return selectedName;
	}

	async read(data: any) {
		await this.speechService.read(data);
	}

	async stopRead() {
		await this.speechService.stopRead();
	}

}
