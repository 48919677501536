import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import L, { Map, latLng, Layer, tileLayer } from "leaflet";
import { AppConfigService } from "src/app/app-config.service";
import { AppConstants } from "src/app/constants/app-constants";
import { MapUtils } from "src/app/util/map-utils";
import { Alarm } from "src/models/alarm.model";
import { ApiResponse } from "src/models/api-response.model";
import { Branch } from "src/models/branch.model";
import { AlarmService } from "../../services/alarm.service";
import { BranchService } from "../../services/branch.service";
import { AnalyticalDashboardService } from "src/app/services/analytical-dashboard.service";
import { Utility } from "src/app/util/app-utils";
import { UserSessionStateService } from "src/app/services/user-session-state.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Subscription } from 'rxjs';
import { MapService } from '../../services/map.service';
import { ConfidenceMonitoringService } from '../../services/confidence-monitoring.service';
import { SocketService } from '../../services/socket.service';
import { WEB_SOCKET_EVENTS } from '../../enums/web-socket-events';

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  allMarkers = [];
  leafletMap: Map;
  markers: Layer[] = [];
  options: any;
  center = latLng(25.276987, 55.296249);
  zoom = 5;
  branches: Branch[] = [];
  branchSearchResult: Branch[] = [];
  searchText: string;
  APP_CONFIG = this.appConfigService.appConfig;
  @Output() doneLoading = new EventEmitter<any>();
  interval: any;
  analyticalDashboardSubscribe: any;
  newAlarmSubscriber: any;
  branchesSubscriber: any;
  operatorDashboardSubscribe: any;
  @Input() shouldShowSearchInput = false;
  shouldMakeSearchVisible = true;
  utility = Utility;
  getConfidenceMonitoringSubscriber: Subscription;

  public containZone: boolean = false;
  zoneStateSubscription: Subscription;
  assetStateSubscription: Subscription;
  mapToggleState: boolean = false;
  isAnalytical: boolean = false;
  selectedAlarm: any;

  constructor(
    private branchService: BranchService,
    private alarmService: AlarmService,
    private appConfigService: AppConfigService,
    private translateService: TranslateService,
    public analyticalDashboard: AnalyticalDashboardService,
    private changeDetector: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private userSessionStateService: UserSessionStateService,
    public translate: TranslateService, public mapService: MapService,
    public confidenceMonitoringService: ConfidenceMonitoringService,
    private socketService: SocketService
  ) { }

  async ngOnInit() {
    // this.restoreLastMarkerLocation();
    await this.configMapOptions();
    this.branches = await this.getBranches();
    this.analyticalDashboardListener();
    this.operatorDashboardListener();
    this.newAlarmAddedListener();
    await this.monitoringModeListener();
    await this.selectedAlarmListener();
    this.webSocketInit();

    this.zoneStateSubscription = this.userSessionStateService.getMapToggleButtonStateChangeNotification().subscribe((isContainZone) => {
      if (isContainZone) {
        this.containZone = true;
      }
      else {
        this.containZone = false;
      }
    });

    const confidenceMonitoringMode = this.userSessionStateService.getConfidenceMonitoringState();
    if (confidenceMonitoringMode) {
      // Received when asset (PDF/Image) is closed.
      this.assetStateSubscription = this.mapService.getAssetStateChangeNotification().subscribe(async (branch) => {
        if (branch) {
          let currentBranch = {
            ...branch,
            latitude: branch.location.latitude,
            longitude: branch.location.longitude
          };
          this.zoomToBranch(currentBranch);
        }
      });
    }

    this.isAnalyticalDashboard();
    this.getMapToggleState();
  }

  isAnalyticalDashboard() {
    const analyticalDashboardState = this.userSessionStateService.getAnalyticalDashboardState();
    if (analyticalDashboardState) {
      this.isAnalytical = true
    } else {
      this.isAnalytical = false;
    }
  }

  getMapToggleState() {
    const confidenceMonitoringMode = this.userSessionStateService.getConfidenceMonitoringState();
    this.mapToggleState = this.userSessionStateService.getMapToggleState();
    if (this.mapToggleState === true && !confidenceMonitoringMode) {
      this.containZone = true;
    } else {
      this.containZone = false;
    }
  }

  async ngOnDestroy() {
    this.operatorDashboardSubscribe = Utility.Unsubscribe(this.operatorDashboardSubscribe);
    this.analyticalDashboardSubscribe = Utility.Unsubscribe(this.analyticalDashboardSubscribe);
    this.newAlarmSubscriber = Utility.Unsubscribe(this.newAlarmSubscriber);
    this.branchesSubscriber = Utility.Unsubscribe(this.branchesSubscriber);
    this.getConfidenceMonitoringSubscriber = Utility.Unsubscribe(this.getConfidenceMonitoringSubscriber);
    this.zoneStateSubscription = Utility.Unsubscribe(this.zoneStateSubscription);
    // this.assetStateSubscription = Utility.Unsubscribe(this.assetStateSubscription);
  }


  async branchesUpdateListener() {
    this.branchesSubscriber = this.branchService.getBranchesChangeListener().subscribe(async (branch: any) => {
      await this.updateMap();
    });
  }

  async monitoringModeListener() {
    this.getConfidenceMonitoringSubscriber = this.userSessionStateService.getConfidenceMonitorigChangeNotification().subscribe(async (state) => {
      let alarmsList = await this.alarmService.getAlarms();
      let alarmsLength = alarmsList.data.length;
      if (!state && alarmsLength === 0) {
        await this.updateMap();
        await this.zoomToMultipleBranch(this.branches);
      } else if (state === true) {
        this.containZone = false;
      }
    });
  }

  newAlarmAddedListener() {
    this.newAlarmSubscriber = this.alarmService.getNewAlarmNotification().subscribe((alarm: any) => {
      if (alarm) {
        this.changeMarkerStatus(alarm);
      }
    });
  }

  changeMarkerStatus(alarm: any) {
    for (const branch of this.branches) {
      if (branch.id === alarm.branch.id) {
        branch.status = alarm.branch.status || branch.status;
        branch.icon = alarm.branch.statusIcon || branch.icon;
        break;
      }
    }
    this.createMarkers();
  }

  /**
   * Called to reload the last selected location on map
   */
  restoreLastMarkerLocation() {
    if (localStorage.getItem("prevAlarm")) {
      this.zoomToBranch(JSON.parse(localStorage.getItem("prevAlarm")).branch);
      this.refreshComponent();
    }
  }

  async createMarkers(data?: any, type?: string) {
    if (this.branches && this.branches.length) {
      this.markers = [];
      switch (type) {
        case 'branch':
          let branchDetails = this.branches.find(x => x.id == data.id);
          if (branchDetails) {
            this.markers.push(MapUtils.createMarker(branchDetails, this.translateService));
          }
          break;
        case 'related-branches':
          let relatedBranchesWithIcons = [];
          for (let i = 0; i < data.length; i++) {
            let branchDetails = this.branches.find(x => x.id == data[i].id);
            relatedBranchesWithIcons.push(branchDetails)
          }
          relatedBranchesWithIcons.forEach((branch: Branch) => {
            this.markers.push(MapUtils.createMarker(branch, this.translateService));
          });
          break;
        default:
          this.branches.forEach((branch: Branch) => {
            this.markers.push(MapUtils.createMarker(branch, this.translateService));
          });
      }
    }
    this.allMarkers = this.markers;
  }

  /**
   * called to config map options
   */
  async configMapOptions() {
    const appConfigInitializer = setInterval(() => {
      this.APP_CONFIG = this.appConfigService.appConfig;
      if (this.APP_CONFIG) {
        const LAYER_OSM = tileLayer(this.APP_CONFIG.MAP_CONFIG.LAYER_NAME, {
          maxZoom: this.APP_CONFIG.MAP_CONFIG.MAX_ZOOM,
          attribution: this.APP_CONFIG.MAP_CONFIG.ATTRIBUTION,
          minZoom: 2,
        });
        // Limit Map Draging - Keeping selected branch location according to map bounds 
        // #378 Staging
        var southWest = L.latLng(180, 180),
          northEast = L.latLng(-180, -180),
          bounds = L.latLngBounds(southWest, northEast);

        this.options = {
          layers: [LAYER_OSM],
          zoom: this.APP_CONFIG.MAP_CONFIG.DEFAULT_ZOOM,
          dragging: true,
          maxBounds: bounds,
          center: latLng(
            this.APP_CONFIG.MAP_CONFIG.DEFAULT_LATITUDE,
            this.APP_CONFIG.MAP_CONFIG.DEFAULT_LONGITUDE
          ),
        };
        // this.initializeMarkers();
        clearInterval(appConfigInitializer);
      }
      this.refreshComponent();
    }, AppConstants.APP_CONFIG_INIT_TIMER);
  }

  // Called when map view toggle after zone view
  async selectedAlarmListener() {
    this.selectedAlarm = this.alarmService.selectedAlarm;
    if (this.selectedAlarm) {
      this.markers = this.allMarkers || [];
      localStorage.setItem("prevAlarm", JSON.stringify(this.selectedAlarm));
      this.zoomToBranch(this.selectedAlarm.branch);
      this.refreshComponent();
    }
  }

  /**
   * called when alarm is selected on operator dashboard screen
   */
  operatorDashboardListener() {
    this.operatorDashboardSubscribe = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
      if (alarm) {
        this.markers = this.allMarkers || [];
        localStorage.setItem("prevAlarm", JSON.stringify(alarm));
        this.zoomToBranch(alarm.branch);
        this.refreshComponent();
      }
      else {
        this.defaultMapLocation();
      }
    });
  }

  /**
   * called when a row is selected from alarms, operators or sites table
   */
  analyticalDashboardListener() {
    this.analyticalDashboardSubscribe = this.analyticalDashboard.getOnAlarmSelectedNotification().subscribe(async (alarm: any) => {
      const confidenceMonitoringMode = this.userSessionStateService.getConfidenceMonitoringState();
      const isOperator = this.authenticationService.hasRole(AppConstants.Operator);
      clearInterval(this.interval);
      if (alarm && (!isOperator || (isOperator && confidenceMonitoringMode))) {
        this.markers = [];
        switch (this.analyticalDashboard.chartSelected) {
          case this.analyticalDashboard.chartType.ALARM:
            this.zoomToBranch(alarm.branch);
            break;
          case this.analyticalDashboard.chartType.OPERATOR:
            this.zoomToMultipleBranch(alarm.relatedSites);
            break;
          default:
            // when site is selected then call to zoom on branch
            let branch = {
              ...alarm,
              latitude: alarm.location.latitude,
              longitude: alarm.location.longitude
            };
            this.zoomToBranch(branch);
        }
      }
      else if (!alarm) {
        switch (this.analyticalDashboard.chartSelected) {
          case this.analyticalDashboard.chartType.ALARM:
            this.zoomToMultipleBranch(this.branches);
            break;
        }
      }
    });
  }

  /**
   * Initializes markers
   */
  async initializeMarkers() {
    this.markers.forEach((element) => {
      this.allMarkers.push(element);
    });
    this.refreshComponent();
  }

  async updateMap() {
    if (this.markers.length) {
      this.markers.forEach((marker) => {
        if (this.leafletMap) {
          this.leafletMap.removeLayer(marker);
        }
      });
    }
    this.createMarkers();
    this.refreshComponent();
  }

  /**
   * Searches branches
   */
  async searchBranch() {
    this.branchSearchResult = [];
    if (this.searchText === "") {
      return;
    } else {
      this.branchSearchResult = await this.getBranches(this.searchText);
    }
    this.refreshComponent();
  }

  /**
   * Fetches branches from API
   *
   * @param searchText Text to search
   */
  async getBranches(searchText?: string): Promise<Branch[]> {
    try {
      const response: ApiResponse = await this.branchService.getMapBranches(
        searchText
      );
      if (response.success === true) {
        return response.data;
      }
      // else if (response.success === false){
      //   console.log(response.errorMessages[0].code)
      // }
    } catch (err) { }
  }

  /**
   * Pans map to the branch location
   *
   */
  zoomToBranch(branch: Branch) {
    this.center = latLng(branch.latitude, branch.longitude);
    this.zoom = 16;
    this.branchSearchResult = [];
    this.searchText = "";
    if (branch.id) {
      this.createMarkers(branch, 'branch');
    }
    this.refreshComponent();
  }

  async defaultMapLocation() {
    this.branches = await this.getBranches();
    let alarmsList = await this.alarmService.getAlarms();
    if (alarmsList.data.length === 0) {
      if (!this.analyticalDashboard.chartSelected) {
        this.zoomToMultipleBranch(this.branches);
      }
    };
  }

  /**
   * Triggered when map is ready.
   * @param map Leaflet map object
   */
  async onMapReady(map: Map) {
    this.leafletMap = map;
    // calls to done loading after map is loaded
    const interval = setInterval(() => {
      if (this.leafletMap) {
        this.doneLoading.emit(true);
        clearInterval(interval);
      }
    }, AppConstants.MAP_LOADING_CONFIG_TIMER);
    this.refreshComponent();
    // this.shouldMakeSearchVisible = true;

    await this.defaultMapLocation();
    await this.branchesUpdateListener();
  }

  /**
   * called to search a site on map
   */
  onSearchExit() {
    this.searchText = "";
    this.branchSearchResult = [];
    this.searchBranch();
    this.createMarkers();
    this.refreshComponent();
  }

  /**
   * called to zoom to multiple sites
   */
  async zoomToMultipleBranch(branches: any) {
    const markerArray = [];
    if (branches && branches.length !== 0) {
      branches.forEach((branch: Branch) => {
        markerArray.push(L.marker([branch.latitude, branch.longitude]));
      });
      const group = L.featureGroup(markerArray);
      if (this.leafletMap) {
        this.leafletMap.fitBounds(group.getBounds());
      }
      this.createMarkers(branches, 'related-branches');
    }
    this.branchSearchResult = [];
    this.searchText = "";
    this.refreshComponent();
  }

  /**
   * We are manually detecting changes to improve performance of page.
   * Please call this function whenever you make changes.
   */
  private refreshComponent() {
    this.changeDetector.markForCheck();
  }

  toggleMap() {
    this.userSessionStateService.modifyZoneState(this.containZone);
  }

  // WebSocket Listener on Remove Alarm
  webSocketInit() {
    this.socketService.initSocket();
    const socketId = localStorage.getItem('socketId');
    if (!socketId) {
      return;
    }
    const eventNotification = this.socketService.onEvent<any>(socketId);
    if (eventNotification) {
      eventNotification.subscribe((socketEvent) => {
        const data = socketEvent.data;
        switch (socketEvent.type) {
          case WEB_SOCKET_EVENTS.REMOVE_ALARM:
            this.onRemoveAlarmEvent(data);
            break;
          case WEB_SOCKET_EVENTS.TAKEN_OVER_ALARM:
            this.onTakenOverAlarm(data);
            break;
          case WEB_SOCKET_EVENTS.REFRESH_SUPER_ROUTES:
            this.refreshSupervisorRoutes(data);
            break;
          default:
        }
      });
    }
  }

  async onRemoveAlarmEvent(data: any) {
    let alarmsList = await this.alarmService.getAlarms();
    if (alarmsList.data.length === 0) {
      if (!this.analyticalDashboard.chartSelected) {
        this.defaultMapLocation();
      }
    }
  }

  onTakenOverAlarm(data: any) {
    this.onRemoveAlarmEvent(data.alarm);
  }

  refreshSupervisorRoutes(data: any) {
    if (data === "newAlarm") {
      this.initializeMarkers();
    }
  }

}
