<div class="app-workflow full-height">
	<div class="box-header d-flex align-items-center">
		<div class="icon d-flex align-items-center">
			<img [src]="utility.getImagePath('list.png')" alt="list">
		</div>
		<div class="workflow-topbar ml-2">
			<p class="workflow-title">{{ "WORKFLOW.TITLE" | translate }}</p>
			<span *ngIf="alarm" class="buttons mr-3">
				<img *ngIf="this.speechService.allowAudio" [src]="utility.getImagePath('unmute.png')" alt="unmute" class="audio" (click)="toggleAudio()">
				<img *ngIf="!this.speechService.allowAudio" [src]="utility.getImagePath('mute.png')" alt="mute" class="audio" (click)="toggleAudio()">
				<i class="fa fa-envelope mr-4" [title]="'WORKFLOW.SEND_MAIL' | translate" aria-hidden="true"
					(click)="showSendMailBox()" [class.active-link]="isSendMailBoxShown==true"></i>
				<i class="fa fa-comment-o mr-4" [class.active-link]="isCommentBoxShown==true"
					[title]="'WORKFLOW.ADD_COMMENT' | translate" aria-hidden="true" (click)="showCommentBox()"></i>
				<i class="fa fa-external-link mr-4 pt-1" [title]="'WORKFLOW.ESCALATE_ALARM' | translate" aria-hidden="true"
					[class.active-link]="isEscalationConfirmationShown==true" (click)="showEscalationConfirmation()"
					*ngIf="alarm.status!=EscalatedAlarm"></i>
				<!-- chat -->
				<i class="fa fa-commenting mr-4" [class.active-link]="isChatShown==true" aria-hidden="true"
					[title]="'WORKFLOW.CHAT' | translate" (click)="showChatBox()"></i>
				<!-- Audio toggle -->
				<!-- <i class="fa fa-volume-up" aria-hidden="true" *ngIf="this.speechService.allowAudio" (click)="toggleAudio()"></i>
				<i class="fa fa-volume-off" aria-hidden="true" *ngIf="!this.speechService.allowAudio"
					(click)="toggleAudio()"></i> -->
			</span>
		</div>
	</div>
	<div *ngIf="alarm && !showSmartWorkflow" class="content-height">
		<div class='sections div1 full-height'
			*ngIf="isCommentBoxShown || isEscalationConfirmationShown || isSendMailBoxShown || isChatShown">
			<div *ngIf="isCommentBoxShown">
				<app-add-comment [comment]="previousComments" (commentAdded)="addComment($event)"
					(commentCancelled)="onCommentCancel()" (commentsChange)="onCommentsTextChange($event)">
				</app-add-comment>
			</div>
			<div *ngIf="isSendMailBoxShown">
				<app-send-mail (sendEmailCancelled)="isSendMailBoxShown=false"></app-send-mail>
			</div>
			<div *ngIf="isEscalationConfirmationShown">
				<app-escalation-confirmation (alarmEscalated)="escalateAlarm()"
					(alarmEscalationCancelled)="isEscalationConfirmationShown=false" [title]="title" [calledFrom]="calledFrom"
					[header]="header"></app-escalation-confirmation>
			</div>
			<!-- Chat Users -->
			<div *ngIf="isChatShown">
				<app-chat-users [alarm]=alarm (ChatBoxCancelled)="isChatShown=false"></app-chat-users>
			</div>
		</div>
		<div class='sections div2 full-height'
			[hidden]="!(!isCommentBoxShown && !isEscalationConfirmationShown && !isSendMailBoxShown && !isChatShown)">
			<div *ngIf="question">
				<app-question [question]="question" [workflowComplete]="workflowComplete"></app-question>
			</div>
		</div>
		<div class="sections div3 full-height">
			<div class="heading"> {{ "WORKFLOW.BRANCH_CONTACT" | translate }}</div>
			<ul class="list-group">
				<li *ngFor="let manager of managers">
					<p>{{" " +manager.designation}}</p> <br>
					<p class="manager-name limit-contains" matTooltip="{{manager.name}}">{{manager.name}}</p><br>
					<p>{{ "WORKFLOW.MOBILE" | translate }} : {{manager.contact + " "}}</p><span class="ml-2"><img
							[src]="utility.getImagePath('call.png')" alt="call"></span>
					<hr>
				</li>
			</ul>
		</div>
	</div>

	<div class="row smart-workflow" *ngIf="alarm && showSmartWorkflow">
		<div class="col-4">
			<circle-progress 
		   	[percent]="percent" 
			  [radius]="70" 
			  [outerStrokeWidth]="18" 
			  [innerStrokeWidth]="15" 
				[space]="-16" 
				[outerStrokeColor]="'#45acdd'" 
				[innerStrokeColor]="'#e7e8ea'" 
				[showInnerStroke]="true" 
				[animation]="true" 
				[animationDuration]="2000" 
				[showBackground]="false" 
				[responsive]="false" 
				[showTitle]="true" 
				[showSubtitle]="false" 
				[titleFontSize]="25" 
				[unitsFontSize]="15" 
				[titleColor]="'#fff'"
				[unitsColor]="'#fff'" 
				[showImage]="false" 
				[lazy]="true" 
				[clockwise]="true">
			</circle-progress>
		</div>
		<div class="col-7 ai-text">
			<ul #actionsList>
				<li *ngFor="let action of actions" class="typewriter">
					<span *ngIf="action.completed" class="checkmark">&#10004;</span>{{ action.displayedText }}
				</li>
			</ul>
		</div>
		<div class="col-1">
			<i class="fa fa-toggle-on toggle" aria-hidden="true" (click)="toggle()"></i>
		</div>
	</div>

</div>