<pdf-viewer class="pdf-container"
	[src]="url"
	[show-all]="true"
	[render-text]="false"
	[original-size]="true"
	[zoom]="zoom"
	(error)="onError()"
	*ngIf="!PDFLoadFailed">
</pdf-viewer>
<button class="zoom-btn zoom-in-btn"
	(click)="zoomIn()"
	title="Zoom in">
	+
</button>
<button class="zoom-btn zoom-out-btn"
	(click)="zoomOut()"
	title="Zoom out">
	-
</button>
<p *ngIf="PDFLoadFailed"
	class="error">{{ "ERRORS.PDF_LOADING_ERROR" | translate }}</p>