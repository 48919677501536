import { ApiResponse } from 'src/models/api-response.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { RouteConstants } from '../constants/route-constants';
import { AppConstants } from '../constants/app-constants';
import { Utility } from '../util/app-utils';
import { ApiConstants } from '../constants/api-constants';
import { TranslateService } from '@ngx-translate/core';
import { LocationStrategy } from '@angular/common';

/**
 * Creates an instance of login component.
 * Login screen for user authentication.
 */
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
	SOCKET_ID = 'socketId';
	logoUrl: object;

	public loginForm = new FormGroup({
		username: new FormControl('', Validators.required),
		password: new FormControl('', Validators.required),
	});
	public submitted;
	public errorMessage;
	utility = Utility;

	/**
	 * Constructor of login component.
	 *
	 * @param authenticationService provide user authentication utilities.
	 * @param router navigation between the components.
	 */
	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
		public translate: TranslateService,
		private locationStrategy: LocationStrategy
	) { }

	ngOnInit() {
		this.authenticationService.getClientLogo().subscribe((r: any) => this.logoUrl = r.logo);
		this.preventBackButton();
	}

	/**
	 * Logins user check for user authentication.
	 * On success navigates to dashboard page and on failure shows the error message.
	 */
	async loginUser() {
		this.submitted = true;
		if (this.loginForm.valid) {
			const username = this.loginForm.value.username;
			const password = btoa(this.loginForm.value.password);
			let response: any;

			try {
				response = await this.authenticationService.verifyUser(username, password);
				if (response.success === true) {
					this.authenticationService.poll();
					localStorage.setItem('socketId', response[this.SOCKET_ID]);
					this.authenticationService.setAuthToken(response[AppConstants.SESSION_TOKEN]);
					this.router.navigate([RouteConstants.PAGES + RouteConstants.HOME]);
					this.preventBackButton();
				} else {
					this.errorMessage = response.errorMessages[0].message;
				}

			} catch (err) {
				let errorCode = err.error.errorMessages[0].code;
				if (errorCode == 'RMS_0001') {
					this.errorMessage = this.translate.instant('LOGIN.LOGIN_ERROR');
				}
				else if (err.status == 500) {
					this.errorMessage = this.translate.instant('LOGIN.SERVER_ERROR');
				}
				else if (err.status == 606) {
					this.errorMessage = this.translate.instant('LOGIN.LICENSE_NOT_VALID');
				}
				else if (errorCode == 'RMS_0060') {
					this.errorMessage = this.translate.instant('LOGIN.USER_ACCOUNT_DEACTIVATED');
				}
				else {
					this.errorMessage = this.translate.instant('LOGIN.UNKNOWN_ERROR');
				}
			}
		} else {
			Utility.validateAllFormFields(this.loginForm);
			this.submitted = false;
		}
	}

	/**
	 * Removes error set error message blank
	 */
	removeError() {
		this.errorMessage = '';
	}

	// Disable back button
	preventBackButton() {
		history.pushState(null, null, location.href);
		this.locationStrategy.onPopState(() => {
			history.pushState(null, null, location.href);
		})
	}

}
