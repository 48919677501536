import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';
import { BranchService } from 'src/app/services/branch.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppConstants } from 'src/app/constants/app-constants';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { OwlDateTimeIntl } from 'ng-pick-datetime';
import moment from 'moment-timezone';
import { Utility } from 'src/app/util/app-utils';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from '../../constants/api-constants';
import { Subscription } from 'rxjs';
import { AppLocalStorage } from 'src/app/util/app-local-storage-utils';
import { AlarmService } from 'src/app/services/alarm.service';

@Component({
	selector: 'app-alarms-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss']
})

export class AlarmsFilterComponent implements OnInit {
	public SelectedType = {
		region: AppConstants.REGION,
		sites: AppConstants.SITES,
		operators: AppConstants.OPERATORS,
		active_operators: AppConstants.ACTIVE_OPERATORS,
		online_operators: AppConstants.ONLINE_OPERATORS,
		type: AppConstants.TYPE,
		status: AppConstants.STATUS,
		timeRange: AppConstants.TIME_RANGE,
		sitePriority: AppConstants.SITE_PRIORITY,
		alarmsSeverity: AppConstants.ALARMS_SEVERITY,
		shortAlarmID: AppConstants.SHORT_ALARM_ID,
		alarmInfo: AppConstants.ALARM_INFO
	};

	regions = [];
	sites = [];
	operators = [];
	activeOperators = [];
	onlineOperators = [];
	activeOnlineOfflineOperators = [];
	types = [];
	statuses = [];
	OperatorStatuses = [];
	sitesStatuses = [];
	SitesPriorityTypes = [];
	alarmsType = [];
	alarmsSeverity = [];
	alarmsStatusTypes = [];
	currentDate = new Date();
	public selectedMoments: any;
	@Input() filterType = 'ALARMS';
	@Input() isConfidenceMonitoring = false;
	@Output() changeHeight = new EventEmitter;
	public operatorSelectFields = [];
	public operatorSelectFieldsForAction = [];
	public selectFields: any;
	public regionSelectFields = [];
	public sitesSelectFields = [];
	public typeSelectFields = [];
	public statusSelectFields: any;
	public severitySelectFields: any;
	public OperatorStatusSelectFelid: any;
	public selectedOperatorsId = null;
	isShowingUserDrawdown = false;
	formatedRegionData = [];
	public placeholder: any;
	public loading = false;
	@Input() selectedAlarmHistory: any;
	public selectedFilter = {
		region: false,
		sites: false,
		operators: false,
		type: false,
		status: false,
		timeRange: false,
		sitePriority: false,
		alarmsSeverity: false,
		shortAlarmID: false,
		alarmInfo: false
	};
	actionType = {
		takeover: this.translate.instant('ANALYTICAL_FILTERS.TAKEOVER_ACTION'),
		assign: this.translate.instant('ANALYTICAL_FILTERS.ASSIGN_ACTION'),
		unAssign: this.translate.instant('ANALYTICAL_FILTERS.UN_ASSIGN_ACTION')
	};
	selectedAction = null;
	formatedOperatorsDataForFilter: any = [];
	branchChangeSubscriber: any;
	selectedChartSubscription: Subscription;
	alarmInfoText: string = '';
	generalSearch: string = '';
	shortAlarmIdText: string = '';
	askAiText: string = '';
	utility = Utility;
	initFilterStateSubscription: any;
	isHour12Format: boolean;
	spinner: boolean = false;

	constructor(
		public appConfigService: AppConfigService,
		public analyticalDashboard: AnalyticalDashboardService,
		public branchService: BranchService,
		public authService: AuthenticationService,
		public translate: TranslateService,
		public dialog: MatDialog,
		public owlDateTimeIntl: OwlDateTimeIntl,
		private http: HttpClient,
		private alarmService: AlarmService
	) { }

	async ngOnInit() {
		this.owlDateTimeIntl.setBtnLabel = this.translate.instant('ANALYTICAL_FILTERS.SAVE_TEXT');
		this.owlDateTimeIntl.cancelBtnLabel = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
		this.owlDateTimeIntl.rangeFromLabel = this.translate.instant('FILTER_DATA.FROM');
		this.owlDateTimeIntl.rangeToLabel = this.translate.instant('FILTER_DATA.TO');
		this.owlDateTimeIntl.hour12AMLabel = this.translate.instant('FILTER_DATA.AM');
		this.owlDateTimeIntl.hour12PMLabel = this.translate.instant('FILTER_DATA.PM');
		this.selectedFilter[this.SelectedType.region] = true;
		this.setPlaceholderForFilters();
		this.initFilter();

		this.analyticalDashboard.getUpdateNotification().subscribe((isChange) => {
			this.resetFilters(true);
		});

		// this.branchChangeSubscriber = this.analyticalDashboard.getBranchNotification().subscribe(async () => {
		// 	this.initFilter();
		// });

		this.initFilterStateSubscription = this.analyticalDashboard.getInitFilterStateChangeNotification().subscribe((value) => {
			if (value === true) {
				this.initFilter();
				this.analyticalDashboard.initFilterState(false);
			}
		});

		this.selectedChartSubscription = this.analyticalDashboard.OnSelectedChartNotification().subscribe((chartType) => {
			this.collapseFilters();
			if (chartType === 1) {
				this.getOperators_online()
			} else if (chartType === 2) {
				//
			} else if (chartType === 3) {
				//
			}
		});
		let lang = AppLocalStorage.getLanguage();
		this.isHour12Format = (lang !== "ru");
	}

	ngOnDestroy() {
		// this.branchChangeSubscriber = Utility.Unsubscribe(this.branchChangeSubscriber);
		this.initFilterStateSubscription = Utility.Unsubscribe(this.initFilterStateSubscription);
		this.selectedChartSubscription.unsubscribe();
	}

	/**
	 * called to set placeholder text
	 */
	setPlaceholderForFilters() {
		this.placeholder = {
			allStatus: this.translate.instant('ANALYTICAL_FILTERS.ALL_STATUS'),
			allOperators: this.translate.instant('ANALYTICAL_FILTERS.ALL_OPERATORS'),
			allUsers: this.translate.instant('ANALYTICAL_FILTERS.ALL_USERS'),
			allOperator: this.translate.instant('ANALYTICAL_FILTERS.ALL_OPERATOR'),
			allSitesPriority: this.translate.instant('ANALYTICAL_FILTERS.ALL_SITES_PRIORITY'),
			allSites: this.translate.instant('ANALYTICAL_FILTERS.ALL_SITES'),
			allRegion: this.translate.instant('ANALYTICAL_FILTERS.ALL_REGION'),
			allSeverity: this.translate.instant('ANALYTICAL_FILTERS.ALL_SEVERITY'),
			allType: this.translate.instant('ANALYTICAL_FILTERS.ALL_TYPE'),
			selectOperators: this.translate.instant('ANALYTICAL_FILTERS.SELECT_OPERATORS'),
			selectDateAndTime: this.translate.instant('ANALYTICAL_FILTERS.SELECT_DATE_AND_TIME'),
		};
	}

	/**
	 * called to open and close multi select option
	 */
	toggleUserDrawdown(toggle: boolean) {
		this.isShowingUserDrawdown = toggle;
		// Stop all drop down from showing and show only selected
		if (toggle) {
			this.isShowingUserDrawdown = !toggle;
		}
	}

	/**
	 * called to format multi select option table
	 */
	formatData(filterData: any, type: any) {
		let formattedData = [];
		for (const data of filterData) {
			switch (type) {
				case this.SelectedType.sites:
					formattedData.push({ title: data.branchName, value: data._id });
					break;
				case this.SelectedType.operators:
					formattedData.push({ title: data.name, value: data.id });
					break;
				default:
					formattedData.push({ title: data, value: data });
			}
		}
		if (type === this.SelectedType.operators && this.analyticalDashboard.chartType.ALARM === this.analyticalDashboard.chartSelected) {
			// Adding supervisor info to filter
			this.formatedOperatorsDataForFilter = [];
			formattedData.forEach(data => {
				this.formatedOperatorsDataForFilter.push(data);
			});
			// Commenting as per #288 Sataging
			// this.formatedOperatorsDataForFilter.push({
			// 	title: this.translate.instant('ANALYTICAL_ALARM_HISTORY.YOU'), value: this.authService.currentUserSession.userId
			// });
			// Adding UnAssigned info to filter
			this.formatedOperatorsDataForFilter.push({ title: this.translate.instant('ANALYTICAL_ALARM_HISTORY.UN_ASSIGNED_TEXT'), value: '-1' });
			this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, formatedOperatorsDataForFilter: this.formatedOperatorsDataForFilter };
		}
		if (type === this.SelectedType.active_operators) {
			formattedData = [];
			filterData.forEach(data => {
				formattedData.push({ title: data.name, value: data.id });
			});
		}

		if (type === this.SelectedType.online_operators) {
			formattedData = [];
			filterData.forEach(data => {
				formattedData.push({ title: data.name, value: data.id });
			});
		}

		this.onlineOperators
		return formattedData;
	}

	/**
	 * called to set time range filter default time
	 */
	initFilterTimeRange() {
		const timezoneOffsetDifference = ((moment().utcOffset() - moment().tz(this.authService.getUserTimezone()).utcOffset()) * AppConstants.MINUTE_TO_MILLISECONDS);
		let nowNumber = new Date().getTime() - timezoneOffsetDifference
		let fromNumber = nowNumber - this.appConfigService.appConfig.FILTER_START_DATE_INTERVAL
		const now: any = new Date(nowNumber);
		const from = new Date(fromNumber);
		this.selectedMoments = [
			from,
			now
		];
		this.currentDate = now;
	}

	/**
	 * called to init the filter on UI and get data for filter option
	 */
	initFilter() {
		let { isFilterDataLoaded, filterData } = this.analyticalDashboard
		if (!isFilterDataLoaded) {
			isFilterDataLoaded = true;
			// Load filter data
			this.initFilterTimeRange();
			this.getSites();
			this.getAlarmsTypes();
			this.getAlarmsSeverityTypes();
			this.getAlarmsStatusTypes();
			this.getOperator();
			this.getOperators_active();
			this.getOperators_online();
			this.getRegion();
			this.getOperatorStatuses();
			this.getBranchStatuses();
			this.getSitesPriorityType();
		} else {
			// reload data for filter option	
			this.initFilterTimeRange();
			this.sites = filterData && filterData.sites ? filterData.sites : this.getSites();
			this.alarmsType = filterData && filterData.alarmsType ? filterData.alarmsType : this.getAlarmsTypes();
			this.alarmsSeverity = filterData && filterData.alarmsSeverity ? filterData.alarmsSeverity : this.getAlarmsSeverityTypes();
			this.alarmsStatusTypes = filterData && filterData.alarmsStatusTypes ? filterData.alarmsStatusTypes : this.getAlarmsStatusTypes();
			this.operators = filterData && filterData.operators ? filterData.operators : this.getOperator();
			this.activeOperators = filterData && filterData.activeOperators ? filterData.activeOperators : this.getOperators_active();
			this.onlineOperators = filterData && filterData.onlineOperators ? filterData.onlineOperators : this.getOperators_online();
			this.regions = filterData && filterData.regions ? filterData.regions : this.getRegion();
			this.SitesPriorityTypes = filterData && filterData.SitesPriorityTypes ? filterData.SitesPriorityTypes : this.getSitesPriorityType();
			this.OperatorStatuses = filterData && filterData.OperatorStatuses ? filterData.OperatorStatuses : this.getOperatorStatuses();
			this.sitesStatuses = filterData && filterData.sitesStatuses ? filterData.sitesStatuses : this.getBranchStatuses();
			this.formatedOperatorsDataForFilter = filterData && filterData.formatedOperatorsDataForFilter;
		}
	}

	/**
	 * get all sites type
	 */
	async getSites() {
		this.sites = await this.analyticalDashboard.getSites();
		this.sites = this.formatData(this.sites, this.SelectedType.sites);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, sites: this.sites };
	}

	/**
	 * get all alarms type
	 */
	async getAlarmsTypes() {
		this.alarmsType = await this.analyticalDashboard.getAlarmsTypes();
		this.alarmsType = this.formatData(this.alarmsType, this.SelectedType.type);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, alarmsType: this.alarmsType };
	}

	/**
	 * get all alarms severity type
	 */
	async getAlarmsSeverityTypes() {
		this.alarmsSeverity = await this.analyticalDashboard.getAlarmsSeverityTypes();
		this.alarmsSeverity = this.formatData(this.alarmsSeverity, this.SelectedType.alarmsSeverity);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, alarmsSeverity: this.alarmsSeverity };
	}

	/**
	 * get all alarms status type
	 */
	async getAlarmsStatusTypes() {
		this.alarmsStatusTypes = await this.analyticalDashboard.getAlarmsStatusTypes();
		this.alarmsStatusTypes = this.formatData(this.alarmsStatusTypes, this.SelectedType.status);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, alarmsStatusTypes: this.alarmsStatusTypes };
	}

	/**
	 * get all  Users related to supervisor
	 */
	async getOperator() {
		this.operators = await this.authService.getUsers();
		this.operators = this.formatData(this.operators, this.SelectedType.operators);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, operators: this.operators };
		this.setActiveOnlineOfflineOperators();
	}

	/**
	 * get all active operators related to supervisor / removing the deactivated users.
	 * which has been deactivated from admin dashboard
	 */
	async getOperators_active() {
		this.activeOperators = await this.authService.getSupervisorActiveUsers();
		this.activeOperators = this.formatData(this.activeOperators, this.SelectedType.active_operators);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, activeOperators: this.activeOperators };
		this.setActiveOnlineOfflineOperators();
	}

	/**
	 * get Online Operators ONLY
	 */
	async getOperators_online() {
		this.onlineOperators = [];
		this.onlineOperators = await this.authService.getOnlineUsers();
		this.onlineOperators = this.formatData(this.onlineOperators, this.SelectedType.online_operators);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, onlineOperators: this.onlineOperators };
		this.setActiveOnlineOfflineOperators();
	}

	/**
	 * get all region related to supervisor
	 */
	async getRegion() {
		this.regions = await this.analyticalDashboard.getBranches();
		this.regions = this.formatData(this.regions, this.SelectedType.region);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, regions: this.regions };
	}

	/**
	 * get all sites priority type
	 */
	async getSitesPriorityType() {
		this.SitesPriorityTypes = await this.analyticalDashboard.getSitesPriorityType();
		this.SitesPriorityTypes = this.formatData(this.SitesPriorityTypes, this.SelectedType.sitePriority);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, SitesPriorityTypes: this.SitesPriorityTypes };
	}

	/**
	 * get all operator status type
	 */
	async getOperatorStatuses() {
		this.OperatorStatuses = await this.analyticalDashboard.getOperatorStatuses();
		this.OperatorStatuses = this.formatData(this.OperatorStatuses, this.SelectedType.status);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, OperatorStatuses: this.OperatorStatuses };
	}

	/**
	 * get all sites status type
	 */
	async getBranchStatuses() {
		this.sitesStatuses = await this.analyticalDashboard.getAllBranchStatuses();
		this.sitesStatuses = this.formatData(this.sitesStatuses, this.SelectedType.sitePriority);
		this.analyticalDashboard.filterData = { ...this.analyticalDashboard.filterData, sitesStatuses: this.sitesStatuses };
	}

	/**
	 * reset filter options
	 */
	resetFilters(clearFilter = false) {
		this.analyticalDashboard.clearFilter(clearFilter);
		this.analyticalDashboard.resetSelectedFilters();
		this.alarmInfoText = '';
		this.generalSearch = '';
		this.shortAlarmIdText = '';
		this.askAiText = '';
		this.initFilterTimeRange();
		this.regionSelectFields = [];
		this.severitySelectFields = [];
		this.sitesSelectFields = [];
		this.operatorSelectFields = [];
		this.typeSelectFields = [];
		this.statusSelectFields = [];
		this.OperatorStatusSelectFelid = [];
		if (this.analyticalDashboard.chartSelected === this.analyticalDashboard.chartType.ALARM) {
			this.analyticalDashboard.filterParams = {
				startDate: this.analyticalDashboard.filterParams.startDate,
				// Adding maximum milliseconds "999" for timeRange filter
				endDate: this.analyticalDashboard.filterParams.endDate + 999,
				start: AppConstants.PAGE_START_CONSTANT,
				pageSize: AppConstants.DEFAULT_PAGE_SIZE
			};
			this.analyticalDashboard.getAlarmsHistoryStats(AppConstants.JSON_TEXT, false, false, false, false);
		} else if (this.analyticalDashboard.chartSelected === this.analyticalDashboard.chartType.OPERATOR) {
			this.analyticalDashboard.operatorsFilterParams = {};
			this.analyticalDashboard.filterOperatorReport(AppConstants.JSON_TEXT, false, false);
		} else {
			this.analyticalDashboard.branchParams = {};
			this.analyticalDashboard.BranchStatusReportFilter();
		}
	}

	handleEnterKey(event: KeyboardEvent, type: string) {
		event.preventDefault();
		switch (type) {
			case 'alarmInfo':
				// this.alarmInfoText ? this.filterList() : this.showDialogConfirmation('none');
				this.alarmInfoText = this.alarmInfoText ? this.alarmInfoText : this.alarmInfoText = '';
				this.filterList();
				break;
			case 'shortAlarmId':
				// this.shortAlarmIdText ? this.filterList() : this.showDialogConfirmation('none');
				this.shortAlarmIdText = this.shortAlarmIdText ? this.shortAlarmIdText : this.shortAlarmIdText = '';
				this.filterList();
				break;
			case 'generalSearch':
				// this.generalSearch ? this.filterList() : this.showDialogConfirmation('none');
				this.generalSearch = this.generalSearch ? this.generalSearch : this.generalSearch = '';
				this.filterList();
				break;
			default:
				this.askAI();
				this.showDialogConfirmation('none');
				break;
		}
	}

	async askAI(event?: Event) {
		if (this.askAiText) {
			this.spinner = true;
			let res = await this.analyticalDashboard.ask_AI(this.askAiText);
			if (res && res.success && res.data) {
				let filterOption: any = {};
				for (const key in res.data) { if (Array.isArray(res.data[key]) && res.data[key].length === 0) { delete res.data[key]; } }
				filterOption = res.data;
				if (this.selectedMoments && this.selectedMoments.length === 2) {
					const timezoneOffsetDifference = ((moment().utcOffset() - moment().tz(this.authService.getUserTimezone()).utcOffset()) * AppConstants.MINUTE_TO_MILLISECONDS);
					filterOption.startDate = this.selectedMoments[0].getTime() + timezoneOffsetDifference;
					filterOption.endDate = this.selectedMoments[1].getTime() + timezoneOffsetDifference + 999;
				}
				await this.analyticalDashboard.filterAlarmsHistoryList(filterOption);
				await this.analyticalDashboard.filterAlarmsHistoryList_withoutPagination(filterOption);
				this.spinner = false;
			} else {
				this.spinner = false;
				const message = this.translate.instant('AI.ERROR');
				const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
				this.alarmService.openSnackBar(message, confirmText);
			}
		}
	}

	/**
	 * called to check on which table we want to apply filter
	 */
	filterList() {
		if (this.analyticalDashboard.chartSelected === this.analyticalDashboard.chartType.ALARM) {
			this.alarmsFilter();
		} else if (this.analyticalDashboard.chartSelected === this.analyticalDashboard.chartType.OPERATOR) {
			this.operatorsFilter();
		} else {
			this.sitesFilter();
		}
	}

	/**
	 * called to filter alarms list
	 */
	async alarmsFilter() {
		const filterOption = this.getAlarmsFilterOptions(AppConstants.JSON_TEXT);
		await this.analyticalDashboard.filterAlarmsHistoryList(filterOption);
		await this.analyticalDashboard.filterAlarmsHistoryList_withoutPagination(filterOption);
	}

	/**
	 * called to filter operator list
	 */
	operatorsFilter() {
		const filterOption = this.getOperatorsFilterOptions(AppConstants.JSON_TEXT);
		this.analyticalDashboard.filterOperatorsReportList(filterOption);
	}

	/**
	 * called to filter sites list
	 */
	sitesFilter() {
		const filterOption = this.getSitesFilterOptions(AppConstants.JSON_TEXT);
		this.analyticalDashboard.filterSitesList(filterOption);
	}

	/**
	 * called to get alarms filter option
	 */
	getAlarmsFilterOptions(responseFormat: any) {
		const filterOption: any = {};
		filterOption.responseFormat = responseFormat;
		filterOption.details = AppConstants.SUPERVISOR_DETAILS;
		this.shortAlarmIdText = (this.shortAlarmIdText || '').trim();
		if (Utility.isNotEmpty(this.shortAlarmIdText)) {
			filterOption.shortAlarmIds = Utility.stringToArrayByComma(this.shortAlarmIdText);
		}
		this.alarmInfoText = (this.alarmInfoText || '').trim();
		if (Utility.isNotEmpty(this.alarmInfoText)) {
			filterOption.alarmInfo = Utility.stringToArrayByComma(this.alarmInfoText);
		}
		if (Utility.isNotEmpty(this.regionSelectFields)) {
			filterOption.areaNames = this.regionSelectFields;
		}
		if (Utility.isNotEmpty(this.sitesSelectFields)) {
			filterOption.branchIds = this.sitesSelectFields;
		}
		if (Utility.isNotEmpty(this.operatorSelectFields)) {
			filterOption.userIds = this.operatorSelectFields;
		}
		if (Utility.isNotEmpty(this.typeSelectFields)) {
			filterOption.alarmTypes = this.typeSelectFields;
		}
		if (Utility.isNotEmpty(this.severitySelectFields)) {
			filterOption.alarmSeverities = this.severitySelectFields;
		}
		if (Utility.isNotEmpty(this.selectFields)) {
			filterOption.alarmStatuses = this.selectFields;
		}
		if (this.selectedMoments && this.selectedMoments.length === 2) {
			const timezoneOffsetDifference = ((moment().utcOffset() - moment().tz(this.authService.getUserTimezone()).utcOffset()) * AppConstants.MINUTE_TO_MILLISECONDS);
			filterOption.startDate = this.selectedMoments[0].getTime() + timezoneOffsetDifference;
			filterOption.endDate = this.selectedMoments[1].getTime() + timezoneOffsetDifference + 999;
		}
		filterOption.start = AppConstants.PAGE_START_CONSTANT;
		// Issue #275 Staging - Setting Default Page size on filteration Intialization
		filterOption.pageSize = AppConstants.DEFAULT_PAGE_SIZE;
		// filterOption.pageSize = this.appConfigService.appConfig.PDF_REPORT_ALARMS_COUNT;
		return filterOption;
	}

	/**
	 * called to get operator filter options
	 */
	getOperatorsFilterOptions(responseFormat: any) {
		const filterOption: any = {};
		filterOption.responseFormat = responseFormat;
		filterOption.details = AppConstants.SUPERVISOR_DETAILS;
		if (Utility.isNotEmpty(this.operatorSelectFields)) {
			filterOption.userIds = this.operatorSelectFields;
		}
		if (Utility.isNotEmpty(this.sitesSelectFields)) {
			filterOption.branchIds = this.sitesSelectFields;
		}
		if (Utility.isNotEmpty(this.regionSelectFields)) {
			filterOption.regions = this.regionSelectFields;
		}
		if (Utility.isNotEmpty(this.OperatorStatusSelectFelid)) {
			filterOption.statuses = this.OperatorStatusSelectFelid;
		}
		return filterOption;
	}

	/**
	 * called to get sites filter options
	 */
	getSitesFilterOptions(responseFormat: any = false) {
		const filterOption: any = {};
		if (responseFormat) {
			filterOption.responseFormat = responseFormat;
		}
		if (Utility.isNotEmpty(this.regionSelectFields)) {
			filterOption.areaNames = this.regionSelectFields;
		}
		if (Utility.isNotEmpty(this.sitesSelectFields)) {
			filterOption.branchIds = this.sitesSelectFields;
		}
		if (Utility.isNotEmpty(this.typeSelectFields)) {
			filterOption.branchTypes = this.typeSelectFields;
		}
		if (Utility.isNotEmpty(this.OperatorStatusSelectFelid)) {
			filterOption.statuses = this.OperatorStatusSelectFelid;
		}
		if (Utility.isNotEmpty(this.statusSelectFields)) {
			filterOption.branchStatuses = this.statusSelectFields;
		}
		if (Utility.isNotEmpty(this.operatorSelectFields)) {
			filterOption.userIds = this.operatorSelectFields;
		}

		// General search filter - new req
		// this.generalSearch = (this.generalSearch || '').trim();
		// if (Utility.isNotEmpty(this.generalSearch)) {
		// 	filterOption.generalSearch = Utility.stringToArrayByComma(this.generalSearch);
		// }

		return filterOption;
	}

	/**
	 * called to assign alarms to operator
	 */
	assignAction() {
		this.operators.forEach(operator => {
			if (operator.value === this.operatorSelectFieldsForAction[0]) {
				this.analyticalDashboard.selectedOperatorsId = operator.value;
				this.analyticalDashboard.isOperatorsOverloaded(this.analyticalDashboard.selectedOperatorsId);
				return;
			}
		});
	}

	/**
	 * called to takeover alarms to supervisor
	 */
	escalateAction() {
		this.analyticalDashboard.actionOnAlarms(this.analyticalDashboard.selectedAlarms, this.analyticalDashboard.selectedOperatorsId, AppConstants.ACTION_ESCALATE);
	}

	/**
	 * called to unAssign alarms
	 */
	unAssignAction() {
		this.analyticalDashboard.actionOnAlarms(this.analyticalDashboard.selectedAlarms, this.analyticalDashboard.selectedOperatorsId, AppConstants.ACTION_UN_ASSIGN);
	}

	/**
	 * called when want to select or unSelect a filter
	 */
	changeSelectedFilter(selectedFilter: any) {
		this.selectedFilter[selectedFilter] ? this.selectedFilter[selectedFilter] = false : this.selectedFilter[selectedFilter] = true;
		this.changeHeight.emit();
	}

	unselectFilter(selectedFilter: any) {
		this.selectedFilter[selectedFilter] = false;
		this.changeHeight.emit();
	}

	// Close all opened/Selected filters
	collapseFilters() {
		let filtersNames = ["region", "sites", "operators", "type", "alarmsSeverity", "status", "timeRange", "shortAlarmID", "alarmInfo", "sitePriority"];
		for (let i = 0; i < filtersNames.length; i++) {
			this.unselectFilter(filtersNames[i])
		}
	}

	/**
	 * called to download alarms pdf
	 */
	async downloadAlarmsPdfReport() {
		const filterOption2 = this.getAlarmsFilterOptions("json");
		const response: any = await this.http.post<any>(ApiConstants.URL_ALARM_HISTORY_REPORT, filterOption2).toPromise();
		let totalcount = Number(response.totalcount)
		if (totalcount > this.appConfigService.appConfig.PDF_REPORT_ALARMS_COUNT) {
			//show warning message
			const title = this.translate.instant('FILTER_DATA.PDF_WARNING_TITLE');
			let message = this.translate.instant('FILTER_DATA.PDF_WARNING_MESSAGE');
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
			const typeOfDialogBox = 2;
			const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
			this.dialog.open(ConfirmDialogComponent, {
				data: dialogData
			});
		}
		else {
			const filterOption = this.getAlarmsFilterOptions(AppConstants.PDF_TEXT);
			// Issue #275 Staging - Passing ALARMS_COUNT Value to Full PDF requestBody
			filterOption.pageSize = this.appConfigService.appConfig.PDF_REPORT_ALARMS_COUNT;
			await this.analyticalDashboard.getAlarmsHistoryStatsPDF(filterOption)
		}
	}

	/**
	 * called to download alarms csv
	 */
	async downloadAlarmsCsvReport() {
		const filterOption2 = this.getAlarmsFilterOptions("json");
		const response: any = await this.http.post<any>(ApiConstants.URL_ALARM_HISTORY_REPORT, filterOption2).toPromise();
		let totalcount = Number(response.totalcount)
		if (totalcount > this.appConfigService.appConfig.PDF_REPORT_ALARMS_COUNT) {
			//show warning message
			const title = this.translate.instant('FILTER_DATA.PDF_WARNING_TITLE');
			let message = this.translate.instant('FILTER_DATA.PDF_WARNING_MESSAGE');
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
			const typeOfDialogBox = 2;
			const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
			this.dialog.open(ConfirmDialogComponent, {
				data: dialogData
			});
		}
		else {
			const filterOption = this.getAlarmsFilterOptions(AppConstants.CSV_TEXT);
			// Issue #275 Staging - Passing ALARMS_COUNT Value to Full PDF requestBody
			filterOption.pageSize = this.appConfigService.appConfig.PDF_REPORT_ALARMS_COUNT;
			await this.analyticalDashboard.getAlarmsHistoryStatsCSV(filterOption)
		}
	}

	/**
	 * called to download operator pdf
	 */
	async downloadOperatorsPdfReport() {
		const filterOption = this.getOperatorsFilterOptions(AppConstants.PDF_TEXT);
		await this.analyticalDashboard.getOperatorsStatsPDF(filterOption);
	}

	/**
	 * called to download operator csv
	 */
	async downloadOperatorsCsvReport() {
		const filterOption = this.getOperatorsFilterOptions(AppConstants.CSV_TEXT);
		await this.analyticalDashboard.getOperatorsStatsCSV(filterOption);
	}


	/**
	 * called to download sites pdf
	 */
	async downloadSitesPdfReport() {
		const filterOption = this.getSitesFilterOptions(AppConstants.PDF_TEXT);
		await this.analyticalDashboard.getSitesStatsPDF(filterOption);
	}

	/**
	 * called to download sites csv
	 */
	async downloadSitesCsvReport() {
		const filterOption = this.getSitesFilterOptions(AppConstants.CSV_TEXT);
		await this.analyticalDashboard.getSitesStatsCSV(filterOption);
	}

	/**
	 * To show confirmation dialog
	 * @param actionType selected type of action
	 */
	showDialogConfirmation(actionType: any) {
		if (actionType === 'none') {
			return; // No action needed
		}

		this.selectedAction = actionType;
		if (this.analyticalDashboard.selectedAlarms.length === 0) {
			this.showNoAlarmsSelectedDialog();
		} else if (actionType === this.actionType.assign && this.operatorSelectFieldsForAction.length === 0) {
			this.showNoOperatorsSelectedDialog();
		} else if (actionType === this.actionType.takeover) {
			this.showTakeoverDialog(actionType);
		} else if (actionType === this.actionType.unAssign) {
			this.showUnAssignDialog(actionType);
		} else {
			this.alarmsAction();
		}
	}

	/**
	 * called to show no alarms selected dialog
	 */
	showNoAlarmsSelectedDialog() {
		const title = this.translate.instant('ANALYTICAL_FILTERS.ERROR_TITLE');
		let message = this.translate.instant('ANALYTICAL_FILTERS.NO_ALARM_SELECTED_ERROR_MESSAGE');
		const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
		const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
		const typeOfDialogBox = 2;
		const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
		this.dialog.open(ConfirmDialogComponent, {
			data: dialogData
		});
	}

	/**
	 * called to show no operator selected dialog
	 */
	showNoOperatorsSelectedDialog() {
		const title = this.translate.instant('ANALYTICAL_FILTERS.ERROR_TITLE');
		let message = this.translate.instant('ANALYTICAL_FILTERS.ERROR_MESSAGE');
		const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
		const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
		const typeOfDialogBox = 2;
		const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
		this.dialog.open(ConfirmDialogComponent, {
			data: dialogData
		});
	}

	/**
	 * called to show takeOver dialog
	 */
	showTakeoverDialog(actionType: any) {
		const title = actionType + ' ' + this.translate.instant('ANALYTICAL_FILTERS.CHART_TITLE_ALARMS');
		let message = this.translate.instant('ANALYTICAL_FILTERS.ALARMS_ACTION_TEXT') + ' ' + this.translate.instant('ANALYTICAL_FILTERS.TAKEOVER_MESSAGE');
		if (actionType !== this.actionType.takeover) {
			message += actionType === this.actionType.unAssign ? ' ' + this.translate.instant('ANALYTICAL_FILTERS.UN_ASSIGN_ACTION_TEXT') : ' ' + this.translate.instant('ANALYTICAL_FILTERS.ACTION_TEXT');
		} else {
			message += ' ' + this.translate.instant('ANALYTICAL_FILTERS.UN_ASSIGN_ACTION_TEXT');
		}
		const confirmText = actionType;
		const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
		const typeOfDialogBox = 1;
		const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: dialogData
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) {
				this.alarmsAction();
			}
		});
	}

	/**
	 * called to show unAssign dialog
	 */
	showUnAssignDialog(actionType: any) {
		let count = 0;
		let title: string;
		let message: string;
		let typeOfDialogBox: number;
		let confirmText: string;
		for (const alarm of this.analyticalDashboard.selectedAlarms) {
			if (alarm.operator) {
				count++;
				break;
			}
		}
		if (!count) {
			title = this.translate.instant('ANALYTICAL_FILTERS.ERROR_TITLE');
			message = this.translate.instant('ANALYTICAL_FILTERS.ALREADY_UN_ASSIGNED_ALARMS_TEXT');
			confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			typeOfDialogBox = 2;
		} else {
			title = actionType + ' ' + this.translate.instant('ANALYTICAL_FILTERS.CHART_TITLE_ALARMS');
			message = this.translate.instant('ANALYTICAL_FILTERS.ALARMS_ACTION_TEXT') + ' ' + actionType + ' ' + this.translate.instant('ANALYTICAL_FILTERS.UN_ASSIGN_ACTION_TEXT');
			confirmText = actionType;
			typeOfDialogBox = 1;
		}
		const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
		const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: dialogData
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) {
				this.alarmsAction();
			}
		});
	}

	/**
	 * called to check which option to perform
	 */
	alarmsAction() {
		switch (this.selectedAction) {
			case this.actionType.assign:
				this.assignAction();
				break;
			case this.actionType.unAssign:
				this.unAssignAction();
				break;
			default:
				this.escalateAction();
		}
	}

	/**
	 * get all operators with inactive as disabled
	 */
	private setActiveOnlineOfflineOperators() {
		const activeOperators = new Set(this.activeOperators.map(op => op.value));
		const onlineUsers = new Set(this.onlineOperators.map(op => op.value));
		this.activeOnlineOfflineOperators = this.operators.filter(op => activeOperators.has(op.value)).map(op => {
			return { ...op, disabled: !onlineUsers.has(op.value) };
		});
	}
}
