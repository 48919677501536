<div class="filter-data">
	<div class="header">
		<div class="box-title">
			<div class="box-title-icon">
				<img class="header-icon" alt="header-filter"
					[src]="utility.getImagePath('filter.png')">
			</div>
			<div>
				{{ "FILTER_DATA.TITLE" | translate }}

			</div>
		</div>
	</div>
	<div class="main-body"
		*ngIf="authService.currentUserSession">
		<form mat-form>
			<!-- Commenting Users filters for Operator History Page -->
			<!-- <div class="filter-row"
				*ngIf="authService.hasRole(appConstants.Supervisor) || authService.hasRole(appConstants.Admin)">
				<div class="form-header">{{ "FILTER_DATA.SELECT_USER" | translate }}</div>
				<app-rm-multi-select [placeholder]="allUsersPlaceholder"
					[inputData]="formatedUserData"
					[showDropDown]="isShowingUserDropdown"
					(dropdownToggled)="toggleUserDropdown($event)"
					(dataChanged)="selectedUsers = $event"></app-rm-multi-select>
			</div> -->
			<div class="filter-row">
				<div class="form-header">{{ "FILTER_DATA.SELECT_SITES" | translate }}</div>
				<app-rm-multi-select [placeholder]="allSitesPlaceholder"
					[inputData]="formatedBranchData"
					[showDropDown]="isShowingBranchesDropdown"
					(dropdownToggled)="toggleBranchDropdown($event)"
					(dataChanged)="selectedBranches = $event"></app-rm-multi-select>
			</div>
			<div class="filter-row">
				<div class="form-header">{{ "FILTER_DATA.SELECT_ALARM_TYPE" | translate }}</div>
				<app-rm-multi-select [placeholder]="allAlarmTypesPlaceholder"
					[inputData]="formatedAlarmTypesData"
					[showDropDown]="isShowingAlarmTypesDropdown"
					(dataChanged)="selectedAlarmTypes = $event"></app-rm-multi-select>
			</div>
			<div class="filter-row">
				<div class="form-header">{{ "FILTER_DATA.SELECT_STATUS" | translate }}</div>
				<app-rm-multi-select [placeholder]="allStatusesPlaceholder"
					[inputData]="formatedStatusesData"
					[showDropDown]="isShowingStatusesDropdown"
					(dataChanged)="selectedStatus = $event"></app-rm-multi-select>
			</div>
			<div class="filter-row">
				<div class="form-header">{{ "FILTER_DATA.SELECT_SEVERITY" | translate }}</div>
				<app-rm-multi-select [placeholder]="allSeveritiesPlaceholder"
					[inputData]="formatedSeveritiesData"
					[showDropDown]="isShowingSeveritiesDropdown"
					(dataChanged)="selectedSeverity = $event"></app-rm-multi-select>
			</div>
			<div class="filter-row">
				<div class="form-header">{{ "FILTER_DATA.ENTER_ALARM_ID" | translate }}</div>
				<input type="text"
					class="search-input"
					[(ngModel)]="shortAlarmIdText"
					name="shortAlarmsId"
					autocomplete="off">
			</div>

			<div class="filter-row">
				<div class="form-header">{{ "FILTER_DATA.ENTER_ALARM_INFO" | translate }}</div>
				<input type="text"
					class="search-input"
					[(ngModel)]="alarmInfoText"
					name="alarmInfo"
					autocomplete="off">
			</div>

			<!-- DatePicker Filters -->
			<div class="filter-row">
				<div class="form-header"> {{ "FILTER_DATA.SELECT_DATE" | translate }} </div>
				<div class="form-element">
					<div class="date-input">
						<div class="label">
							{{ "FILTER_DATA.FROM" | translate }}
						</div>
						<div class="form-field">
							<div class="date-time-picker-div owl-form-field-infix date-picker-1" >
								<input [owlDateTimeTrigger]="dateAndTime1"
								    class="date-time-picker" 
									[owlDateTime]="dateAndTime1" 
									[max]="currentDate"
									[(ngModel)]="selectedMoments" 
									[selectMode]="'rangeFrom'" 
									[placeholder]="placeholder.selectDateAndTime"
									name="dateTimeRange">
						
								<div class="mat-form-field-suffix ng-tns-c20-74 ng-star-inserted" 
									[owlDateTimeTrigger]="dateAndTime1">
									<mat-datepicker-toggle class="date-picker-toggle mat-datepicker-toggle">
										<i class="fas fa-calendar-alt" aria-hidden="true"></i>
									</mat-datepicker-toggle>
								</div>
								<owl-date-time #dateAndTime1 
								showSecondsTimer="true" 
								[hour12Timer]="isHour12Format" 
								class="date-picker-1"></owl-date-time>
							</div>
						</div>
					</div>

					<div class="date-input">
						<div class="label">
							{{ "FILTER_DATA.TO" | translate }}
						</div>
						<div class="form-field">
							<div class="date-time-picker-div owl-form-field-infix date-picker-2">
								<input [owlDateTimeTrigger]="dateAndTime2" 
								    class="date-time-picker" 
									[owlDateTime]="dateAndTime2"
									[min]="selectedMoments[0]" 
									[max]="currentDate" 
									[(ngModel)]="selectedMoments" 
									[selectMode]="'rangeTo'"
									[placeholder]="placeholder.selectDateAndTime" 
									name="dateTimeRange">
						
								<div class="mat-form-field-suffix ng-tns-c20-74 ng-star-inserted" 
									[owlDateTimeTrigger]="dateAndTime2">
									<mat-datepicker-toggle class="date-picker-toggle mat-datepicker-toggle">
										<i class="fas fa-calendar-alt" aria-hidden="true"></i>
									</mat-datepicker-toggle>
								</div>
								<owl-date-time 								
								showSecondsTimer="true" 
								[hour12Timer]="isHour12Format"
								#dateAndTime2></owl-date-time>
							</div>

						</div>
					</div>
				</div>
			</div>

			<div class="submit-data">
				<button class="btn btn-info btn-filter"
					(click)="filterData()"> {{ "BTN.FILTER" | translate }} </button>
				<button class="btn btn-info ml-3"
					(click)="getFullReport()"
					download>{{ "FILTER_DATA.GET_FULL_REPORT_LINK" | translate}}</button>
				<button class="btn btn-info ml-3"
					(click)="clearFilters()"> {{ "BTN.CLEAR" | translate }} </button>
			</div>
		</form>
	</div>
</div>