<div class="alarms-row">
	<div class="alarms-content">
		<div class="alarm-history">
			<div class="table-container"
				id="sites-table-container">
				<table mat-table aria-describedby="branch-details"
					matSort
					[dataSource]="branchDetailDataSource"
					*ngIf="branchDetailDataSource && branchDetailDataSource.length > 0; else noActions">

					<ng-container matColumnDef="Region">
						<th scope="col" mat-header-cell
							mat-sort-header
							*matHeaderCellDef> {{ "ANALYTICAL_ALARM_HISTORY.REGION" | translate}}
						</th>
						<td mat-cell
							*matCellDef="let branchDetail">
							{{branchDetail.areaName}}</td>
					</ng-container>

					<ng-container matColumnDef="Sites">
						<th scope="col" mat-header-cell
							mat-sort-header
							*matHeaderCellDef> {{ "ANALYTICAL_ALARM_HISTORY.SITE" | translate}}
						</th>
						<td mat-cell
							*matCellDef="let branchDetail"> {{branchDetail.branchName}} </td>
					</ng-container>
					<ng-container matColumnDef="Site Priority">
						<th scope="col" mat-header-cell
							mat-sort-header
							*matHeaderCellDef> {{ "ANALYTICAL_ALARM_HISTORY.SITE_PRIORITY" | translate}}
						</th>
						<td mat-cell
							*matCellDef="let branchDetail"> {{branchDetail.branchType}} </td>
					</ng-container>
					<ng-container matColumnDef="Operator">
						<th scope="col" mat-header-cell
							mat-sort-header
							*matHeaderCellDef>{{ "ANALYTICAL_ALARM_HISTORY.OPERATORS" | translate}}
						</th>
						<td mat-cell
							*matCellDef="let branchDetail"
							matTooltip="{{formatOperatorsNames(branchDetail?.operators)}}">
							{{formatOperatorsCounter(branchDetail?.operators)}}
						</td>
					</ng-container>
					<ng-container matColumnDef="Status">
						<th scope="col" mat-header-cell
							mat-sort-header
							*matHeaderCellDef> {{ "ANALYTICAL_ALARM_HISTORY.STATUS" | translate}}</th>
						<td mat-cell
							class="status-col"
							*matCellDef="let branchDetail"> {{branchDetail.status}} </td>
					</ng-container>
					<ng-container matColumnDef="Asset">
						<th scope="col" mat-header-cell
							mat-sort-header
							*matHeaderCellDef> {{ "ANALYTICAL_ALARM_HISTORY.ASSET" | translate}}</th>
						<td mat-cell
							*matCellDef="let branchDetail"
							matTooltip="{{formatAssetsNames(branchDetail.devices)}}">
							{{formatAssetsCounter(branchDetail.devices)}}</td>
					</ng-container>
					<tr mat-header-row
						*matHeaderRowDef="displayedColumns; sticky: true">
					</tr>
					<tr mat-row
						*matRowDef="let row; columns:displayedColumns;"
						[ngClass]="{'highlight': selectedBranchId == row.id}"
						(click)="highlight(row)"></tr>
				</table>
				<ng-template #noActions>
					<div class="box-content-no-data">
						{{'ALARM_HISTORY.NO_DATA' | translate}}
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>