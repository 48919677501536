import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AlarmService } from '../services/alarm.service';
import { Alarm } from '../../models/alarm.model';
import { AssetDoc } from '../../models/asset-doc.model';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AppConstants } from '../constants/app-constants';
import { DateUtil } from '../util/date-util';
import { AuthenticationService } from '../services/authentication.service';
import { ConfidenceMonitoringService } from '../services/confidence-monitoring.service';
import { Utility } from '../util/app-utils';

@Component({
	selector: 'app-asset-docs',
	templateUrl: './asset-docs.component.html',
	styleUrls: ['./asset-docs.component.scss']
})

export class AssetDocsComponent implements OnInit {
	alarmOrBranchId: string;
	public assetsDocs: AssetDoc[] = [];
	public selectedAssetDocId: any = null;
	@Output() selectedAssetDoc = new EventEmitter<any>();
	appConstants = AppConstants;
	component: any;
	AlarmSelectedSubscriber: any;
	utility = Utility;

	constructor(
		private alarmService: AlarmService,
		private userSessionStateService: UserSessionStateService,
		private authService: AuthenticationService,
		public confidenceMonitoringService: ConfidenceMonitoringService
	) { }

	ngOnInit() {
		// Subscribe to alarm selection
		this.AlarmSelectedSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe(async (alarm: Alarm) => {
			this.assetsDocs = [];
			this.selectedAssetDocId = null;
			if (alarm) {
				this.alarmOrBranchId = alarm.id;
				const branchId = alarm.branch['id'];
				await this.getAssetData(branchId);
				this.restorePrevSelection(alarm.id);
			}
		});

		this.confidenceMonitoringService.getSitesChangedNotification().subscribe(async (branch: any) => {
			this.assetsDocs = [];
			this.selectedAssetDocId = null;
			if (branch) {
				this.alarmOrBranchId = branch.id;
				await this.getAssetData(this.alarmOrBranchId);
				this.restorePrevSelection(this.alarmOrBranchId);
			}
		});

	}

	ngOnDestroy() {
		this.AlarmSelectedSubscriber = Utility.Unsubscribe(this.AlarmSelectedSubscriber);
	}

	showAsset(assetDoc: AssetDoc) {
		this.selectedAssetDocId = assetDoc ? assetDoc.id : undefined;
		this.selectedAssetDoc.emit(assetDoc);
		this.userSessionStateService.saveSelectedAssetId(this.alarmOrBranchId, this.selectedAssetDocId);
		this.confidenceMonitoringService.changeAssetSelected(assetDoc);
	}

	async getAssetData(branchId: string) {
		this.assetsDocs = await this.alarmService.getAssetData(branchId);
	}

	/**
	 * Returns date in format dd/MM/yyyy;
	 * @param activationTime activation time
	 */
	getFormatedDate(activationTime: any, format: string) {
		return DateUtil.getFormattedDate(activationTime, format, this.authService.getUserTimezone());
	}

	/**
	 * Restore previous state of the asset doc selection in the asset doc list
	 */
	restorePrevSelection(alarmId: string) {
		if (this.assetsDocs && this.assetsDocs.length > 0) {
			// Get previous selected asset doc id.
			const docId: string = this.userSessionStateService.getSelectedAssetDocId(alarmId);
			let selDoc: AssetDoc = null;
			if (docId) {
				selDoc = this.assetsDocs.find((doc) => doc.id === docId);
			}
			if (selDoc) {
				this.showAsset(selDoc);
			}
		}
	}
}
