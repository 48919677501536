<div class="div-container p-5 justify-content-center text-center">
	<div class="heading" (mouseover)='read(header)' (mouseout)='stopRead()'>
		{{ header | translate }}
	</div>
	<div class="content" (mouseover)='read(title)' (mouseout)='stopRead()'>
		{{ title | translate }}
	</div>
</div>
<div class="d-flex justify-content-center mt-2 action-buttons">
	<button class="mr-2 btn btn-info"
		(click)="yesClicked()" (mouseover)='read(YES)' (mouseout)='stopRead()'>
		{{ YES }}
	</button>
	<button class="cancel-button btn btn-info"
		(click)="noClicked()" (mouseover)='read(NO)' (mouseout)='stopRead()'>
		{{ NO }}
	</button>
</div>