import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Subscription, lastValueFrom } from 'rxjs';
import { Utility } from '../util/app-utils';
import { Alarm } from 'src/models/alarm.model';
import { AlarmService } from '../services/alarm.service';
import { HttpClient } from "@angular/common/http"
import { ApiConstants } from '../constants/api-constants'; { }
import { VideoStreamService } from '../services/video-stream.service';

@Component({
	selector: 'app-thermal-feeds',
	templateUrl: './thermal-feeds.component.html',
	styleUrls: ['./thermal-feeds.component.scss']
})

export class ThermalFeedsComponent implements OnInit, OnDestroy, OnChanges {
	alarmSelectedSubscription: Subscription;
	alarm: Alarm;
	public videoURL: string;
	public scShootURL: string;
	isImg: boolean = false;
	isVid: boolean = false;
	utility = Utility;
	public fullScreenVideo: boolean = false;
	public fullScreenVideoStyle: any = {};
	public fullScreenImage: boolean = false;
	public fullScreenImageStyle: any = {};

	@Input() alarmId: string;

	constructor(private alarmService: AlarmService, public http: HttpClient,
		public videoStreamService: VideoStreamService) { }

	ngOnInit() {
		if (this.alarmId) {
			this.fetchAlarm(this.alarmId);
		};
		if (this.fullScreenVideo) {
			this.fullScreenVideo = false;
		}
		if (this.fullScreenImage) {
			this.fullScreenImage = false;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.alarmId && !changes.alarmId.isFirstChange()) {
			this.fetchAlarm(changes.alarmId.currentValue);
		}
	}

	ngOnDestroy() {
		//
	}

	async fetchAlarm(alarmId: string) {
		try {
			const response: any = await lastValueFrom(this.http.get<any>(ApiConstants.URL_ALARMS + "/alarm/" + alarmId, { params: {} }));
			if (response.success === true) {
				if (response.data.aiVideo === "") {
					this.videoURL = "noVID"
					this.isVid = false;
				} else {
					this.videoURL = response.data.aiVideo;
					this.isVid = true;
				}

				if (response.data.aiPhoto === "") {
					this.scShootURL = "noIMG"
					this.isImg = false;
				} else {
					this.scShootURL = response.data.aiPhoto;
					this.isImg = true;
				}
			}
		} catch (err) {
		}
	}


	async updateAlarm(alarmOption: any) {
		try {
			const response: any = await lastValueFrom(this.http.get<any>(ApiConstants.URL_ALARMS + "/alarm/" + this.alarmService.selectedAlarm.id, { params: { ...alarmOption } }));
			if (response.success === true && response.data.aiVideo !== "") {
				this.videoURL = response.data.aiVideo;
				this.isVid = true;
			}
		} catch (err) {
		}
	}

	showFullScreenImage() {
		this.closeFullScreenImage();
		if (this.fullScreenVideo) {
			this.fullScreenVideo = false;
		}
		// Let Angular CD to detect changes.
		setTimeout(() => {
			this.fullScreenImage = true;
			this.updateFullscreenPlayerStyle();
			window.onresize = () => this.updateFullscreenPlayerStyle();
		}, 50);
	}

	showFullScreenVideo() {
		this.closeFullScreenVideo();
		if (this.fullScreenImage) {
			this.fullScreenImage = false;
		}
		// Let Angular CD to detect changes.
		setTimeout(() => {
			this.fullScreenVideo = true;
			this.updateFullscreenPlayerStyle();
			window.onresize = () => this.updateFullscreenPlayerStyle();
		}, 50);
		this.videoStreamService.fullScreenVideo = true;
	}

	closeFullScreenVideo() {
		this.fullScreenVideo = false;
		window.onresize = null;
		this.videoStreamService.fullScreenVideo = false;
	}

	closeFullScreenImage() {
		this.fullScreenImage = false;
		window.onresize = null;
		this.videoStreamService.fullScreenVideo = false;
	}

	/**
	 * Show full screen player just above map
	 */
	updateFullscreenPlayerStyle() {
		const div = document.getElementsByClassName('asset-view-section')[0];
		if (div) {
			const mapAreaDimension = div.getBoundingClientRect();
			this.fullScreenVideoStyle = {
				height: mapAreaDimension.height + 'px',
				width: mapAreaDimension.width + 'px',
				top: mapAreaDimension.top + 'px',
				left: mapAreaDimension.left + 'px',
			};
		}
	}

}
