<div class="alerts-module">
	<div class="alerts-list-module">
		<div class="alerts-header">
			<img [src]="utility.getImagePath('alert.png')" alt="alerts" class="alerts-logo">
			<span class="alerts-title">{{'ALERTS.ALARMS' | translate}}</span>
			<img *ngIf="this.alarmService.allowAudio" [src]="utility.getImagePath('unmute.png')" alt="unmute" class="audio" (click)="toggleAudio()">
			<img *ngIf="!this.alarmService.allowAudio" [src]="utility.getImagePath('mute.png')" alt="mute" class="audio"(click)="toggleAudio()">
		</div>
		<div class="alerts-operators">
			<div class="search-bar">
				<input type="text" class="search-input" [(ngModel)]="searchText">
			</div>
			<div class="search-button">
				<img [src]="utility.getImagePath('search.png')" alt="search" class="search-logo">
			</div>
		</div>
		<div class="alerts-sorting">
			<div class="sorting-type" (click)="sortAlarms(alarmSortFields.location)">
				<span class="sorting-title"> {{'ALERTS.LOCATION' | translate}} </span>
				<img class="sorting-logo" alt="sorting" [src]="utility.getImagePath('sort2.png')">
			</div>
			<div class="sorting-type" (click)="sortAlarms(alarmSortFields.time)">
				<span class="sorting-title">{{'ALERTS.DATE_TIME' | translate}}</span>
				<img class="sorting-logo" alt="sorting" [src]="utility.getImagePath('sort2.png')">
			</div>
			<div class="sorting-type" (click)="sortAlarms(alarmSortFields.severity)">
				<span class="sorting-title">{{'ALERTS.SEVERITY' | translate}}</span>
				<img class="sorting-logo" alt="sorting" [src]="utility.getImagePath('sort2.png')">
			</div>
			<div class="sorting-type" (click)="sortAlarms(alarmSortFields.status)">
				<span class="
				sorting-title">{{'ALERTS.STATUS' | translate}}</span>
				<img class="sorting-logo" alt="sorting" [src]="utility.getImagePath('sort2.png')">
			</div>
		</div>
		<mat-list class="alerts-list" *ngIf="alarms">
			<ng-container	*ngFor="let alarm of alarms| alarmSearchFilter:searchText:authService.getUserTimezone():toggleOnAlarmListChange; trackBy: trackByAlarmId;">
				<mat-list-item class="alerts-list-items" [class.selected]="alarm.id === selectedAlarmId && !confidenceMonitoringMode" [class.newAlarm]="newAlarm" 
				[class.repeatedAlarm]="alarm.repetition > 1" (click)="selectAlarm(alarm)">
					<div matListAvatar class="alerts-type">
						<img [src]="alarm.icon" class="alerts-type-logo" [matTooltip]='alarm.type' alt="sds">
						<span class="alerts-type-text">{{getTextToShowBelowIcon(alarm.type)}}</span>
					</div>
					<div class="alerts-details">
						<span class="branch-name" [matTooltip]='alarm.branch.name'>{{alarm.branch.name}}</span>
						<span class="alerts-time">{{getDate(alarm.activationTime)}}</span>
					</div>
					<div class="alerts-condition">
						<span class="alerts-severity">{{alarm.severity}}</span>
						<span class="alerts-status">{{alarm.status}}</span>
					</div>
					<!-- <span class="badge badge-danger" data-toggle="tooltip" data-placement="right" title="Repetition times">{{alarm.repetition>1 ?alarm.repetition : null}}</span> -->
					<div class="list-end-arrow"></div>
				</mat-list-item>
			</ng-container>
		</mat-list>
	</div>
	<div class="alarms-counts">
		<div class="title"><span>{{'ALERTS.TOTAL_UNATTENDED_ALARMS' | translate}}</span></div>
		<div class="count"><span>{{unAttentedAlarmCount}}</span></div>
	</div>
	<div class="alarms-counts">
		<span class="title">{{'ALERTS.ESCALATED_ALARMS' | translate}}</span>
		<span class="count">{{escalatedAlarmCount}}</span>
	</div>
</div>