import { Component, Input } from '@angular/core';
import { VideoFeed } from 'src/models/video-feed.model';
import { VideoStreamService } from 'src/app/services/video-stream.service';
import { Utility } from 'src/app/util/app-utils';

@Component({
	selector: 'app-sites-video-feeds',
	templateUrl: './sites-video-feeds.component.html',
	styleUrls: ['./sites-video-feeds.component.scss']
})
export class SitesVideoFeedsComponent {
	@Input() selectedBranch = null;
	public videoFeeds: VideoFeed[] = [];
	public selectedVideoFeed: any = null;
	public selectedVideoFeedNo: any = null;
	utility = Utility;

	constructor(public videoStreamService: VideoStreamService) { }

	async ngOnChanges() {
		if (this.selectedBranch) {
			await this.loadVideoFeeds(this.selectedBranch.id);
		}
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		this.selectedVideoFeed = null;
		this.videoStreamService.setEmptyVideoSources();
		// this.videoStreamService.stopStream();
	}

	async loadVideoFeeds(branchId: string) {
		this.videoFeeds = [];
		this.selectedVideoFeed = null;
		this.videoFeeds = await this.videoStreamService.fetchVideoSourcesList(branchId, true);
		if (!this.videoFeeds) {
			this.videoFeeds = [];
		}

		// By default first video is shown
		this.selectedVideoFeed = this.videoFeeds.length > 0 ? this.videoStreamService.videoSourceOne : null;
		this.selectedVideoFeedNo = 0;
	}

	// async shareVideo() {
	// 	// on api side, video index starts from 1
	// 	await this.videoStreamService.shareVideo();
	// }

	// async shareVideo() {
	// 	await this.videoStreamService.shareVideo(this.videoStreamService.sourceOneUrl);
	// 	await this.videoStreamService.shareVideo(this.videoStreamService.sourceOneName, 'alarmType');
	// }

	// async unShareVideo() {
	// 	// on api side, video index starts from 1
	// 	await this.videoStreamService.unShareVideo();
	// }

	// async controlVideo() {
	// 	// on api side, video index starts from 1
	// 	await this.videoStreamService.controlVideo(this.videoStreamService.sourceOneIp);
	// }

	onVideoSelect(videoNo: number) {
		if (this.selectedVideoFeedNo === videoNo) {
			return;
		}

		// destroy the old player and start new one.
		this.selectedVideoFeed = null;
		// Allow angular to detect changes. It should destroy old player before starting new one.
		setTimeout(async () => {
			this.selectedVideoFeedNo = videoNo;
			await this.videoStreamService.setVideoSourceOne(videoNo);
			this.selectedVideoFeed = this.videoStreamService.videoSourceOne;
		}, 400);
	}

}
