<div class="content">
    <table aria-describedby="options">
        <tr class="content-row row-12">
            <td class="col-6">
                <mat-form-field appearance="fill">
                    <mat-label>{{ "ADD_ALARM.SELECT_ALARM_TYPE" | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedAlarmType" name="selectedAlarmType"
                        (selectionChange)="onAlarmTypeChange($event)" [disabled]="isSelectedBranch === false">
                        <mat-option *ngFor="let type of alarmTypes" [value]="type._id">
                            <span>{{type.alarmType}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td class="col-6">
                <mat-form-field appearance="fill">
                    <mat-label>{{ "ADD_ALARM.SELECT_SEVERITY" | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedSeverity" name="selectedSeverity"
                        (selectionChange)="onSeverityChange($event)">
                        <mat-option *ngFor="let severity of severityTypes" [value]="severity.id">
                            <span>{{severity.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>

        <tr class="content-row row-12">
            <div class="heading">{{"COMMENT_FORM.TITLE" | translate}} :</div>
        </tr>
        <tr class="content-row row-12">
            <textarea matInput rows="5" [(ngModel)]="comment"
                [placeholder]="'COMMENT_FORM.PLACEHOLDER' | translate"></textarea>
        </tr>

        <tr class="content-row justify-content-center row-12">
            <div class="d-flex buttons-section">
                <button class="mr-2 btn btn-info"
                    [disabled]="!isSelectedBranch || !isSelectedAlarmType || !isSelectedSeverity"
                    (click)="addAlarm()">{{
                    "ADD_ALARM.BUTTON_LABEL" | translate }}</button>
            </div>
        </tr>

        <tr class="content-row row-12">
            <mat-card class="success-card" *ngIf="success_send && !isSelectedAlarmType && !isSelectedSeverity">
                <span class="success_text">{{ success_message }}</span>
                <img class="close-btn" alt="close" (click)="closeCard()"
                    [src]="utility.getImagePath('close_video.png')" />
            </mat-card>

            <mat-card class="failure-card" *ngIf="failure_send">
                <span class="failure_text">{{ failure_message }}</span>
                <img class="close-btn" alt="close" (click)="closeCard()"
                    [src]="utility.getImagePath('close_video.png')" />
            </mat-card>
        </tr>

    </table>
</div>