<mat-table [dataSource]="allUsersArray">
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef class="users-column">{{ 'CHAT.USERS' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let user" class="users-column">
      <span class="chat-users" [matTooltip]="user.name" matTooltipPosition="below" matTooltipClass="custom-tooltip">
        {{ user.role }} : {{user.name}}
      </span>
    </mat-cell>
  </ng-container>

  <!-- Selection Column -->
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef class="check-column">{{ 'CHAT.RECIPIENT' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let user" class="check-column">
      <mat-checkbox [(ngModel)]="user.selected" [disabled]="!canDeselect(user)"
        (change)="onSelectChange(user)"></mat-checkbox>
    </mat-cell>
  </ng-container>

  <!-- Notification Column -->
  <ng-container matColumnDef="notify">
    <mat-header-cell *matHeaderCellDef class="check-column">{{ 'CHAT.NOTIFIED' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let user" class="check-column">
      <mat-checkbox [(ngModel)]="user.notify" [disabled]="!user.selected || !canUnnotify(user) || 
      user.rolePosition > (currentUser_rolePosition + 1)"></mat-checkbox>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<div class="d-flex justify-content-center mt-2 action-buttons">
  <button class="mr-2 btn btn-info" [disabled]="!anyUserSelected()" (click)="chat()">
    {{ 'CHAT.TITLE' | translate }}
  </button>
  <button class="cancel-button btn btn-info" (click)="cancel()">
    {{ 'BTN.CANCEL' | translate }}
  </button>
</div>