import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Utility } from '../../util/app-utils';
import { TranslateService } from '@ngx-translate/core';
import { AlarmService } from '../../services/alarm.service';
import { WorkflowService } from '../../services/workflow.service';
import { Subscription } from 'rxjs';
import { DateUtil } from '../../util/date-util';
import { AuthenticationService } from '../../services/authentication.service';
import { ReportService } from '../../services/report.service';
import { AppConstants } from '../../constants/app-constants';
import { Action } from '../../../models/action.model';
import { MediaComponent } from '../../media/media.component';


@Component({
  selector: 'app-alarm-info',
  templateUrl: './alarm-info.component.html',
  styleUrls: ['./alarm-info.component.scss']
})
export class AlarmInfoComponent implements OnInit {
  utility = Utility;
  alarm: any;
  alarmId: string;
  appConstants = AppConstants;
  response: any;
  NONE_STRING = 'none';
  actions: Action[] = [];
  displayedColumns = ['timeStamp', 'message', 'name'];
  actionHistorySubscription: Subscription;

  constructor(public translate: TranslateService, public dialog: MatDialog, private dialogRef: MatDialogRef<AlarmInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private alarmService: AlarmService, private workflowService: WorkflowService,
    private authService: AuthenticationService, public reportService: ReportService) { }

  async ngOnInit() {
    this.alarmId = this.data;
    this.response = await this.reportService.getSingleAlarmInfo(this.alarmId);
    this.alarm = this.response.data;
    await this.workflowService.fetchActionHistory(this.alarmId);
    this.actions = this.workflowService.actionHistory;

    // this.actionHistorySubscription = this.workflowService.getActionHistoryChangeListener().subscribe((actions: Action[]) => {
    // 	this.actions = actions;
    // });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AlarmInfoComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getGmtTime(timeMil: any) {
    return DateUtil.getFormattedDate(timeMil, AppConstants.DATE_TIME_FORMAT, this.authService.getUserTimezone());
  }

  getDate(Time: any) {
    return DateUtil.getFormattedDate(Time, AppConstants.DATE_TIME_FORMAT, this.authService.getUserTimezone());
  }

  getUserName(firstName: string, lastName: string) {
    if (firstName === this.NONE_STRING && lastName === this.NONE_STRING) {
      return '-';
    }
    if (firstName === this.NONE_STRING) {
      firstName = '';
    }
    if (lastName === this.NONE_STRING) {
      lastName = '';
    }
    return firstName + ' ' + lastName;
  }

  getAlarmInfo(info: string) {
    if (info === '') {
      return '-';
    } else {
      return info;
    }
  }

  async getAlarmReport(alarmId?: string) {
    await this.reportService.getSingleAlarmReportPdf(this.alarmId);
  }

  // Media display
  displayMedia(element: any) {
    this.dialog.open(MediaComponent, {
      height: '55%',
      width: '40%',
      disableClose: true,
      panelClass: 'custom-dialog',
      data: element
    });
  }

  ngOnDestroy() {
    // this.actionHistorySubscription = Utility.Unsubscribe(this.actionHistorySubscription);
  }

}
