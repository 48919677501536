import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { BranchService } from 'src/app/services/branch.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AlarmService } from 'src/app/services/alarm.service';
import { AppConstants } from 'src/app/constants/app-constants';
import { DateUtil } from 'src/app/util/date-util';
import { Utility } from 'src/app/util/app-utils';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntlService } from 'src/app/services/custom-paginator-intl.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from '../../services/report.service';
import { AlarmInfoComponent } from '../../reports/alarm-info/alarm-info.component';
import { MatDialog } from '@angular/material/dialog';
import { ChatService } from 'src/app/services/chat.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Alarm } from 'src/models/alarm.model';

@Component({
	selector: 'app-alarms-stats',
	templateUrl: './alarms-stats.component.html',
	styleUrls: ['./alarms-stats.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntlService }
	]
})

export class AlarmsStatsComponent implements OnInit {
	public alarmHistoryDataSource: any = [];
	public selectedAlarmHistoryId = null;
	public selectedAlarmHistory;
	selectedAlarmIDs = [];
	checkbox: any;
	appConstants = AppConstants;
	utility = Utility;
	displayedColumns = [
		'select',
		'alarmId',
		'Alarm Types',
		'Severity',
		'timeAndDate',
		'lastActionTime',
		'status',
		'Sites',
		'Operator',
		'ViewDetails',
		'chat',
		'Report'
	];
	selectAllAlarms = false;
	resetChecked = false;
	alarmsHistoryChangeSubscriber: any;
	@ViewChild(MatPaginator, { static: true }) paginationEle: MatPaginator;
	PAGINATION_START_INDEX = 0;
	chatAlarm: Alarm;

	constructor(
		public appConfigService: AppConfigService,
		public analyticalDashboard: AnalyticalDashboardService,
		public branchService: BranchService,
		public authService: AuthenticationService,
		public alarmsService: AlarmService,
		private changeDetector: ChangeDetectorRef,
		public translate: TranslateService,
		public reportService: ReportService,
		public dialog: MatDialog,
		public chatService: ChatService,
		public _snackBar: MatSnackBar
	) { }

	ngOnInit() {
		this.alarmsHistoryChangeSubscriber = this.analyticalDashboard.getAlarmsHistoryNotification().subscribe(() => {
			this.alarmHistoryDataSource = this.analyticalDashboard.alarmsHistory;
			this.analyticalDashboard.selectedAlarms = [];
			this.selectAllAlarms = false;
			this.resetChecked = false;
			if (this.alarmHistoryDataSource.length) {
				this.selectedAlarmHistoryId = this.alarmHistoryDataSource[0].shortAlarmId;
				this.selectedAlarmHistory = this.alarmHistoryDataSource[0];
				this.analyticalDashboard.selectAlarm(this.selectedAlarmHistory);
				this.paginationEle.pageIndex = this.analyticalDashboard.filterParams.start - AppConstants.PAGE_DIFF_CONSTANT;
				this.paginationEle.pageSize = this.analyticalDashboard.filterParams.pageSize;
			}
			this.refreshComponent();
		});
		this.getAlarmsHistoryStats();
		this.analyticalDashboard.selectAlarm(this.selectedAlarmHistory);
		this.refreshComponent();

	}

	ngOnDestroy() {
		this.alarmsHistoryChangeSubscriber = Utility.Unsubscribe(this.alarmsHistoryChangeSubscriber);
	}

	/**
	 * zoom to map and select a row
	 * @param row is selected from table
	 */
	highlight(row: any) {
		this.selectedAlarmHistoryId = row.shortAlarmId;
		this.selectedAlarmHistory = row;
		this.analyticalDashboard.selectAlarm(this.selectedAlarmHistory);
		if (this.chatAlarm && this.chatAlarm.shortAlarmId !== this.selectedAlarmHistoryId) {
			this.showMap();
		}
		this.refreshComponent();
	}

	/**
	 * Change the size of table related to selected filter
	 */
	changeTableSize() {
		this.analyticalDashboard.changeTableSizeOnAddedFilter(AppConstants.TABLE_CONTAINER_ID);
	}

	/**
	 * get alarms history table data
	 */
	async getAlarmsHistoryStats() {
		this.setDefaultPaginationOption();
		const response = await this.analyticalDashboard.getAlarmsHistoryStats(AppConstants.JSON_TEXT, false, false, false, false);
		this.alarmHistoryDataSource = response;
		if (this.alarmHistoryDataSource.length > 0) {
			this.selectedAlarmHistoryId = this.alarmHistoryDataSource[0].shortAlarmId;
			this.selectedAlarmHistory = this.alarmHistoryDataSource[0];
			this.analyticalDashboard.selectAlarm(this.selectedAlarmHistory);
		}
		this.refreshComponent();
	}

	setDefaultPaginationOption() {
		this.analyticalDashboard.filterParams.start = AppConstants.PAGE_START_CONSTANT;
		this.analyticalDashboard.filterParams.pageSize = AppConstants.DEFAULT_PAGE_SIZE;
	}

	/**
	 * select and unselect table row using checkbox
	 */
	onCheckboxChange() {
		let isUnSelected = false;
		this.analyticalDashboard.selectedAlarms.forEach(element => {
			if (element === this.selectedAlarmHistory) {
				const index = this.analyticalDashboard.selectedAlarms.indexOf(element);
				this.resetChecked = false;
				this.analyticalDashboard.selectedAlarms.splice(index, 1);
				isUnSelected = true;
				if (this.analyticalDashboard.selectedAlarms.length === 0) {
					this.selectAllAlarms = false;
				}
			}
		});
		if (!isUnSelected) {
			this.analyticalDashboard.selectedAlarms.push(this.selectedAlarmHistory);
			if (this.analyticalDashboard.selectedAlarms.length === this.alarmHistoryDataSource.length) {
				this.resetChecked = true;
			}
		}
		this.refreshComponent();
	}
	/**
	 * formats the time
	 */
	getFormatedTime(activationTime: any, format: any) {
		return DateUtil.getFormattedDate(activationTime, format, this.authService.getUserTimezone());
	}

	/**
	 * formats the operator name
	 */
	getOperatorName(alarmsHistory: any) {
		return alarmsHistory.operator ? `${alarmsHistory.operator.firstName} ${alarmsHistory.operator.lastName}` : '';
	}

	/**
	 * assign the a alarms to operator
	 */
	assignAlarmToOperator() {
		this.analyticalDashboard.actionOnAlarms(this.analyticalDashboard.selectedAlarms, this.analyticalDashboard.selectedOperatorsId, AppConstants.ACTION_ASSIGN);
		this.refreshComponent();
	}

	/**
	 * select all alarms
	 */
	checkAllAlarms() {
		if (!this.selectAllAlarms) {
			this.resetChecked = true;
			this.analyticalDashboard.selectedAlarms = [];
			this.alarmHistoryDataSource.forEach(alarmsData => {
				this.analyticalDashboard.selectedAlarms.push(alarmsData);
			});
		} else {
			this.analyticalDashboard.selectedAlarms = [];
			this.resetChecked = false;
		}
		this.selectAllAlarms = !this.selectAllAlarms;
		this.refreshComponent();
	}

	/**
	 * We are manually detecting changes to improve performance of page.
	 * Please call this function whenever you make changes.
	 */
	private refreshComponent() {
		this.changeDetector.markForCheck();
	}

	/**
	 * function called then map loading is done
	 */
	mapLoaded() {
		this.analyticalDashboard.selectAlarm(this.selectedAlarmHistory);
	}

	showMap() {
		this.chatAlarm = null;
	}

	async onPaginateChange(pageObj: any) {
		await this.paginateAlarmHistory(pageObj);
	}

	async paginateAlarmHistory(pageObj: any) {
		const filterOption = this.getAlarmHistoryFilters(pageObj);
		await this.analyticalDashboard.filterAlarmsHistoryList(filterOption, true);
		// this.analyticalDashboard.filterAlarmsHistoryList_withoutPagination(filterOption);
	}

	getAlarmHistoryFilters(pageObj: any) {
		const filterOption = this.analyticalDashboard.getAlarmHistoryFilterOptions();
		filterOption.start = pageObj.pageIndex + AppConstants.PAGE_DIFF_CONSTANT;
		filterOption.pageSize = pageObj.pageSize;
		return filterOption;
	}

	getAlarmReport(alarmId: string) {
		this.reportService.getSingleAlarmReportPdf(alarmId);
	}

	getAlarmInfo(alarmId: string) {
		this.dialog.open(AlarmInfoComponent, {
			height: '70%',
			width: '50%',
			disableClose: true,
			panelClass: 'custom-dialog',
			data: alarmId
		});
	}

	async getChatHistory(alarm: any) {
		let res = await this.chatService.getAlarmRoom(alarm.alarmId);
		if (res && res.success === true && res.data.length !== 0) {
			await this.getChatRoom(alarm);
		} else {
			this.chatAlarm = alarm;
		}
	}

	async getChatRoom(alarm: any) {
		let res = await this.chatService.getUserRooms(alarm.alarmId);
		if (res && res.success === true && res.data.rooms.length !== 0) {
			this.chatAlarm = alarm;
		} else {
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			const message = this.translate.instant('CHAT.NO_ACCESS') + ' : ' + alarm.shortAlarmId;
			let snackBarRef = this.openSnackBar(message, confirmText);
			snackBarRef.afterDismissed().subscribe(() => { });
		};
	}

	openSnackBar(message: string, action: string) {
		return this._snackBar.open(message, action, {
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: 'custom-snack-bar',
		});
	}

}
