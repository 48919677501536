import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BranchService } from 'src/app/services/branch.service';
import { AlarmService } from '../services/alarm.service';
import { Utility } from '../util/app-utils';
import { Subscription } from 'rxjs';
import { ConfidenceMonitoringService } from '../services/confidence-monitoring.service';

@Component({
  selector: 'app-add-alarm',
  templateUrl: './add-alarm.component.html',
  styleUrls: ['./add-alarm.component.scss']
})
export class AddAlarmComponent implements OnInit {
  utility = Utility;
  branches: [];
  selectedBranch: string;
  alarmTypes: [];
  selectedAlarmType: string;
  severityTypes: [];
  isSelectedBranch: boolean = false;
  isSelectedAlarmType: boolean = false;
  isSelectedSeverity: boolean = false;
  selectedSeverity: string;
  comment: any;
  success_message: any;
  failure_message: any;
  success_send: boolean = false;
  failure_send: boolean = false;
  disable_add: boolean = true;
  panelOpenState: boolean = true;

  public selectedBranchSubscriber: Subscription;

  constructor(public translate: TranslateService, public branchService: BranchService, public alarmService: AlarmService,
    public confidenceMonitoringService: ConfidenceMonitoringService) { }

  async ngOnInit() {
    this.selectedBranchSubscriber = this.confidenceMonitoringService.getSitesChangedNotification().subscribe(async (branch: any) => {
      if (branch) {
        this.isSelectedBranch = true;
        this.selectedAlarmType = null;
        this.selectedSeverity = null;
        this.selectedBranch = branch.id;
        this.getAlarmTypes();
        this.closeCard();
      }
    });
    // await this.getBranches();
    await this.getSeverities();
    this.reset();
  }

  // async getBranches() {
  //   this.branches = await this.branchService.getOperatorBranches();
  // }

  // onBranchChange(ev: any) {
  //   this.selectedBranch = ev.source.selected.value;
  //   this.isSelectedBranch = true;
  //   this.selectedAlarmType = null;
  //   this.getAlarmTypes();
  // }

  onAlarmTypeChange(ev: any) {
    this.selectedAlarmType = ev.source.selected.value;
    this.isSelectedAlarmType = true;
  }

  onSeverityChange(ev: any) {
    this.selectedSeverity = ev.source.selected.value;
    this.isSelectedSeverity = true;
  }

  async getAlarmTypes() {
    this.alarmTypes = await this.alarmService.getAlarmsTypes(this.selectedBranch);
  }

  async getSeverities() {
    this.severityTypes = await this.alarmService.getSeverityTypes();
  }

  async addAlarm() {
    let response = await this.alarmService.addAlarm(this.selectedBranch, this.selectedAlarmType, this.selectedSeverity, this.comment);
    if (response.success === true) {
      this.success_message = this.translate.instant('ADD_ALARM.SUCCESS_MESSAGE');
      this.failure_send = false;
      this.success_send = true;
      this.reset();
    } else {
      this.failure_message = response.errorMessages[0].message;
      this.success_send = false;
      this.failure_send = true;
    }
  }

  closeCard() {
    this.success_send = false;
    this.failure_send = false;
  }

  reset() {
    // this.selectedBranch = null;
    this.selectedAlarmType = null;
    this.selectedSeverity = null;
    // this.isSelectedBranch = false;
    this.isSelectedAlarmType = false;
    this.isSelectedSeverity = false;
    this.comment = "";
  }

  ngOnDestroy() {
    this.selectedBranchSubscriber.unsubscribe();
  }

  /**
 * Called when the expansion panel is expanded. It triggers an event indicating expansion panel is opened.
 */
  opened() {
    this.alarmService.changeAddAlarmExpandStatus(true);
    this.panelOpenState = true;
  }

  /**
   * Called when expansion panel is closed. It triggers an event indicating expansion panel is closed.
   */
  closed() {
    this.alarmService.changeAddAlarmExpandStatus(false);
    this.panelOpenState = false;
  }

}
