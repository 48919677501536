import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfidenceMonitoringService } from '../../services/confidence-monitoring.service';

const MAX_ZOOM_LEVEL = 2000;
const MIN_ZOOM_LEVEL = 500;
const ZOOM_INC_VAL = 100;
@Component({
	selector: 'app-img-viewer',
	templateUrl: './img-viewer.component.html',
	styleUrls: ['./img-viewer.component.scss']
})
export class ImgViewerComponent implements OnInit {
	public imgLoadFailed = false;
	assetChangedSubscriber: Subscription;
	@Input() url: string;
	constructor(public confidenceMonitoringService: ConfidenceMonitoringService) { }

	ngOnInit() { 
		this.selectedAssetListener();
	}

	ngOnChanges() {
		this.imgLoadFailed = false;
		// Restore image full width & height after zooming on new image selection #296 Staging
		const myImg = document.getElementById('show-image');
		if (myImg) {
			myImg.style.width = null;
		}
	}

	/**
	 * called when want to zoom in on image
	 */
	zoomIn() {
		const myImg = document.getElementById('show-image');
		const currentWidth = myImg.clientWidth;
		if (currentWidth === MAX_ZOOM_LEVEL) {
			return false;
		} else {
			myImg.style.width = (currentWidth + ZOOM_INC_VAL) + 'px';
		}
	}

	/**
	 * called when want to zoom out on image
	 */
	zoomOut() {
		const img = document.getElementById('show-image');
		const currentWidth = img.clientWidth;
		if (currentWidth === MIN_ZOOM_LEVEL) { return false; } else {
			img.style.width = (currentWidth - ZOOM_INC_VAL) + 'px';
		}
	}

	/**
	 * called when there is error in rendering image
	 */
	onError() {
		this.imgLoadFailed = true;
	}

	// called when Asset is selected
	selectedAssetListener() {
		this.assetChangedSubscriber = this.confidenceMonitoringService.getAssetSelectedNotification().subscribe(async (asset: any) => {
		// console.log(asset);
		});
	}

}
