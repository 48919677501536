import { Component, OnInit, Input } from '@angular/core';
import { AlarmService } from 'src/app/services/alarm.service';
import { AppConstants } from 'src/app/constants/app-constants';
import { DateUtil } from 'src/app/util/date-util';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Utility } from 'src/app/util/app-utils';

// PDF constants
const DEFAULT_ZOOM_LEVEL = 1;
const MIN_ZOOM_LEVEL = 0;
const ZOOM_INC_VAL = 0.4;

// Img constants
const IMG_MAX_ZOOM_LEVEL = 2000;
const IMG_MIN_ZOOM_LEVEL = 500;
const IMG_ZOOM_INC_VAL = 100;
@Component({
	selector: 'app-sites-asset-docs',
	templateUrl: './sites-asset-docs.component.html',
	styleUrls: ['./sites-asset-docs.component.scss']
})
export class SitesAssetDocsComponent implements OnInit {
	url: string = null;
	zoom = DEFAULT_ZOOM_LEVEL;
	assetsDocsData: any;
	public PDFLoadFailed = false;
	public imgLoadFailed = false;
	@Input() selectedBranch = null;
	selectedAssetId = null;
	appConstants = AppConstants;
	public isPdf = true;
	over = 'over';
	utility = Utility;

	constructor(private alarmService: AlarmService, private authService: AuthenticationService) { }

	ngOnInit() { }

	async ngOnChanges() {
		await this.getAssetData(this.selectedBranch.id);
	}

	/**
	 * called to zoom in on pdf
	 */
	pdfZoomIn() {
		this.zoom += ZOOM_INC_VAL;
	}

	/**
	 * called to zoom out on pdf
	 */
	pdfZoomOut() {
		if (this.zoom >= MIN_ZOOM_LEVEL) {
			this.zoom -= ZOOM_INC_VAL;
		}
	}

	/**
	 * called to zoom in on image
	 */
	imgZoomIn() {
		const myImg = document.getElementById('show-image');
		const currentWidth = myImg.clientWidth;
		if (currentWidth === IMG_MAX_ZOOM_LEVEL) {
			return false;
		} else {
			myImg.style.width = (currentWidth + IMG_ZOOM_INC_VAL) + 'px';
		}
	}

	/**
	 * called to zoom out on image
	 */
	imgZoomOut() {
		const img = document.getElementById('show-image');
		const currentWidth = img.clientWidth;
		if (currentWidth === IMG_MIN_ZOOM_LEVEL) { return false; } else {
			img.style.width = (currentWidth - IMG_ZOOM_INC_VAL) + 'px';
		}
	}

	/**
	 * called when there is error in pdf
	 */
	onPdfError() {
		this.PDFLoadFailed = true;
	}

	/**
	 * called when there is error in image
	 */
	onImgError() {
		this.imgLoadFailed = true;
	}

	/**
 * called when there is error in image
 */
	onAssetError() {
		this.url = null;
	}

	/**
	 * called to format date as required format
	 */
	getFormatedDate(activationTime: any, format: string) {
		return DateUtil.getFormattedDate(activationTime, format, this.authService.getUserTimezone());
	}

	/**
	 * called to get asset docs data
	 */
	async getAssetData(BranchId: string) {
		this.assetsDocsData = await this.alarmService.getAssetData(BranchId);
		if (this.assetsDocsData.length > 0) {
			this.url = this.assetsDocsData[0].url;
			this.selectedAssetId = this.assetsDocsData[0].id;
		}
		else if (this.assetsDocsData.length === 0 || this.assetsDocsData === null) {
			this.url = null;
		}
	}

	/**
	 * called when a asset is select from asset list table
	 */
	onAssetSelect(asset: any) {
		this.url = asset.url;
		this.selectedAssetId = asset.id;
		this.isPdf = asset.type === AppConstants.PDF;
		// Restore image full width & height after zooming on new image selection #296 Staging
		const myImg = document.getElementById('show-image');
		if (myImg) {
			myImg.style.width = null;
		}
	}

}
