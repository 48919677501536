<div class="main-body main-body-container">
	<div class="alerts">
		<app-alarms></app-alarms>
	</div>
	<div class="assets">
		<div class="box-header">
			<div class="icon">
				<img [src]="utility.getImagePath('list.png')" alt="list">
			</div>
			<div class="ml-2">
				<p class="assets-header">{{"DASHBOARD.HEADER_TEXT" | translate}}</p>
			</div>
		</div>

		<div class="assets-section">
			<app-video-feeds class="video-feed-box" [shouldCloseFullScreen]="assetDocSelectedChanged" *ngIf="!isThermal">
			</app-video-feeds>
			<app-thermal-feeds class="video-feed-box" [alarmId]="selectedAlarmId" *ngIf="isThermal">
			</app-thermal-feeds>
			<div class="asset-list">
				<app-asset-docs (selectedAssetDoc)="onAssetSelectShow($event)"></app-asset-docs>
			</div>
		</div>

		<div class="asset-view-section">
			<app-asset-viewer [assetDoc]="assetDoc"></app-asset-viewer>
		</div>

	</div>

	<div class="right-content" [hidden]="confidenceMonitoringMode">
		<div class="alarm-details">
			<app-alarm-details></app-alarm-details>
		</div>
		<div class="workflow" [class.both-expanded]='isActionHistoryExpanded&&isAlarmHistoryExpanded'
			[class.both-collapsed]='!isActionHistoryExpanded&&!isAlarmHistoryExpanded'
			[class.one-collapsed]='(isActionHistoryExpanded&&!isAlarmHistoryExpanded)||(!isActionHistoryExpanded&&isAlarmHistoryExpanded)'>
			<app-workflow></app-workflow>
		</div>
		<div class="action-history-container">
			<div class="action-history">
				<app-action-history></app-action-history>
			</div>
			<div class="alarm-history">
				<app-alarm-history></app-alarm-history>
			</div>
		</div>
	</div>
	<div *ngIf="isOperator && confidenceMonitoringMode" class="right-content">
		<app-confidence-monitor></app-confidence-monitor>
	</div>