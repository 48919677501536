import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Alarm } from 'src/models/alarm.model';
import { AlarmService } from '../services/alarm.service';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstants } from '../constants/app-constants';
import { DateUtil } from '../util/date-util';
import { TranslateService } from '@ngx-translate/core';
import { AlarmType } from '../enums/alarm-type.enum';
import { Utility } from '../util/app-utils';

@Component({
	selector: 'app-alarm-details',
	templateUrl: './alarm-details.component.html',
	styleUrls: ['./alarm-details.component.scss']
})
export class AlarmDetailsComponent implements OnInit, OnDestroy {
	alarm: Alarm;
	alarmSelectedSubscription: Subscription;
	appConstants = AppConstants;
	alarmType = AlarmType;
	utility = Utility;
	alarmInfo: string;
	formattedDate: string;
	activeSince: string;

	constructor(private alarmService: AlarmService, private authService: AuthenticationService, private translate: TranslateService, private cdr: ChangeDetectorRef) { }

	ngOnInit() {
		this.alarmSelectedSubscription = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
			this.alarm = alarm;
			if (this.alarm) {
				this.activeSince = this.getActiveSince(this.alarm.activationTime);
				this.formattedDate = this.getFormatedDate(this.alarm.activationTime, this.appConstants.DATE_FORMAT);

				if (this.alarm.info) {
					this.alarmInfo = this.alarm.info;
				} else {
					this.alarmInfo = this.translate.instant('ALARM_DETAILS.NO_INFO');
				}
			}

			// manually trigger change detection
			this.cdr.detectChanges();
		});
	}

	ngOnDestroy() {
		this.alarmSelectedSubscription = Utility.Unsubscribe(this.alarmSelectedSubscription);
	}

	/**
	 * Returns date in format dd/MM/yyyy;
	 * @param activationTime activation time
	 */
	getFormatedDate(activationTime: any, format: string) {
		return DateUtil.getFormattedDate(activationTime, format, this.authService.getUserTimezone());
	}

	/**
	 * Returns since when the alarm is active.
	 * @param activationTime Time in milliseconds
	 */
	getActiveSince(activationTime: any) {
		// TODO CHANGE API to return date in number format
		activationTime = parseInt(activationTime, 10);
		if (!activationTime) {
			return;
		}
		return DateUtil.getActiveSince(activationTime, this.translate);
	}

	getTextToShow_address(type: string) {
		type = type.trim();
		return type.length > 30 ? type.substring(0, 29) + '...' : type;
	}

	getTextToShow_info(type: string) {
		type = type.trim();
		return type.length > 101 ? type.substring(0, 100) + '...' : type;
	}

}
