import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import { Action } from 'src/models/action.model';
import { AlarmService } from '../services/alarm.service';
import { WorkflowService } from '../services/workflow.service';
import { Subscription } from 'rxjs';
import { DateUtil } from '../util/date-util';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstants } from '../constants/app-constants';
import { Utility } from '../util/app-utils';
import { MediaComponent } from '../media/media.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-action-history',
	templateUrl: './action-history.component.html',
	styleUrls: ['./action-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ActionHistoryComponent implements OnInit, OnDestroy {
	actionPanelOpenState: any = true;
	closeAll: any = false;
	actions: Action[] = [];
	actionsDataSource: any;
	displayedColumns = ['timeStamp', 'message', 'name'];
	actionHistorySubscription: Subscription;
	alarmChangeSubscription: Subscription;
	utility = Utility;
	alarmsExist: boolean = true;

	constructor(
		private alarmService: AlarmService,
		private workflowService: WorkflowService,
		private authService: AuthenticationService,
		private changeDetector: ChangeDetectorRef,
		public dialog: MatDialog
	) { }

	async ngOnInit() {
		this.actionsDataSource = new MatTableDataSource<Action>(this.actions);
		this.actionsDataSource.data = [];
		let res = await this.alarmService.getAlarms();
		if (res && res.data && res.data.length > 0) {
			this.alarmsExist = true;
		} else {
			this.alarmsExist = false;
		};

		this.alarmChangeSubscription = this.alarmService.getOnAlarmSelectedListener().subscribe(async alarm => {
			this.actionsDataSource.data = this.actions;
			if (alarm) {
				await this.workflowService.fetchActionHistory(alarm.id);
			}
			this.workflowService.actionHistory = alarm ? this.workflowService.actionHistory : [];
			this.workflowService.actionHistoryChangeListener.next(this.workflowService.actionHistory);
			this.refreshComponent();
		});

		this.actionHistorySubscription = this.workflowService.getActionHistoryChangeListener().subscribe((actions: Action[]) => {
			this.actions = actions;
			this.actionsDataSource.data = this.actions;
			this.refreshComponent();
		});
	}

	ngOnDestroy() {
		this.actionHistorySubscription = Utility.Unsubscribe(this.actionHistorySubscription);
		this.alarmChangeSubscription = Utility.Unsubscribe(this.alarmChangeSubscription);
	}

	/**
	 * Called when the expansion panel is expanded. It triggers an event indicating expansion panel is opened.
	 */
	opened() {
		this.alarmService.changeActionHistoryExpandStatus(true);
		this.actionPanelOpenState = true;
	}

	/**
	 * This method is called when expansion panel is close. It triggers an event indicating expansion panel is closed.
	 */
	closed() {
		this.alarmService.changeActionHistoryExpandStatus(false);
		this.actionPanelOpenState = false;
	}

	/**
	 * Returns date in specific format of particular timezone.
	 * @param Time Activation time in milliseconds.
	 */
	getDate(Time: any) {
		return DateUtil.getFormattedDate(Time, AppConstants.DATE_TIME_FORMAT, this.authService.getUserTimezone());
	}

	/**
	 * We are manually detecting changes to improve performance of page.
	 * Please call this function whenever you make changes.
	 */
	private refreshComponent() {
		this.changeDetector.markForCheck();
	}

	// Media display
	displayMedia(element: any) {
		this.dialog.open(MediaComponent, {
			height: '55%',
			width: '40%',
			disableClose: true,
			panelClass: 'custom-dialog',
			data: element
		});
	}

}
