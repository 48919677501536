<div class="alarm-details-box">
	<div class="box-header d-flex align-items-center">
		<div class="icon d-flex align-items-center">
			<img class="alert-icon" alt="alert" [src]="utility.getImagePath('alert.png')">
		</div>
		<div class="ml-2">
			{{ "ALARM_DETAILS.ALARM_DETAILS" | translate }}
		</div>
	</div>
	<div class="box-content">
		<table *ngIf="alarm; else noAlarm" aria-describedby="alarm-details">
			<th scope="row" aria-hidden="true"></th>
			<tr class="content-row row-12">
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<img class="details-icon" alt="escalate" [src]="utility.getImagePath('escalate.png')">
					</div>
					<div>
						{{alarm?.status}}
					</div>
				</td>
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<img class="details-icon" alt="calendar" [src]="utility.getImagePath('calendar.png')">
					</div>
					<div>
						{{formattedDate}}
					</div>
				</td>
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<img class="details-icon" alt="time" [src]="utility.getImagePath('time.png')">
					</div>
					<div>
						{{ "ALARM_DETAILS.ACTIVE_SINCE" | translate }} {{activeSince}}
					</div>
				</td>
			</tr>
			<tr class="content-row row-12">
				<td class="alarm-details-cell col-4">
					<div class="icon alarm-icon" [class.big-icon]="alarm.type === alarmType.TEMPERATURE">
						<img class="details-icon" alt="alarm-icon" [src]="alarm.icon">
					</div>
					<div>
						{{alarm?.shortAlarmId || alarm?.id}}
					</div>
				</td>
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<img class="details-icon" alt="clock" [src]="utility.getImagePath('clock.png')">
					</div>
					<div>
						{{getFormatedDate(alarm?.activationTime,appConstants.TIME_FORMAT)}}
					</div>
				</td>
				<td class="alarm-details-cell col-4">
					<div class="icon">
						<img class="details-icon" alt="map" [src]="utility.getImagePath('map.png')">
					</div>
					<div [matTooltip]='alarm?.address' class="address">
						{{alarm?.address}}
					</div>
				</td>
			</tr>
			<tr class="content-row row-12">
				<td class="alarm-details-cell col-12">
					<div class="icon">
						<img class="details-icon" alt="info" [src]="utility.getImagePath('Escalated.png')">
					</div>
					<div class="alarmInfo" [matTooltip]='alarmInfo' [matTooltipClass]="'info-tooltip'">
						{{alarmInfo}}
					</div>
				</td>
			</tr>
		</table>
		<ng-template #noAlarm>
			<div class="box-content-no-data">
				{{ "ALARM_DETAILS.NO_DATA" | translate }}
			</div>
		</ng-template>
	</div>
</div>