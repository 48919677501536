import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ToastrModule } from 'ngx-toastr';
import { ActionHistoryComponent } from './action-history/action-history.component';
import { AlarmDetailsComponent } from './alarm-details/alarm-details.component';
import { AlarmHistoryComponent } from './alarm-history/alarm-history.component';
import { AlarmsComponent } from './alarms/alarms.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssetDocsComponent } from './asset-docs/asset-docs.component';
import { AssetViewerComponent } from './asset-viewer/asset-viewer.component';
import { ImgViewerComponent } from './asset-viewer/img-viewer/img-viewer.component';
import { MapComponent } from './asset-viewer/map/map.component';
import { PdfViewerComponent } from './asset-viewer/pdf-viewer/pdf-viewer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EscalationConfirmationComponent } from './escalation-confirmation/escalation-confirmation.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { FilterDataComponent } from './reports/filter-data/filter-data.component';
import { ReportDetailsComponent } from './reports/report-details/report-details.component';
import { ReportsComponent } from './reports/reports.component';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { VideoFeedsComponent } from './video-feeds/video-feeds.component';
import { ThermalFeedsComponent } from './thermal-feeds/thermal-feeds.component';
import { AddCommentComponent } from './workflow/add-comment/add-comment.component';
import { QuestionComponent } from './workflow/question/question.component';
import { WorkflowComponent } from './workflow/workflow.component';
import { AppConfigService } from './app-config.service';
import { AlarmSearchFilterPipe } from './alarms/alarm-search-filter.pipe';
import { ClickOutsideDirective } from './header/click-outside.directive';
import { RmMultiSelectComponent } from './reports/rm-multi-select/rm-multi-select.component';
import { VideoPlayerDirective } from './video-player.directive';
import { AnalyticalDashboardComponent } from './analytical-dashboard/analytical-dashboard.component';
import { AlarmsStatsComponent } from './analytical-dashboard/alarms-stats/alarms-stats.component';
import { OperatorStatsComponent } from './analytical-dashboard/operator-stats/operator-stats.component';
import { SitesStatsComponent } from './analytical-dashboard/sites-stats/sites-stats.component';
import { AnalyticalChartsComponent } from './analytical-dashboard/analytical-charts/analytical-charts.component';
import { AlarmsStatsSummaryComponent } from './analytical-dashboard/alarms-stats-summary/alarms-stats-summary.component';
import { OperatorsStatsSummaryComponent } from './analytical-dashboard/operators-stats-summary/operators-stats-summary.component';
import { SiteStatsSummaryComponent } from './analytical-dashboard/site-stats-summary/site-stats-summary.component';
import { StatTileComponent } from './analytical-dashboard/stat-tile/stat-tile.component';
import { SitesAssetDocsComponent } from './analytical-dashboard/sites-stats/sites-asset-docs/sites-asset-docs.component';
import { SitesVideoFeedsComponent } from './analytical-dashboard/sites-stats/sites-video-feeds/sites-video-feeds.component';
import { AlarmsFilterComponent } from './analytical-dashboard/filter/filter.component';
import { ConfirmDialogComponent } from './analytical-dashboard/confirm-dialog/confirm-dialog.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CanActivateRouteGuard } from './can-activate-route-guard';
import { AuthenticationService } from './services/authentication.service';
import { SitesTableComponent } from './analytical-dashboard/sites-stats/sites-table/sites-table.component';
import { MonitoringSitesComponent } from './confidence-monitor/monitoring-sites/monitoring-sites.component';
import { ConfidenceMonitorComponent } from './confidence-monitor/confidence-monitor.component';
import { ZoneComponent } from './asset-viewer/zone/zone.component';
import { VncDialogComponent } from './vnc-dialog/vnc-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AlarmInfoComponent } from './reports/alarm-info/alarm-info.component';
import { MediaComponent } from './media/media.component';
import { AddAlarmComponent } from './add-alarm/add-alarm.component';
import { SupervisorAddAlarmComponent } from './analytical-dashboard/supervisor-add-alarm/supervisor-add-alarm.component';
import { SendMailComponent } from './send-mail/send-mail.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { NbThemeModule, NbLayoutModule, NbChatModule, NbAccordionModule, NbChatOptions, NbCardModule, NbWindowModule, NbWindowService, NbButtonModule, NbTooltipModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ChatComponent } from './chat/chat.component';
import { WindowFormComponent } from './chat/window-form/window-form.component';
import { MatMenuModule } from '@angular/material/menu';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeAr from '@angular/common/locales/ar';
import localeRu from '@angular/common/locales/ru';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { dateTimeLocaleFactory } from './locale.factory';
import { TranslateService } from '@ngx-translate/core';
import { SafePipe } from './safe.pipe';
import { ChatUsersComponent } from './chat-users/chat-users.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgCircleProgressModule } from 'ng-circle-progress';

registerLocaleData(localeEn);
registerLocaleData(localeAr);
registerLocaleData(localeRu);

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
        AppComponent, AlarmsComponent, AlarmDetailsComponent, WorkflowComponent, ActionHistoryComponent,
        AlarmHistoryComponent, HeaderComponent, LoginComponent, DashboardComponent, MapComponent,
        ReportsComponent, ReportDetailsComponent, AssetDocsComponent, AssetViewerComponent, PdfViewerComponent, ImgViewerComponent, VideoFeedsComponent, ThermalFeedsComponent,
        QuestionComponent, AddCommentComponent, EscalationConfirmationComponent,
        FilterDataComponent, HomeComponent, AlarmSearchFilterPipe, ClickOutsideDirective,
        RmMultiSelectComponent, VideoPlayerDirective, AnalyticalDashboardComponent,
        AlarmsStatsComponent, OperatorStatsComponent, SitesStatsComponent, AnalyticalChartsComponent,
        AlarmsFilterComponent, AlarmsStatsSummaryComponent, OperatorsStatsSummaryComponent,
        SiteStatsSummaryComponent, StatTileComponent, SitesAssetDocsComponent, SitesVideoFeedsComponent,
        ConfirmDialogComponent, SitesTableComponent, MonitoringSitesComponent, ConfidenceMonitorComponent, ZoneComponent, VncDialogComponent, AlarmInfoComponent, MediaComponent, AddAlarmComponent, SupervisorAddAlarmComponent, SendMailComponent, ChatComponent, WindowFormComponent, SafePipe, ChatUsersComponent
    ],
    entryComponents: [ConfirmDialogComponent, VncDialogComponent, AlarmInfoComponent, MediaComponent],
    imports: [
        MatPaginatorModule, OwlDateTimeModule, OwlNativeDateTimeModule, MatSidenavModule,
        BrowserModule, BrowserAnimationsModule, MatButtonModule, MatSlideToggleModule,
        MatDatepickerModule, MatTableModule, MatExpansionModule, MatNativeDateModule,
        MatToolbarModule, MatTooltipModule, MatFormFieldModule, MatAutocompleteModule,
        MatInputModule, MatCardModule, MatGridListModule, FormsModule, ReactiveFormsModule,
        AppRoutingModule, HttpClientModule, PdfViewerModule, MatListModule, MatCheckboxModule,
        LeafletModule.forRoot(), MatSortModule, MatDialogModule, DragDropModule, FontAwesomeModule, MatSelectModule, MatOptionModule, MatSnackBarModule, MatBadgeModule, MatIconModule, MatMenuModule,
        MatProgressSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
        }),
        NbThemeModule.forRoot({ name: 'default' }),
        NbLayoutModule, NbEvaIconsModule, NbChatModule, NbAccordionModule, NbCardModule, NbButtonModule, NbTooltipModule, NbWindowModule.forRoot(),
        NgCircleProgressModule.forRoot({
            // defaults valuse for progress circle
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: "#78C000",
            innerStrokeColor: "#C7E596",
            animationDuration: 300,

        })
    ],
    providers: [
        NbChatOptions, CanActivateRouteGuard, AuthenticationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [AppConfigService],
            useFactory: (appConfigService: AppConfigService) => {
                return async () => {
                    await appConfigService.loadAppConfig();
                };
            }
        },
        { provide: OWL_DATE_TIME_LOCALE, useFactory: dateTimeLocaleFactory, deps: [TranslateService] },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
