import { Component, OnInit, Input } from '@angular/core';

const DEFAULT_ZOOM_LEVEL = 1;
const MIN_ZOOM_LEVEL = 0;
const ZOOM_INC_VAL = 0.4;

@Component({
	selector: 'app-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
	@Input() url: string;
	zoom = DEFAULT_ZOOM_LEVEL;
	public PDFLoadFailed = false;
	constructor() { }

	ngOnInit() { }

	ngOnChanges() {
		this.PDFLoadFailed = false;
	}

	/**
	 * called when want to zoom in on pdf
	 */
	zoomIn() {
		this.zoom += ZOOM_INC_VAL;
	}

	/**
	 * called when want to zoom out on pdf
	 */
	zoomOut() {
		if (this.zoom >= MIN_ZOOM_LEVEL) {
			this.zoom -= ZOOM_INC_VAL;
		}
	}

	/**
	 * called when there is an error in rendering pdf
	 */
	onError() {
		this.PDFLoadFailed = true;
	}
}
