import { Component, OnInit } from '@angular/core';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { Utility } from 'src/app/util/app-utils';
import { AppConstants } from 'src/app/constants/app-constants';

@Component({
	selector: 'app-alarms-stats-summary',
	templateUrl: './alarms-stats-summary.component.html',
	styleUrls: ['./alarms-stats-summary.component.scss']
})

export class AlarmsStatsSummaryComponent implements OnInit {
	public status = [];
	public statistics = [];
	alarmsSummary: any;
	selectedTile: any = null;
	alarmsChangeSubscriber: any;

	constructor(private analyticalDashboard: AnalyticalDashboardService) { }

	ngOnInit() {
		this.alarmsChangeSubscriber = this.analyticalDashboard.getAlarmsNotification().subscribe(() => {
			this.selectedTile = null;
			this.alarmsSummary = this.analyticalDashboard.getAlarmsStatusDetails();
			this.statistics = Utility.formatIntoRows(this.alarmsSummary);
		});
		this.analyticalDashboard.getAllClearNotification().subscribe(() => {
			this.selectedTile = null;
		});
	}

	ngOnDestroy() {
		this.alarmsChangeSubscriber = Utility.Unsubscribe(this.alarmsChangeSubscriber);
	}

	/**
	 * check tile selected or not
	 */
	isNotActive(item: any) {
		return !(this.selectedTile === item);
	}

	/**
	 * select or unselect a tile and filter table related to that
	 */
	async setSelectedTile(alarmsName: any) {
		this.analyticalDashboard.SelectedStatusesForTile = [];
		if (this.selectedTile !== null && this.selectedTile !== alarmsName) {
			this.analyticalDashboard.SelectedStatusesForTile.push(alarmsName);
			this.filterOptionsParams(this.selectedTile);
		} else if (this.selectedTile === alarmsName) {
			this.filterOptionsParams(alarmsName);
		} else {
			this.analyticalDashboard.SelectedStatusesForTile.push(alarmsName);
		}
		this.setPaginationOption();
		this.selectedTile = this.selectedTile === alarmsName ? null : alarmsName;
		await this.analyticalDashboard.filterAlarmsHistoryList(this.analyticalDashboard.filterParams);
		await this.analyticalDashboard.filterAlarmsHistoryList_withoutPagination(this.analyticalDashboard.filterParams);
	}

	filterOptionsParams(filterBy: string) {
		this.analyticalDashboard.filterParams.alarmStatuses = (this.analyticalDashboard.filterParams.alarmStatuses || []).filter(alarmStatus => alarmStatus !== filterBy);
		this.analyticalDashboard.filterParams.alarmStatuses = this.analyticalDashboard.filterParams.alarmStatuses.length ? this.analyticalDashboard.filterParams.alarmStatuses : null;
	}

	setPaginationOption() {
		this.analyticalDashboard.filterParams.start = AppConstants.PAGE_START_CONSTANT;
		this.analyticalDashboard.filterParams.pageSize = AppConstants.DEFAULT_PAGE_SIZE;
	}
}
