import { Pipe, PipeTransform } from '@angular/core';
import { Alarm } from 'src/models/alarm.model';
import { DateUtil } from '../util/date-util';
import { AppConstants } from '../constants/app-constants';

@Pipe({
	name: 'alarmSearchFilter'
})
export class AlarmSearchFilterPipe implements PipeTransform {
	transform(alarms: Alarm[], searchText: string, userTimeZone: string, isChangeInAlarmList): any {
		searchText = searchText.toUpperCase();
		if (alarms.length === 0) {
			return [];
		}
		if (searchText === '') {
			return alarms;
		}
		let filteredList: Alarm[] = [];
		filteredList = alarms.filter(alarm => {
			return alarm.branch.name.toUpperCase().indexOf(searchText) !== -1 || alarm.status.toUpperCase().indexOf(searchText) !== -1 || alarm.shortAlarmId.toUpperCase().indexOf(searchText) !== -1
				|| alarm.severity.toUpperCase().indexOf(searchText) !== -1 || alarm.type.toUpperCase().indexOf(searchText) !== -1 ||
				DateUtil.getFormattedDate(alarm.activationTime, AppConstants.DATE_TIME_FORMAT, userTimeZone).toUpperCase().
					indexOf(searchText) !== -1;
		});
		return filteredList;
	}
}
