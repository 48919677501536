<div class="asset-list">
	<h1>{{"ASSETDOCS.HEADER_TEXT" | translate}}</h1>
	<ul>
		<li *ngFor="let asset of assetsDocs"
			class="asset-li"
			(click)="showAsset(asset)">
			<div [class.selected]="asset.id === selectedAssetDocId">
				<a>
					<img class="file-icon" alt="file"
						[src]="utility.getImagePath('file.png')">
					<p class="asset-name">{{ asset.name }}</p>
					<p>
						<span>{{"ASSETDOCS.LASTMODIFIED" | translate}}</span>
						{{getFormatedDate(asset?.lastModifiedDate,appConstants.SHORT_DATE_FORMAT)}}
					</p>
					<hr class="hr">
				</a>
			</div>
		</li>
	</ul>
</div>