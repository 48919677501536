<div class="operators full-height">
	<div class="full-height filters-and-table-wrapper">
		<div class="filter-content">
			<!-- <app-alarms-filter [selectedAlarmHistory]="selectedOperatorsHistory"
				[filterType]="filterType"
				(changeHeight)="changeTableSize()"></app-alarms-filter> -->
		</div>
		<div class="alarms-row">
			<div class="alarms-content">
				<div class="alarm-history">
					<div class="table-container" id="operator-table-container">
						<table mat-table aria-describedby="operators-history" matSort
							*ngIf="operatorsHistoryDataSource && operatorsHistoryDataSource.length > 0; else noActions"
							[dataSource]="operatorsHistoryDataSource">

							<ng-container matColumnDef="Region">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> {{ "ANALYTICAL_ALARM_HISTORY.REGION"
									| translate}}
								</th>
								<td mat-cell class="region-column" *matCellDef="let operatorHistory">
									{{formatRegionsNames(operatorHistory.regions)}}</td>
							</ng-container>

							<ng-container matColumnDef="RelatedSites">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
									{{ "ANALYTICAL_ALARM_HISTORY.RELATED_SITES" | translate}}
								</th>
								<td mat-cell class="relatedSites-column" *matCellDef="let operatorHistory">
									{{formatSitesNames(operatorHistory.relatedSites)}}</td>
							</ng-container>

							<ng-container matColumnDef="Sites">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
									{{ "ANALYTICAL_ALARM_HISTORY.SITES" |	translate}}
								</th>
								<td mat-cell class="sites-column" *matCellDef="let operatorHistory"
									matTooltip="{{formatSitesNames(operatorHistory.relatedSites)}}">
									{{formatSitesCounter(operatorHistory.relatedSites)}} </td>
							</ng-container>

							<ng-container matColumnDef="Operators">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> {{
									"ANALYTICAL_ALARM_HISTORY.OPERATOR" | translate}}
								</th>
								<td mat-cell class="operators-column" *matCellDef="let operatorHistory">
									{{operatorHistory.operatorName}} </td>
							</ng-container>

							<ng-container matColumnDef="Status">
								<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef> 
									{{ "ANALYTICAL_ALARM_HISTORY.STATUS" | translate}}
								</th>
								<td mat-cell class="status-column" *matCellDef="let operatorHistory">
									{{operatorHistory.operatorStatus}} </td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"
								[ngClass]="{'highlight': selectedOperatorsHistoryId == row.id}" (click)="highlight(row)"></tr>
						</table>
						<ng-template #noActions>
							<div class="box-content-no-data">
								{{'ALARM_HISTORY.NO_DATA' | translate}}
							</div>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="operators-map">
		<app-map *ngIf="analyticalDashboard.chartSelected === analyticalDashboard.chartType.OPERATOR"
			(doneLoading)="mapLoaded()"></app-map>
	</div>
</div>