import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Alarm } from 'src/models/alarm.model';
import { AlarmService } from '../services/alarm.service';
import { DateUtil } from '../util/date-util';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstants } from '../constants/app-constants';
import { Utility } from '../util/app-utils';
// import { MatPaginator } from '@angular/material';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntlService } from '../services/custom-paginator-intl.service';

@Component({
	selector: 'app-alarm-history',
	templateUrl: './alarm-history.component.html',
	styleUrls: ['./alarm-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntlService }
	]
})
export class AlarmHistoryComponent implements OnInit {
	isChange: any = false;
	closeAll: any = false;
	alarmPanelOpenState: any = true;
	actionPanelOpenState: any = true;
	alarms: Alarm[];
	displayedColumns = ['alertId', 'type', 'severity', 'activationTime', 'status'];
	alarm: Alarm;
	AlarmSelectedSubscriber: any;
	AlarmHistoryChangeSubscriber: any;
	utility = Utility;
	appConstants = AppConstants;
	@ViewChild(MatPaginator, { static: true }) paginationEle: MatPaginator;
	alarmHistoryOptions: any = {};

	constructor(
		public alarmService: AlarmService,
		private authService: AuthenticationService,
		private changeDetector: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.AlarmSelectedSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe(async (alarm: Alarm) => {
			this.alarm = alarm;
			this.alarms = [];
			if (alarm) {
				this.setDefaultPaginationOption();
				await this.alarmService.fetchAlarmHistory(this.alarmHistoryOptions);
			}
			this.alarmService.alarmHistory = alarm ? this.alarmService.alarmHistory : [];
			this.alarmService.alarmHistoryChangeListener.next(this.alarmService.alarmHistory);
			this.refreshComponent();
		});
		this.AlarmHistoryChangeSubscriber = this.alarmService.getAlarmHistoryChangeListener().subscribe((alarms) => {
			this.alarms = alarms;
			this.refreshComponent();
		});
	}

	ngOnDestroy() {
		this.AlarmSelectedSubscriber = Utility.Unsubscribe(this.AlarmSelectedSubscriber);
		this.AlarmHistoryChangeSubscriber = Utility.Unsubscribe(this.AlarmHistoryChangeSubscriber);
	}

	/**
	 * Returns date as a string for the particular timezone.
	 *
	 * @param milliSeconds Timestamp in milliseconds.
	 */
	getStringDate(milliSeconds: number) {
		return DateUtil.getFormattedDate(milliSeconds, AppConstants.DATE_TIME_FORMAT, this.authService.getUserTimezone());
	}

	/**
	 * Called when expansion panel is opened or expanded.
	 */
	opened() {
		this.alarmService.changeAlarmHistoryExpandStatus(true);
		this.alarmPanelOpenState = true;
		this.isChange = this.actionPanelOpenState === false && this.alarmPanelOpenState === true;
	}
	/**
	 * Called when expansion panel is closed.
	 */
	closed() {
		this.alarmService.changeAlarmHistoryExpandStatus(false);
		this.alarmPanelOpenState = false;
	}

	/**
	 * We are manually detecting changes to improve performance of page.
	 * Please call this function whenever you make changes.
	 */
	private refreshComponent() {
		this.changeDetector.markForCheck();
	}

	onPaginateChange(pageObj) {
		this.setSelectedPageOption(pageObj);
		this.getAlarmHistoryByPagination();
	}

	getAlarmHistoryByPagination() {
		this.alarmService.fetchAlarmHistory(this.alarmHistoryOptions);
	}

	setSelectedPageOption(pageObj) {
		this.alarmHistoryOptions.start = pageObj.pageIndex + AppConstants.PAGE_DIFF_CONSTANT;
		this.alarmHistoryOptions.pageSize = pageObj.pageSize;
	}

	setDefaultPaginationOption() {
		this.paginationEle.pageIndex = AppConstants.PAGE_START_CONSTANT - AppConstants.PAGE_DIFF_CONSTANT;
		this.paginationEle.pageSize = AppConstants.DEFAULT_PAGE_SIZE;
		this.alarmHistoryOptions.start = AppConstants.PAGE_START_CONSTANT;
		this.alarmHistoryOptions.pageSize = AppConstants.DEFAULT_PAGE_SIZE;
	}
}
