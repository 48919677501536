<div class="control-bar" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <img class="drag-btn" alt="drag" cdkDragHandle [src]="utility.getImagePath('drag.png')" />
    <img class="close-btn" alt="close" [src]="utility.getImagePath('close_video.png')" 
    (click)="closeDialog()" />
</div>

<div class="dialog-content" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="iframe-container">
        <iframe width="100%" height="100%" frameBorder="0" [src]="iframeUrl | safe" allowfullscreen></iframe>
    </div>
</div>