import { Component, OnInit, OnDestroy } from '@angular/core';
import * as charts from 'highcharts';
import { AnalyticalDashboardService } from '../../services/analytical-dashboard.service';
import { ChartService } from '../../services/chart.service';
import { AppConstants } from '../../constants/app-constants';
import { Utility } from 'src/app/util/app-utils';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ChartResponsiveOptionUtil } from 'src/app/util/chart-responsive-option-util';

interface ChartMenu {
	label: string;
	chartType: any;
	image : string;
}
@Component({
	selector: 'app-analytical-charts',
	templateUrl: './analytical-charts.component.html',
	styleUrls: ['./analytical-charts.component.scss']
})
export class AnalyticalChartsComponent implements OnInit, OnDestroy {
	// HTML id of chart containers
	CHART_ID = 'pie-chart';
	CHART_ID_2 = 'second-analytical-chart';

	// Chart instances
	alarmsChart: charts.Chart;
	operatorsChart: charts.Chart;
	sitesChart: charts.Chart;
	sitePriorityChart: charts.Chart;
	isChartChanged = false;
	relativePath = '../../../assets/';
	utility = Utility;

	fullImagePath: string;

	chartMenus: ChartMenu[] = [
		{
			label: 'ANALYTICAL_CHARTS.ALARMS_TYPE',
			chartType: this.dashboard.chartType.ALARM,
			image :  '../../assets/alarm.png',
		},
		{
			label: 'ANALYTICAL_CHARTS.OPERATORS',
			chartType: this.dashboard.chartType.OPERATOR,
			image : '../../assets/operator.png',
		},
		{
			label: 'ANALYTICAL_CHARTS.SITES',
			chartType: this.dashboard.chartType.SITES,
			image :  '../../assets/site.png',
		}
	];
	public resolutionOfScreen = AppConstants.NORMAL;
	appConstants = AppConstants;
	public isSitesPriorityChartShown = false;
	alarmsChangeSubscriber: any;

	constructor(
		public dashboard: AnalyticalDashboardService,
		public chartService: ChartService,
		public translateService: TranslateService,
		public authService: AuthenticationService,
		public translate: TranslateService
	) { }

	ngOnInit() {
		this.alarmsChangeSubscriber = this.dashboard.getAlarmsNotification().subscribe((isChange) => {
			document.getElementById(this.CHART_ID) ? this.renderAlarmChart() :
				setTimeout(() =>
					document.getElementById(this.CHART_ID) ? this.renderAlarmChart() : ''
					, 2000);
		});
		this.dashboard.getAllClearNotification().subscribe((isChange) => {
			if (!isChange) {
				this.redrawChart();
			}
		});
	}


	ngOnDestroy() {
		this.destroyChartsExcept();
		this.alarmsChangeSubscriber.unsubscribe();
	}

	redrawChart() {
		switch (this.dashboard.chartSelected) {
			case this.dashboard.chartType.ALARM:
				this.selectChart(this.chartMenus[0]);
				break;
			case this.dashboard.chartType.OPERATOR:
				this.selectChart(this.chartMenus[1]);
				break;
			case this.dashboard.chartType.SITES:
				this.selectChart(this.chartMenus[2]);
				break;
		}

	}
	/**
	 * called to render alarms chart
	 */
	async renderAlarmChart() {
		const chartData: any = await this.dashboard.getAlarmsTypesStats();
		this.resolutionOfScreen = Utility.getScreenSize();
		const options = ChartResponsiveOptionUtil.alarmsChartOptions(this.resolutionOfScreen);
		let totalAlarms = 0;
		(chartData || []).forEach(x => totalAlarms += x.count);
		const titleTooltip = this.translateService.instant('ANALYTICAL_CHARTS.NO_OF_ALARMS') + ' :' + totalAlarms;
		this.alarmsChart = await this.chartService.renderPieChart(
			this.CHART_ID,
			this.translateService.instant('ANALYTICAL_CHARTS.CHART_TITLE_ALARMS'),
			chartData,
			ChartResponsiveOptionUtil.getAlarmsOptions(options, titleTooltip)
		);
		this.isChartChanged = true;
	}

	/**
	 * called to render operators chart
	 */
	async renderOperatorsChart() {
		const results = await Promise.all([
			// this.authService.getUsers(),
			this.authService.getSupervisorActiveUsers(),
			this.dashboard.getBranches(),
			this.dashboard.getOperatorsRegionsStats()
		]);
		const operators = results[0];
		const regions = results[1];
		const chartData = results[2];

		const totalOperators = operators ? (operators.length + '') : '';
		const noOfRegions = regions ? (regions.length + '') : '';
		const NO_OF_REGIONS = this.translate.instant('ANALYTICAL_CHARTS.NO_OF_REGIONS');
		const TOTAL_OPERATORS = this.translate.instant('ANALYTICAL_CHARTS.TOTAL_OPERATORS');
		const legendData = NO_OF_REGIONS + ' : ' + noOfRegions + '</br> <div class="legend-row">' + TOTAL_OPERATORS + ' : ' + totalOperators + '</div>';
		this.resolutionOfScreen = Utility.getScreenSize();
		const options = ChartResponsiveOptionUtil.operatorChartsOptions(this.resolutionOfScreen, chartData.length);
		this.operatorsChart = await this.chartService.renderPieChart(
			this.CHART_ID,
			this.translate.instant('ANALYTICAL_CHARTS.CHART_TITLE_OPERATORS'),
			chartData,
			ChartResponsiveOptionUtil.getOperatorOptions(options, legendData)
		);
		this.isChartChanged = true;
	}

	/**
	 * called to render sites chart
	 */
	async renderSiteChart() {
		// Get data to render chart
		const results = await Promise.all([
			this.dashboard.getSites(),
			this.dashboard.getBranches(),
			this.dashboard.getSitesRegionsStats()
		]);
		const sites = results[0];
		const regions = results[1];
		const chartData = results[2];
		const totalSites = sites ? (sites.length + '') : '';
		const noOfRegions = regions ? (regions.length + '') : '';
		const TOTAL_SITES = this.translate.instant('ANALYTICAL_CHARTS.TOTAL_SITES');
		const NO_OF_REGIONS = this.translate.instant('ANALYTICAL_CHARTS.NO_OF_REGIONS');
		const legendData = TOTAL_SITES + ' : ' + totalSites + '</br> <div class="legend-row">' + NO_OF_REGIONS + ' : ' + noOfRegions + '</div>';
		this.resolutionOfScreen = Utility.getScreenSize();
		const options = ChartResponsiveOptionUtil.sitesChartOptions(this.resolutionOfScreen, chartData.length);
		this.sitesChart = await this.chartService.renderPieChart(
			this.CHART_ID,
			this.translate.instant('ANALYTICAL_CHARTS.CHART_TITLE_BRANCH_STATUS'),
			chartData,
			ChartResponsiveOptionUtil.getSitesOptions(options, legendData)
		);
		this.isChartChanged = true;
	}

	/**
	 * called to render sites Priority chart
	 */
	async renderSitePriorityChart() {
		const chartData = await this.dashboard.getSitesBranchTypesStats();
		this.resolutionOfScreen = Utility.getScreenSize();
		const options = ChartResponsiveOptionUtil.sitesPriorityChartOptions(this.resolutionOfScreen);
		this.sitePriorityChart = await this.chartService.renderPieChart(
			this.CHART_ID_2,
			this.translate.instant('ANALYTICAL_CHARTS.CHART_TITLE_BRANCH_PRIORITY'),
			chartData,
			ChartResponsiveOptionUtil.getSitesPriorityOptions(options)
		);
		this.isSitesPriorityChartShown = true;
	}

	destroyChartsExcept(chartInstanceType?: string) {
		let chartsMap = [
			{
				type: 'alarms',
				chartInstances: [this.alarmsChart]
			},
			{
				type: 'operators',
				chartInstances: [this.operatorsChart]
			},
			{
				type: 'sites',
				chartInstances: [this.sitesChart, this.sitePriorityChart]
			}
		];
		chartsMap = chartsMap.filter((chart) => {
			return chartInstanceType !== chart.type;
		});
		try {
			chartsMap.forEach(chart => {
				chart.chartInstances.forEach(i => i.destroy());
			});
			document.querySelectorAll('.highchart-title-tooltip').forEach(x => x.remove());
		} catch (error) {
			// silently ignore all errors occurred while destroying chart instances.
		}
	}

	/**
	 * called to select a chart and component
	 * this function makes all the call needed to change component
	 */
	async selectChart(chartMenu: ChartMenu) {
		this.isSitesPriorityChartShown = false;
		this.dashboard.lastUpdatedTime = new Date();
		this.isChartChanged = this.dashboard.chartSelected === chartMenu.chartType;
		const data: any = {
			chartType: chartMenu.chartType,
			isSelected: true
		};
		this.dashboard.selectChart(data);
		this.dashboard.resetSelectedFilters();

		switch (chartMenu.chartType) {
			case this.dashboard.chartType.ALARM:
				this.destroyChartsExcept('alarms');
				await this.dashboard.fetchAlarmsStatistics();
				this.dashboard.getAlarmsHistoryStats(AppConstants.JSON_TEXT, false, false, false, false);
				this.renderAlarmChart();
				break;
			case this.dashboard.chartType.OPERATOR:
				this.destroyChartsExcept('operators');
				await this.dashboard.fetchOperatorsStatistics();
				this.renderOperatorsChart();
				this.dashboard.operatorsFilterParams.statuses = null;
				this.dashboard.operatorsFilterParams.regions = null;
				this.dashboard.filterOperatorReport(AppConstants.JSON_TEXT, false, false);
				break;
			case this.dashboard.chartType.SITES:
				this.destroyChartsExcept('sites');
				this.renderSiteChart();
				await this.renderSitePriorityChart();
				this.dashboard.fetchSitesStatistics();
				this.dashboard.branchParams = {};
				this.dashboard.BranchStatusReportFilter();
				break;
		}
	}

}
