import { AppConstants } from '../constants/app-constants';

export class AppLocalStorage {

	static setToken(token: string) {
		localStorage.setItem(AppConstants.SESSION_TOKEN, token);
	}

	static getToken() {
		return localStorage.getItem(AppConstants.SESSION_TOKEN);
	}

	static logoutUser() {
		// localStorage.clear();
		localStorage.removeItem('authToken');
		localStorage.removeItem('socketId');
		localStorage.removeItem('prevAlarm');
		// localStorage.removeItem('language');
		localStorage.removeItem('role');
		localStorage.removeItem('opendChatRooms');
		localStorage.removeItem('confidenceMonitoringMode');
		localStorage.removeItem('utcTime');
		localStorage.removeItem('lastUrl');
	}

	static setLanguage(lang: string) {
		localStorage.setItem(AppConstants.LANGUAGE, lang);
	}

	static getLanguage() {
		return localStorage.getItem(AppConstants.LANGUAGE);
	}
}
