import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BranchService } from 'src/app/services/branch.service';
import { AlarmService } from '../../services/alarm.service';
import { Utility } from '../../util/app-utils';
import { Subscription } from 'rxjs';
import { ConfidenceMonitoringService } from '../../services/confidence-monitoring.service';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';

@Component({
  selector: 'app-supervisor-add-alarm',
  templateUrl: './supervisor-add-alarm.component.html',
  styleUrls: ['./supervisor-add-alarm.component.scss']
})
export class SupervisorAddAlarmComponent implements OnInit {
  @Input() supervisor_selectedBranch = null;
  selectedBranchId: any;
  utility = Utility;
  branches: [];
  alarmTypes: [];
  selectedAlarmType: string;
  severityTypes: [];
  isSelectedBranch: boolean = false;
  isSelectedAlarmType: boolean = false;
  isSelectedSeverity: boolean = false;
  selectedSeverity: string;
  comment: any;
  success_message: any;
  failure_message: any;
  success_send: boolean = false;
  failure_send: boolean = false;
  disable_add: boolean = true;

  constructor(public translate: TranslateService, public branchService: BranchService, public alarmService: AlarmService,
    public confidenceMonitoringService: ConfidenceMonitoringService, public analyticalDashboard: AnalyticalDashboardService) { }


    async ngOnInit() {
      await this.getSeverities();
      this.reset();
    }
  
    async ngOnChanges() {
      this.selectedBranchId = this.supervisor_selectedBranch.id;
      this.isSelectedBranch = true;
      this.selectedAlarmType = null;
      this.selectedSeverity = null;
      this.getAlarmTypes();
      this.closeCard();
    }
  
    onAlarmTypeChange(ev: any) {
      this.selectedAlarmType = ev.source.selected.value;
      this.isSelectedAlarmType = true;
    }
  
    onSeverityChange(ev: any) {
      this.selectedSeverity = ev.source.selected.value;
      this.isSelectedSeverity = true;
    }
  
    async getAlarmTypes() {
      this.alarmTypes = await this.alarmService.getAlarmsTypes(this.selectedBranchId);
    }
  
    async getSeverities() {
      this.severityTypes = await this.alarmService.getSeverityTypes();
    }
  
    async addAlarm() {
      let response = await this.alarmService.addAlarm(this.selectedBranchId, this.selectedAlarmType, this.selectedSeverity, this.comment);
      if (response.success === true) {
        this.success_message = this.translate.instant('ADD_ALARM.SUCCESS_MESSAGE');
        this.failure_send = false;
        this.success_send = true;
        this.reset();
      } else {
        this.failure_message = response.errorMessages[0].message;
        this.success_send = false;
        this.failure_send = true;
      }
    }
  
    closeCard() {
      this.success_send = false;
      this.failure_send = false;
    }
  
    reset() {
      // this.selectedBranch = null;
      this.selectedAlarmType = null;
      this.selectedSeverity = null;
      // this.isSelectedBranch = false;
      this.isSelectedAlarmType = false;
      this.isSelectedSeverity = false;
      this.comment = "";
    }
  
    ngOnDestroy() {}

}
