import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Utility } from '../util/app-utils';


@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
  utility = Utility;
  media: any;

  @ViewChild('videoPlayer', { static: true }) videoplayer: ElementRef;

  constructor(public dialog: MatDialog, private dialogRef: MatDialogRef<MediaComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.media = this.data;
  }

  openDialog() {
    const dialogRef = this.dialog.open(MediaComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

}
