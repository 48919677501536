import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/models/api-response.model';
import { Subject, Observable, firstValueFrom, lastValueFrom, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConstants } from '../constants/api-constants';
import { AppConfigService } from '../app-config.service';
import { catchError, map } from "rxjs/operators";
import { Alarm } from 'src/models/alarm.model';
import { NbWindowControlButtonsConfig, NbWindowService, NbWindowState, NbWindowRef } from '@nebular/theme';
import { WindowFormComponent } from '../chat/window-form/window-form.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { WEB_SOCKET_EVENTS } from '../enums/web-socket-events';
import { SocketService } from '../services/socket.service';
import { AppSessionStorage } from '../util/app-session-storage-utils';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  newMessage = new Subject<any>();
  windowState = new Subject<any>();

  opendChatRooms: Array<any> = [];
  private chatWindowsMap = new Map<string, NbWindowRef>();

  private roomSubscriptions: { [key: string]: Subscription } = {};

  currentAlarm: {
    alarm: Alarm,
    enableReply: boolean
  };

  notifications: Array<any> = [];
  notificationsCount: number;
  notificationsCountLimit: string;
  groupedNotificationsCount: { [roomId: string]: number } = {};

  constructor(private http: HttpClient, private appConfigService: AppConfigService,
    private windowService: NbWindowService, public translate: TranslateService,
    private socketService: SocketService, public _snackBar: MatSnackBar,) { }

  newMessageReceived(messages: any) {
    this.newMessage.next(messages);
  }

  newMessageListner() {
    return this.newMessage.asObservable();
  }

  windowStateChange(state: any) {
    this.windowState.next(state);
  }

  windowStateListner() {
    return this.windowState.asObservable();
  }

  // get room purposes
  async getRoomPurposes(branchId: string, info: string, severity: string, type: string) {
    const body = {
      branchId: branchId,
      info: info,
      severity: severity,
      type: type
    };
    let URL = ApiConstants.URL_ROOM_PURPOSES;
    const response = await lastValueFrom(this.http.post<ApiResponse>(URL, body));
    if (response && response.success === true) {
      //
    }
    return response;
  }

  async getNotifications() {
    const count = this.appConfigService.appConfig.NOTIFICATIONS_COUNT_LIMIT;
    const URL = `${ApiConstants.URL_NOTIFICATIONS}?count=${count}&status=New`;
    let res = await lastValueFrom(this.http.get<ApiResponse>(URL));
    return res;
  }

  async getGroupedNotifications() {
    const count = this.appConfigService.appConfig.NOTIFICATIONS_COUNT_LIMIT;
    const URL = `${ApiConstants.GROUPED_NOTIFICATIONS_URL}?count=${count}&status=New`;
    let res = await lastValueFrom(this.http.get<ApiResponse>(URL));
    return res;
  }

  async getAllGroupedNotifications() {
    const count = 30;
    const URL = `${ApiConstants.GROUPED_NOTIFICATIONS_URL}?count=${count}&status=New`;
    let res = await lastValueFrom(this.http.get<ApiResponse>(URL));
    return res;
  }

  async getUserRooms(alarmId?: string) {
    const count = this.appConfigService.appConfig.NOTIFICATIONS_COUNT_LIMIT;
    const URL = `${ApiConstants.URL_ROOM}?count=${count}&purposeId=${alarmId}`;
    let res = await lastValueFrom(this.http.get<ApiResponse>(URL));
    return res;
  }

  async getAlarmRoom(alarmId?: string) {
    const URL = `${ApiConstants.URL_ROOM_PURPOSES}?purpose=Alarm&ids[]=${alarmId}`;
    let res = await lastValueFrom(this.http.get<ApiResponse>(URL));
    return res;
  }

  async getRoomById(roomId: string) {
    try {
      const URL = ApiConstants.URL_ROOM + '/' + roomId;
      let res = await lastValueFrom(this.http.get<ApiResponse>(URL));
      return res;
    } catch (error) {
      this.closeChatWebSocket(roomId);
      const windowRef = this.chatWindowsMap.get(roomId);
      if (windowRef) {
        const message = this.translate.instant('CHAT.NO_ACCESS');
        const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
        this.showSnackBar(message, confirmText);
        windowRef.close();
      }
    }
  }

  async createPrivateRoom(user: any) {
    const body = {
      title: user.title,
      purpose: 'Private',
      members: [user.id],
      notified: [user.id]
    };
    let URL = ApiConstants.URL_ROOM;
    const response = await lastValueFrom(this.http.post<ApiResponse>(URL, body));
    if (response && response.success === true) {
      return response.data;
    }
    return null;
  }

  async createAlarmRoom(alarm: any, users: any, notifiedUsers: any) {
    const body = {
      title: alarm.shortAlarmId + ', ' + alarm.type,
      purpose: 'Alarm',
      purposeId: alarm.id,
      members: users,
      notified: notifiedUsers
    };
    let URL = ApiConstants.URL_ROOM;
    const response = await lastValueFrom(this.http.post<ApiResponse>(URL, body));
    if (response && response.success === true) {
      return response.data;
    }
    return null;
  }

  async uploadMedia(media: any, userId: string) {
    const body = {
      media: media,
      userId: userId
    };
    let URL = ApiConstants.URL_MEDIA_UPLOAD;
    const response = await lastValueFrom(this.http.post<ApiResponse>(URL, body));
    if (response && response.success === true) {
      //
    }
    return response;
  }

  async createRoomMessage(roomId: string, type: string, content: string) {
    type = type.charAt(0).toUpperCase() + type.slice(1);
    const body = {
      type: type,
      content: content
    };
    let URL = ApiConstants.URL_ROOM + '/' + roomId + '/message';
    const response = await lastValueFrom(this.http.post<ApiResponse>(URL, body));
    if (response && response.success === true) {
      //
    }
    return response;
  }

  async addRoomMembers(roomId: string, userIds: any) {
    const body = {
      userIds: [userIds]
    };
    let URL = ApiConstants.URL_ROOM + '/' + roomId + '/member';
    const response = await lastValueFrom(this.http.post<ApiResponse>(URL, body));
    if (response && response.success === true) {
      //
    }
    return response;
  }

  async removeRoomMembers(roomId: string, userIds: any) {
    const body = {
      userIds: [userIds]
    };
    let URL = ApiConstants.URL_ROOM + '/' + roomId + '/member';
    const options = {
      body: body
    };
    const response = await lastValueFrom(this.http.delete<ApiResponse>(URL, options));
    if (response && response.success === true) {
      //
    }
    return response;
  }

  async updateRoomMembers(roomId: string, users: any, notifiedUsers: any) {
    const body = {
      members: users,
      notified: notifiedUsers
    };
    let URL = ApiConstants.URL_ROOM + '/' + roomId + '/members';
    const response = await lastValueFrom(this.http.patch<ApiResponse>(URL, body));
    if (response && response.success === true) {
      //
    }
    return response;
  }

  async terminateRoom(roomId: string) {
    let URL = ApiConstants.URL_ROOM + '/' + roomId + '/terminate';
    return lastValueFrom(this.http.patch<ApiResponse>(URL, {}));
  }

  async seeNotifications(roomId: string) {
    let URL = ApiConstants.URL_NOTIFICATIONS + '/' + roomId + '/seen';
    const response = await lastValueFrom(this.http.patch<ApiResponse>(URL, {}));
    if (response && response.success === true) {
      //
    }
    return response;
  }

  async markNotificationSeen(notification: any) {
    // Mark the grouped notification as seen on the server
    await this.set_RoomNotifications_seen(notification._id);
    // Remove from local list
    const index = this.notifications.indexOf(notification);
    this.notifications.splice(index, 1);
    this.notificationsCount--;
    this.updateNotificationsDisplayCount();
  }

  async set_RoomNotifications_seen(roomId: string) {
    let URL = ApiConstants.ROOM_NOTIFICATIONS_URL + '/' + roomId + '/seen';
    const response = await lastValueFrom(this.http.patch<ApiResponse>(URL, {}));
    if (response && response.success === true) {
      //
    }
    return response;
  }

  getDirectChat(userId: string): Observable<Array<any>> {
    const params = { members: [userId] }
    return this.http.get<ApiResponse>(`${ApiConstants.URL_ROOM}/directChat`, { params }).pipe(
      map(response => {
        return response.data;
      }),
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  createNewConversation(user: any, message: { type: string; content: string }) {
    user.title = user.role + ': ' + user.name;
    message.type = message.type.charAt(0).toUpperCase() + message.type.slice(1).toLowerCase();
    let room = {
      title: user.title,
      purpose: 'Private',
      messages: [message],
      members: [user.id],
      notified: [user.id]
    };
    return this.http.post<ApiResponse>(`${ApiConstants.URL_ROOM}`, room).pipe(
      map(response => {
        return response.data;
      }),
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  // Open chat window
  async openChatWindow(data: any) {
    const roomId = data._id;
    // Close existing opened chat rooms
    this.chatWindowsMap.forEach((windowRef, openRoomId) => {
      if (roomId !== openRoomId) {
        windowRef.close();
        this.opendChatRooms = this.opendChatRooms.filter(id => id !== openRoomId);
        // Update localStorage for the closed room
        let storedOpendChatRooms = JSON.parse(localStorage.getItem('opendChatRooms') || '[]');
        storedOpendChatRooms = storedOpendChatRooms.filter((room: any) => room.roomId !== openRoomId);
        localStorage.setItem('opendChatRooms', JSON.stringify(storedOpendChatRooms));
      }
    });

    // To Remove/reset groupedNotificationsCount for this chat room
    if (this.groupedNotificationsCount[roomId]) {
      delete this.groupedNotificationsCount[roomId];
    }

    // Proceed to open new chat room if it's not already opened
    if (!this.opendChatRooms.includes(roomId)) {
      this.opendChatRooms.push(roomId);
      let opendChatRooms = JSON.parse(localStorage.getItem('opendChatRooms') || '[]');
      if (!opendChatRooms.some((room: any) => room.roomId === roomId)) {
        opendChatRooms.push({ roomId, title: data.title || this.translate.instant('CHAT.NO_TITLE') });
        localStorage.setItem('opendChatRooms', JSON.stringify(opendChatRooms));
      }

      const buttonsConfig: NbWindowControlButtonsConfig = {
        minimize: true,
        maximize: false,
        fullScreen: true,
        close: true,
      };

      const windowRef = this.windowService.open(WindowFormComponent, {
        title: this.getTextToShow(data.title),
        buttons: buttonsConfig,
        hasBackdrop: false,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        initialState: NbWindowState.MAXIMIZED,
        windowClass: "nb-theme-default",
        context: roomId,
      });

      this.chatWindowsMap.set(roomId, windowRef);

      // Subscribe to window state changes
      windowRef.stateChange.subscribe((data) => {
        let opendChatRooms = JSON.parse(localStorage.getItem('opendChatRooms') || '[]');
        const chatRoom = opendChatRooms.find((room: any) => room.roomId === roomId);
        if (chatRoom) {
          chatRoom.state = data.newState;
          // store the new state
          localStorage.setItem('opendChatRooms', JSON.stringify(opendChatRooms));
        }

        if (data.newState === NbWindowState.MINIMIZED) {
          windowRef.config.buttons = {
            ...buttonsConfig,
            minimize: false,
            maximize: true,
          };
        } else if (data.newState === NbWindowState.MAXIMIZED) {
          windowRef.config.buttons = {
            ...buttonsConfig,
            minimize: true,
            maximize: false,
            fullScreen: false
          };
        }
      });

      windowRef.onClose.subscribe((data) => {
        const index = this.opendChatRooms.indexOf(roomId);
        if (index !== -1) {
          this.opendChatRooms.splice(index, 1);
          // Get the existing opened rooms from localStorage
          let storedOpendChatRooms = JSON.parse(localStorage.getItem('opendChatRooms') || '[]');
          // Remove the closed room from the stored rooms
          storedOpendChatRooms = storedOpendChatRooms.filter((room: any) => room.roomId !== roomId);
          // Update the localStorage with the filtered rooms
          localStorage.setItem('opendChatRooms', JSON.stringify(storedOpendChatRooms));
        }
        // close web socket for that chat to prevent message duplication Issue #471
        this.closeChatWebSocket(roomId);
        this.chatWindowsMap.delete(roomId);
      });

      this.onChatMessageNotification(data);
      // Issue #481 seen notifications for opened chat
      await this.handleNotifications(roomId);
    }
  }

  getTextToShow(input: string) {
    input = input.trim();
    // Capitalize the first letter
    input = input.charAt(0).toUpperCase() + input.slice(1);
    return input.length > 34 ? input.substring(0, 33) + ' ..' : input;
  }

  closeChatWebSocket(roomId: string) {
    if (this.roomSubscriptions[roomId]) {
      this.roomSubscriptions[roomId].unsubscribe();
      delete this.roomSubscriptions[roomId];
    }
  }

  onChatMessageNotification(notification: any) {
    let roomSocketId = notification._id;
    if (!roomSocketId) {
      return;
    }
    const chatEventNotification = this.socketService.onEvent<any>(roomSocketId);
    if (chatEventNotification) {
      this.roomSubscriptions[roomSocketId] = chatEventNotification.subscribe((socketEvent) => {
        const data = socketEvent.data;
        switch (socketEvent.type) {
          case WEB_SOCKET_EVENTS.CHAT_MESSAGE:
            this.onChatMessage(data);
            break;
          case WEB_SOCKET_EVENTS.CHAT_REMOVED_MEMBER:
            this.onRemoveChatMember(data);
            break;
          default:
        }
      });
    }
  }

  onChatMessage(data: any) {
    this.newMessageReceived(data);
  }

  onRemoveChatMember(data: any) {
    const currentUser = AppSessionStorage.getUser();
    const currentUserId = currentUser.userId;
    // Check if the current user's userId is present in the affectedUsers
    const isUserAffected = data.event.affectedUsers.some((user: any) => user._id === currentUserId);
    if (isUserAffected && this.opendChatRooms.includes(data.roomId)) {
      const index = this.opendChatRooms.indexOf(data.roomId);
      if (index !== -1) {
        this.opendChatRooms.splice(index, 1);
        let storedOpendChatRooms = JSON.parse(localStorage.getItem('opendChatRooms') || '[]');
        storedOpendChatRooms = storedOpendChatRooms.filter((room: any) => room.roomId !== data.roomId);
        localStorage.setItem('opendChatRooms', JSON.stringify(storedOpendChatRooms));
      }
      this.closeChatWebSocket(data.roomId);
      const windowRef = this.chatWindowsMap.get(data.roomId);
      if (windowRef) {
        const message = this.translate.instant('CHAT.REMOVED_MEMBER');
        const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
        this.showSnackBar(message, confirmText);
        windowRef.close();
      }
    }
  }

  showSnackBar(message: string, action: string) {
    return this._snackBar.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'custom-snack-bar',
    });
  }

  setCurrentAlarm(chatAlarm: any) {
    this.currentAlarm = chatAlarm;
  }

  getCurrentAlarm() {
    return this.currentAlarm;
  }

  async handleNotifications(roomId: string) {
    for (const notification of this.notifications) {
      if (notification._id === roomId) {
        await this.markNotificationSeen(notification);
        break;
      }
    }
  }

  async updateNotificationsDisplayCount() {
    const limit = this.appConfigService.appConfig.NOTIFICATIONS_COUNT_LIMIT;
    const count = this.notificationsCount;
    this.notificationsCountLimit = count > limit ? `${limit}+` : count.toString();
  }

}
