<div class="dialog-content">

    <div class="header">{{ "ALARM_DETAILS.ALARM_DETAILS" | translate }}</div>
	<div class="alarm-details">
		<table aria-describedby="alarm-info">
			<tr class="row-12">
				<td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.ALARM_ID" | translate}}: </span> 
                    <span class="value">{{alarm?.shortAlarmId}}</span></td>
				<td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.ALARM_TYPE" | translate}}: </span> 
                    <span class="value">{{alarm?.alarmType}}</span></td>
			</tr>
			<tr class="row-12">
                <td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.AREA_NAME" | translate}}: </span>
                    <span class="value">{{alarm?.branch.areaName}}</span></td>  
                <td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.BRANCH_NAME" | translate}}: </span>
                    <span class="value">{{alarm?.branch.branchName}}</span></td>
			</tr>
            <tr class="row-12">
                <td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.STATUS" | translate}}: </span>
                    <span class="value">{{alarm?.status}}</span></td>
                <td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.ACTIVATION_TIME" | translate }}: </span>
                    <span class="value">{{getGmtTime(alarm?.activationTime)}}</span></td>
			</tr>
            <tr class="row-12">
                <td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.LONGITUDE" | translate}}: </span>
                    <span class="value">{{alarm?.branch.location.longitude}}</span></td>
                <td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.LATITUDE" | translate}}: </span>
                    <span class="value">{{alarm?.branch.location.latitude}}</span></td>  
			</tr>
            <tr class="row-12">
                <td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.ESCALATED_BY" | translate}}: </span>
                    <span class="value">{{ getUserName(alarm?.escalatedBy.firstName,  alarm?.escalatedBy.lastName)}}</span></td>
				<td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.ESCALATED_TO" | translate}}: </span>
                    <span class="value">{{ getUserName(alarm?.escalatedTo.firstName, alarm?.escalatedTo.lastName)}}</span></td>
			</tr>
            <tr class="row-12">
                <td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.VERIFY_BY" | translate}}: </span>
                    <span class="value">{{ getUserName(alarm?.verifiedBy.firstName,  alarm?.verifiedBy.lastName)}}</span></td>
				<td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.VERIFY_FROM" | translate}}: </span>
                    <span class="value">{{ getUserName(alarm?.verifiedFrom.firstName, alarm?.verifiedFrom.lastName)}}</span></td>
			</tr>
            <tr class="row-12">
                <td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.RESOLVED_BY" | translate}}: </span>
                    <span class="value">{{ getUserName(alarm?.resolvedBy.firstName,  alarm?.resolvedBy.lastName)}}</span></td>
				<td class="col-6">
                    <span class="key">{{ "REPORT_DETAIL.LAST_ACTION_TIME" | translate}}: </span>
                    <span class="value">{{getGmtTime(alarm?.lastactiontime)}}</span></td>
			</tr>
            <tr class="row-12">
                <div class="alarmInfo">
                    <span class="key">{{ "REPORT_DETAIL.ALARM_INFO" | translate}}: </span>
                    <span class="value">{{getAlarmInfo(alarm?.info)}}</span>
                </div>
			</tr>
		</table>
	</div>

    
    <div class="action-history">
        <div class="header">{{ "ACTION_HISTORY.ACTION_HISTORY" | translate }}</div>
    
        <table mat-table [dataSource]="actions" *ngIf="actions && actions.length > 0; else noActions">
            <ng-container matColumnDef="timeStamp">
                <th mat-header-cell *matHeaderCellDef>{{ "ACTION_HISTORY.DATE_TIME" | translate }}</th>
                <td mat-cell *matCellDef="let element" class="time-data">
                    {{ getDate(element.timeStamp) }}
                </td>
            </ng-container>
    
            <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef>{{ "ACTION_HISTORY.ACTION" | translate }}</th>
                <td mat-cell *matCellDef="let element" class="action-data" matTooltip="{{element.action}}">
                    {{element.action}}
                    <img class="photo-icon" alt="photo" [src]="utility.getImagePath('photo.png')" *ngIf="element.type ==='photo'" 
                    (click)="displayMedia(element)"> 
                    <img class="video-icon" alt="video" [src]="utility.getImagePath('playVideo.png')" *ngIf="element.type ==='video'"
                    (click)="displayMedia(element)">
                    <audio *ngIf="element.type ==='audio'" alt="audio" controls>
                        <source [src]="element.url" type="audio/mp3" />
                    </audio>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>{{ "ACTION_HISTORY.NAME" | translate }}</th>
                <td mat-cell *matCellDef="let element" class="name-data" 
                [matTooltip]="element.user?.firstName + ' ' + element.user?.lastName">
                    {{element.user.firstName + ' ' + element.user.lastName}}
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    
        <ng-template #noActions>
            <div class="box-content-no-data">
                {{'ALARM_HISTORY.NO_DATA' | translate}}
            </div>
        </ng-template>
    </div>

    <button class="btn" (click)="getAlarmReport()">{{ "FILTER_DATA.GET_FULL_REPORT_LINK" | translate}}</button>

    <img class="close-btn" alt="close" (click)="closeDialog()" [src]="utility.getImagePath('close_video.png')" />
</div>

