import { Component, OnInit, OnDestroy } from '@angular/core';
import { AnalyticalDashboardService } from '../services/analytical-dashboard.service';
import { Subject } from 'rxjs';
import { ConfidenceMonitoringService } from '../services/confidence-monitoring.service';
import { VideoStreamService } from '../services/video-stream.service';
import { AlarmService } from '../services/alarm.service';
import { Subscription } from 'rxjs';
import { Utility } from 'src/app/util/app-utils';

@Component({
	selector: 'app-confidence-monitor',
	templateUrl: './confidence-monitor.component.html',
	styleUrls: ['./confidence-monitor.component.scss']
})
export class ConfidenceMonitorComponent implements OnInit {

	public assetDoc: Subject<any> = new Subject();
	public getSitesChangedSubscriper: Subscription;
	public addAlarmExpandedSubscriber : Subscription;
	utility = Utility;
	panelOpenState = false;
	isAddAlarmExpanded: boolean = true;

	constructor(
		public alarmService: AlarmService,
		public analyticalDashboard: AnalyticalDashboardService,
		public confidenceMonitoringService: ConfidenceMonitoringService,
		public videoStreamService: VideoStreamService) { }

	ngOnInit() {
		this.analyticalDashboard.chartSelected = this.analyticalDashboard.chartType.SITES;

		this.getSitesChangedSubscriper = this.confidenceMonitoringService.getSitesChangedNotification().subscribe(async (branch: any) => {
			await this.videoStreamService.setEmptyVideoSources();
			await this.videoStreamService.fetchVideoSourcesList(branch.id);
		});

		this.addAlarmExpandedSubscriber = this.alarmService.getAddAlarmExpandedListener().subscribe(isExpanded => this.isAddAlarmExpanded = isExpanded);
	}

	ngOnDestroy() {
		this.analyticalDashboard.isFilterDataLoaded = false;
		this.analyticalDashboard.chartSelected = undefined;
		this.getSitesChangedSubscriper.unsubscribe();
	}
}
