<div class='login-main'>
	<div class="left-background">
		<div class="logo-login-wrapper">
			<div class="client-logo row">
				<img class="client-logo-icon" alt="client-logo-icon" [src]="logoUrl">
			</div>
			<div class="row login-info">
				<mat-card class="login-card">
					<mat-card-title class="login-heading">{{ "LOGIN.HEADING" | translate }}</mat-card-title>
					<mat-card-content>
						<div class="error-message">
							<span>{{errorMessage}}</span>
						</div>
						<form [formGroup]="loginForm" class='login-form'>
							<div class="form-group">
								<div class="input-label">{{ "LOGIN.USER_NAME" | translate }}</div>
								<mat-form-field appearance="outline" class="form-field col-12">
									<input formControlName="username" matInput type="text" (focus)="removeError()" autocomplete="on">
									<mat-error class="mat-error">
										<span *ngIf="loginForm.controls['username'].errors?.required">{{
											"LOGIN.VALIDATION_USERNAME_REQUIRED" | translate }}</span>
									</mat-error>
								</mat-form-field>
							</div>
							<div class="form-group">
								<div class="input-label">{{ "LOGIN.PASSWORD" | translate }}</div>
								<mat-form-field appearance="outline" class="form-field col-12">
									<input formControlName="password" matInput type="password" (focus)="removeError()" autocomplete="on">
									<mat-error class="mat-error">
										<span *ngIf="loginForm.controls['password'].errors?.required">{{
											"LOGIN.VALIDATION_PASSWORD_REQUIRED" | translate }}</span>
									</mat-error>
								</mat-form-field>
							</div>
							<!-- <div class="form-group">
								<mat-checkbox class="remember-me">{{ "LOGIN.REMEMBER_ME" | translate }}</mat-checkbox>
							</div> -->
							<div class="form-group">
								<button type="submit" (click)="loginUser()" class="login-button">{{ "BTN.LOGIN" | translate }}</button>
							</div>
						</form>
					</mat-card-content>
				</mat-card>
			</div>
		</div>

	</div>
	<div class="right-background"></div>
	<div class="app-logo right-side-panel">
		<img [src]="utility.getImagePath('RAMsysLogo.png')" alt="app-logo" class="app-logo-image">

		<div class="description-text-1" *ngIf="false">{{ "LOGIN.RIGHT_PANEL_TEXT_1" | translate }}</div>
		<div class="description-text-2" *ngIf="false">{{ "LOGIN.RIGHT_PANEL_TEXT_2" | translate }}</div>
	</div>


</div>