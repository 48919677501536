<mat-accordion>
	<mat-expansion-panel [expanded]="true"
		class="expansion-box"
		(opened)="opened()"
		(closed)="closed()">
		<mat-expansion-panel-header class="header">
			<mat-panel-title>
				<div class="box-title d-flex align-items-center">
					<div class="box-title-icon d-flex align-items-center">
						<img [src]="utility.getImagePath('list.png')" alt="list">
					</div>
					<div class="ml-2">
						{{ "ACTION_HISTORY.ACTION_HISTORY" | translate }}
					</div>
				</div>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="table-container">
			<table mat-table
				[dataSource]="actionsDataSource"
				*ngIf="actions && actions.length > 0; else noActions"
				aria-describedby="action-history">

				<ng-container matColumnDef="timeStamp">
					<th scope="col" mat-header-cell
						*matHeaderCellDef> {{ "ACTION_HISTORY.DATE_TIME" | translate }} </th>
					<td mat-cell
						*matCellDef="let element"
						class="time-stamp-data"> {{ getDate(element.timeStamp) }} </td>
				</ng-container>

				<ng-container matColumnDef="message">
					<th scope="col" mat-header-cell
						*matHeaderCellDef> {{ "ACTION_HISTORY.ACTION" | translate }}</th>
					<td mat-cell
						*matCellDef="let element"
						class="action-data"
						matTooltip="{{element.action}}">
						{{element.action}}
						<img class="photo-icon" alt="photo" [src]="utility.getImagePath('photo.png')" *ngIf="element.type ==='photo'" 
						(click)="displayMedia(element)"> 
						<img class="video-icon" alt="video" [src]="utility.getImagePath('playVideo.png')" *ngIf="element.type ==='video'"
						(click)="displayMedia(element)">
						<audio *ngIf="element.type ==='audio'" alt="audio" controls controlsList="nodownload noplaybackrate">
							<source [src]="element.url" type="audio/mp3" />
						</audio>
					</td>
				</ng-container>

				<ng-container matColumnDef="name">
					<th scope="col" mat-header-cell
						*matHeaderCellDef> {{ "ACTION_HISTORY.NAME" | translate }} </th>
					<td mat-cell
						*matCellDef="let element"
						class="name-data"
						matTooltip="{{element.user.firstName + ' ' + element.user.lastName}}">
						{{element.user.firstName + ' ' + element.user.lastName}} </td>
				</ng-container>

				<tr mat-header-row
					*matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row
					*matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
			<ng-template #noActions>
				<div class="box-content-no-data">
					{{'ALARM_HISTORY.NO_DATA' | translate}}
				</div>
			</ng-template>
		</div>
	</mat-expansion-panel>
</mat-accordion>