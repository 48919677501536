import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlarmReportEntry } from 'src/models/alarm-report.model';
import { ReportService } from '../../services/report.service';
import { ApiConstants } from 'src/app/constants/api-constants';
import { Utility } from 'src/app/util/app-utils';
import { AppConstants } from 'src/app/constants/app-constants';
import { DateUtil } from '../../util/date-util';
import { AuthenticationService } from '../../services/authentication.service';
import { AlarmInfoComponent } from '../alarm-info/alarm-info.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntlService } from 'src/app/services/custom-paginator-intl.service';
import { ChatService } from 'src/app/services/chat.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-report-details',
	templateUrl: './report-details.component.html',
	styleUrls: ['./report-details.component.scss'],
	providers: [
		{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntlService }
	]
})
export class ReportDetailsComponent implements OnInit {

	alarmReport: AlarmReportEntry[] = [];
	dataSource: MatTableDataSource<AlarmReportEntry>;
	isAsc = true;
	// displayedColumns = ['alarmId', 'branchName', 'areaName',
	// 	'alarmType', 'time', 'lastActionTime', 'status', 'escalatedBy', 'escalatedTo', 'verifiedBy',
	// 	'verifiedFrom', 'resolvedBy', 'viewDetails', 'report'];

	displayedColumns = ['alarmId', 'branchName', 'areaName', 'alarmType',
		'time', 'lastActionTime', 'status', 'info', 'viewDetails', 'chat', 'report'];

	NONE_STRING = 'none';
	utility = Utility;

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	baseURL = ApiConstants.URL_ALARM_HISTORY_REPORT;
	@ViewChild(MatPaginator, { static: true }) paginationEle: MatPaginator;
	appConstants = AppConstants;

	constructor(public reportService: ReportService, private authService: AuthenticationService, public dialog: MatDialog, public chatService: ChatService, public _snackBar: MatSnackBar,
		public translate: TranslateService) { }

	async ngOnInit() {
		this.getUpdatedData();
	}

	/**
	 * Gets updated alarms history report data
	 */
	getUpdatedData() {
		this.reportService.getOnFilterDataChangeListener().subscribe((response: AlarmReportEntry[]) => {
			if (response) {
				this.alarmReport = response;
				this.dataSource = new MatTableDataSource(this.alarmReport);
				this.dataSource.sort = this.sort;
				this.paginationEle.pageIndex = this.reportService.alarmFilterParameter.start - AppConstants.PAGE_DIFF_CONSTANT;
				this.paginationEle.pageSize = this.reportService.alarmFilterParameter.pageSize;
			}
		});
	}

	/**
	 * Gets GMT time
	 * @param timeMil time in milliseconds
	 * @returns  GMT date
	 */
	getGmtTime(timeMil: any) {
		return DateUtil.getFormattedDate(timeMil, AppConstants.DATE_TIME_FORMAT, this.authService.getUserTimezone());
	}

	/**
	 * Gets users full name i.e. first_name last_name
	 * @param firstName first name of user
	 * @param lastName last name of user
	 * @returns  username
	 */
	getUserName(firstName: string, lastName: string) {
		if (firstName === this.NONE_STRING && lastName === this.NONE_STRING) {
			return '-';
		}
		if (firstName === this.NONE_STRING) {
			firstName = '';
		}
		if (lastName === this.NONE_STRING) {
			lastName = '';
		}
		return firstName + ' ' + lastName;
	}

	/**
	 * Gets alarm report pdf
	 * @param alarmId id of alarm
	 */
	getAlarmReport(alarmId: string) {
		this.reportService.getSingleAlarmReportPdf(alarmId);
	}

	getAlarmDetails(alarmId: string) {
		this.dialog.open(AlarmInfoComponent, {
			height: '70%',
			width: '50%',
			disableClose: true,
			panelClass: 'custom-dialog',
			data: alarmId
		});
	}

	getAlarmInfo(info: string) {
		if (info === '') {
			return '-';
		} else {
			info = info.trim();
			return info.length > 90 ? info.substring(0, 89) + '...' : info;
		}
	}

	/**
	 * Sorts report data by column in table
	 * @param sort current sort state
	 */
	sortData(sort: MatSort) {
		const data = this.alarmReport;
		this.isAsc = !this.isAsc;
		this.alarmReport = data.sort((a, b) => {
			const columnIndex = this.displayedColumns.indexOf(sort.active);
			switch (columnIndex) {
				case 0: return this.compare((a.shortAlarmId || a.alarmId), (b.shortAlarmId || b.alarmId), this.isAsc);
				case 1: return this.compare(a.branch.branchName, b.branch.branchName, this.isAsc);
				case 2: return this.compare(a.branch.areaName, b.branch.areaName, this.isAsc);
				case 3: return this.compare(a.branch.location.latitude, b.branch.location.latitude, this.isAsc);
				case 4: return this.compare(a.branch.location.longitude, b.branch.location.longitude, this.isAsc);

				case 5: return this.compare(a.alarmType, b.alarmType, this.isAsc);
				case 6: return this.compare(this.getGmtTime(a.activationTime), this.getGmtTime(b.activationTime), this.isAsc);

				case 7: return this.compare(a.status, b.status, this.isAsc);
				case 8: return this.compare(this.getUserName(a.escalatedTo.firstName, a.escalatedTo.lastName), this.getUserName(b.escalatedTo.firstName, b.escalatedTo.lastName), this.isAsc);

				case 9: return this.compare(this.getUserName(a.escalatedTo.firstName, a.escalatedTo.lastName), this.getUserName(b.escalatedTo.firstName, b.escalatedTo.lastName), this.isAsc);

				case 10: return this.compare(this.getUserName(a.verifiedBy.firstName, a.verifiedBy.lastName), this.getUserName(b.verifiedBy.firstName, b.verifiedBy.lastName), this.isAsc);

				case 11: return this.compare(this.getUserName(a.verifiedFrom.firstName, a.verifiedFrom.lastName), this.getUserName(b.verifiedFrom.firstName, b.verifiedFrom.lastName), this.isAsc);

				case 12: return this.compare(this.getUserName(a.resolvedBy.firstName, a.resolvedBy.lastName), this.getUserName(b.resolvedBy.firstName, b.resolvedBy.lastName), this.isAsc);
				default: return 0;
			}
		});
		this.dataSource = new MatTableDataSource(this.alarmReport);
	}

	/**
	 * Compares two elements of a report data column
	 * @param a first element
	 * @param b second element
	 * @param isAsc order of sort
	 * @returns  boolean true/false
	 */
	compare(a: any, b: any, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	onPaginateChange(pageObj: any) {
		this.paginateAlarmHistory(pageObj);
	}

	async paginateAlarmHistory(pageObj: any) {
		const requestBody = this.getAlarmFilterParameter(pageObj);
		const response: any = await this.reportService.getReportDetails(requestBody);
		if (response && response.success) {
			this.reportService.changeFilterData(response.data);
		}
	}

	getAlarmFilterParameter(pageObj: any) {
		const requestBody = this.reportService.alarmFilterParameter || {};
		requestBody.start = pageObj.pageIndex + AppConstants.PAGE_DIFF_CONSTANT;
		requestBody.pageSize = pageObj.pageSize;
		return requestBody;
	}

	async getChatHistory(alarm: any) {
		let res = await this.chatService.getAlarmRoom(alarm.alarmId);
		if (res && res.success === true && res.data.length !== 0) {
			this.getUserRooms(alarm);
		} else {
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			const message = this.translate.instant('CHAT.NO_CHAT_ALARM') + ' : ' + alarm.shortAlarmId;
			let snackBarRef = this.openSnackBar(message, confirmText);
			snackBarRef.afterDismissed().subscribe(() => { });
		}
	}

	async getUserRooms(alarm: any) {
		let res = await this.chatService.getUserRooms(alarm.alarmId);
		if (res && res.success === true && res.data.rooms.length !== 0) {
			const room = res.data.rooms[0];
			const currentAlarm = {
				alarm: alarm,
				enableReply: this.authService.enableReply_chat
			};
			this.chatService.setCurrentAlarm(currentAlarm);
			this.chatService.openChatWindow(room);
		} else {
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			const message = this.translate.instant('CHAT.NO_ACCESS') + ' : ' + alarm.shortAlarmId;
			let snackBarRef = this.openSnackBar(message, confirmText);
			snackBarRef.afterDismissed().subscribe(() => {
				//
			});
		}
	}

	openSnackBar(message: string, action: string) {
		return this._snackBar.open(message, action, {
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: 'custom-snack-bar',
		});
	}

}
