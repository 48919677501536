import { Component, OnInit } from '@angular/core';
import { BranchService } from 'src/app/services/branch.service';
import { ApiResponse } from 'src/models/api-response.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { AppConfigService } from 'src/app/app-config.service';
import { ReportService } from 'src/app/services/report.service';
import { AppConstants } from 'src/app/constants/app-constants';
import { RmSelectItemData } from '../rm-multi-select/rm-multi-select.component';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from 'src/app/util/app-utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConstants } from '../../constants/api-constants';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../analytical-dashboard/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OwlDateTimeIntl } from 'ng-pick-datetime';
import moment from 'moment-timezone';
import { Router } from '@angular/router';
import { RouteConstants } from '../../constants/route-constants';
import { WEB_SOCKET_EVENTS } from '../../enums/web-socket-events';
import { SocketService } from '../../services/socket.service';
import { AppLocalStorage } from 'src/app/util/app-local-storage-utils';

@Component({
	selector: 'app-filter-data',
	templateUrl: './filter-data.component.html',
	styleUrls: ['./filter-data.component.scss']
})
export class FilterDataComponent implements OnInit {

	users: any[];
	formatedUserData: RmSelectItemData[] = [];
	formatedBranchData: RmSelectItemData[] = [];
	formatedAlarmTypesData: RmSelectItemData[] = [];
	formatedStatusesData: RmSelectItemData[] = [];
	formatedSeveritiesData: RmSelectItemData[] = [];
	selectedUsers: string[] = [];
	branches: any[];
	alarmTypesList: any[];
	statusesList: any[];
	severitiesList: any[];
	selectedBranches: string[] = [];
	selectedAlarmTypes: string[] = [];
	selectedStatus: string[] = [];
	selectedSeverity: string[] = [];
	shortAlarmIdText: string;
	alarmInfoText: string;
	defaultUserPlaceholder = true;
	defaultBranchPlaceholder = true;
	startDate: Date;
	selectedStartDate: any;
	endDate: any;
	selectedEndDate: any;
	millisecondsInOneDay = 86399999;
	currentDate = new Date();
	timeRange: any;
	public placeholder: any;
	public selectedMoments: any;
	public selectedFilter = {
		timeRange: false
	};

	isShowingBranchesDropdown = false;
	isShowingAlarmTypesDropdown = false;
	isShowingStatusesDropdown = false;
	isShowingSeveritiesDropdown = false;
	isShowingUserDropdown = false;
	allUsersPlaceholder: string = '';
	allSitesPlaceholder: string = '';
	allAlarmTypesPlaceholder: string = '';
	allStatusesPlaceholder: string = '';
	allSeveritiesPlaceholder: string = '';
	appConstants = AppConstants;
	utility = Utility;
	isHour12Format: boolean;

	constructor(
		private http: HttpClient,
		public branchService: BranchService,
		public analyticalDashboardService: AnalyticalDashboardService,
		public authService: AuthenticationService,
		public reportsService: ReportService,
		public appConfigService: AppConfigService,
		public translate: TranslateService,
		public dialog: MatDialog,
		public owlDateTimeIntl: OwlDateTimeIntl,
		private authenticationService: AuthenticationService,
		private socketService: SocketService,
		private router: Router,
	) { }

	async ngOnInit() {
		this.initFilters();
		this.allUsersPlaceholder = this.translate.instant('FILTER_DATA.ALL_USERS');
		this.allSitesPlaceholder = this.translate.instant('FILTER_DATA.ALL_SITES');
		this.allAlarmTypesPlaceholder = this.translate.instant('FILTER_DATA.ALL_ALARM_TYPES');
		this.allStatusesPlaceholder = this.translate.instant('FILTER_DATA.ALL_STATUSES');
		this.allSeveritiesPlaceholder = this.translate.instant('FILTER_DATA.ALL_SEVERITIES');
		this.datePickerInitialize();
		let lang = AppLocalStorage.getLanguage();
		this.isHour12Format = (lang !== "ru");

		// this.socketListener();
	}

	ngOnDestroy() {
		// this.socketService.closeSocket();
	}

	// DatePicker Initialize
	datePickerInitialize() {
		this.timeRange = AppConstants.TIME_RANGE;
		this.owlDateTimeIntl.setBtnLabel = this.translate.instant('ANALYTICAL_FILTERS.SAVE_TEXT');
		this.owlDateTimeIntl.cancelBtnLabel = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
		this.owlDateTimeIntl.rangeFromLabel = this.translate.instant('FILTER_DATA.FROM');
		this.owlDateTimeIntl.rangeToLabel = this.translate.instant('FILTER_DATA.TO');
		this.owlDateTimeIntl.hour12AMLabel = this.translate.instant('FILTER_DATA.AM');
		this.owlDateTimeIntl.hour12PMLabel = this.translate.instant('FILTER_DATA.PM');
		this.placeholder = {
			selectDateAndTime: this.translate.instant('ANALYTICAL_FILTERS.SELECT_DATE_AND_TIME'),
		};
		this.initFilterTimeRange();
	}

	/**
	 * called to set time range filter default time
	 */
	initFilterTimeRange() {
		const timezoneOffsetDifference = ((moment().utcOffset() - moment().tz(this.authService.getUserTimezone()).utcOffset()) * AppConstants.MINUTE_TO_MILLISECONDS);
		let nowNumber = new Date().getTime() - timezoneOffsetDifference
		let fromNumber = nowNumber - this.appConfigService.appConfig.FILTER_START_DATE_INTERVAL
		const now: any = new Date(nowNumber);
		const from = new Date(fromNumber);
		this.selectedMoments = [
			from,
			now
		];
		this.currentDate = now;
		this.endDate = now;
		this.startDate = from;
	}


	/**
	 * Initialize filter data form users, branches , short alarm Id's & start end dates
	 */
	async initFilters() {
		// await this.getUsers();
		await this.getBranches();
		await this.getAlarmTypes();
		await this.getStatuses();
		await this.getSeverities();
		this.initFiltersWithDefaultValue();
		this.filterData();
	}

	/**
	 * Gets users
	 */
	async getUsers() {
		try {
			this.users = await this.authService.getUsers();
			this.formatUserData();
		} catch (err) {
		}
	}

	/**
	 * Gets alarm types
	 */
	async getAlarmTypes() {
		try {
			const response = await this.analyticalDashboardService.getAlarmsTypes();
			if (response) {
				this.alarmTypesList = response;
				this.formatAlarmTypeData();
			}
		} catch (err) {
		}
	}

	/**
	 * Get Statuses
	 */
	async getStatuses() {
		try {
			const response = await this.analyticalDashboardService.getAlarmsStatusTypes();
			if (response) {
				this.statusesList = response;
				this.formatStatusesData();
			}
		} catch (err) {
		}
	}

	/**
	 * Get Severities
	 */
	async getSeverities() {
		try {
			const response = await this.analyticalDashboardService.getAlarmsSeverityTypes();
			if (response) {
				this.severitiesList = response;
				this.formatSeveritiesData();
			}
		} catch (err) {
		}
	}

	/**
	 * Gets branches
	 */
	async getBranches() {

		try {
			const response: ApiResponse = await this.branchService.getAllBranches();
			if (response.success === true) {
				this.branches = response.data;
				this.formatBranchData();
			}
		} catch (err) {
		}
	}

	/**
	 * converts date into GMT milliseconds
	 * @param dateTime date
	 * @returns  GMT milliseconds
	 */
	getUTCMilliseconds(dateTime: any) {
		const time = dateTime.getTime();
		let offset = dateTime.getTimezoneOffset();
		offset = offset * AppConstants.MINUTE_TO_MILLISECONDS;
		return time - offset;
	}

	// Get Selected Start Date
	public OnStartDateChange(event: any): void {
		this.selectedStartDate = event;
	}

	// Get Selected Start Date
	public OnEndDateChange(event: any): void {
		this.selectedEndDate = event;
	}

	/**
	 * Apply Filters on reports data and gets filtered data
	 */
	async filterData() {

		const requestBody = this.getRequestBody();
		try {
			const response: ApiResponse = await this.reportsService.getReportDetails(requestBody);
			if (response && response.success) {
				this.reportsService.changeFilterData(response.data);
			}
		} catch (err) {
		}
	}

	/**
	 * Constructs request body from filter data form
	 * @param [responseFormat] optional response format pdf/json
	 * @returns  request body object
	 */
	getRequestBody(responseFormat?: any) {
		const requestBody = {};
		const shortAlarmArray = Utility.stringToArrayByComma(this.shortAlarmIdText) || [];
		const alarmInfoArray = Utility.stringToArrayByComma(this.alarmInfoText) || [];
		if (this.startDate) {
			const startMilliseconds = this.getUTCMilliseconds(this.startDate);
			requestBody['startDate'] = startMilliseconds;
		}
		if (this.endDate) {
			const endMilliseconds = this.getUTCMilliseconds(this.endDate) + this.millisecondsInOneDay;
			requestBody['endDate'] = endMilliseconds;
		}
		if (this.selectedUsers.length) {
			requestBody['userIds'] = this.selectedUsers;
		}

		if (this.selectedAlarmTypes.length) {
			requestBody['alarmTypes'] = this.selectedAlarmTypes;
		}

		if (this.selectedStatus.length) {
			requestBody['status'] = this.selectedStatus;
		}

		if (this.selectedSeverity.length) {
			requestBody['severity'] = this.selectedSeverity;
		}

		if (shortAlarmArray.length) {
			requestBody['shortAlarmIds'] = shortAlarmArray;
		}

		if (alarmInfoArray.length) {
			requestBody['alarmInfo'] = alarmInfoArray;
		}

		if (this.selectedBranches.length) {
			requestBody['branchIds'] = this.selectedBranches;
		}

		if (responseFormat && responseFormat === AppConstants.PDF) {
			requestBody['responseFormat'] = responseFormat;
		}

		if (this.selectedMoments && this.selectedMoments.length === 2) {
			const timezoneOffsetDifference = ((moment().utcOffset() - moment().tz(this.authService.getUserTimezone()).utcOffset()) * AppConstants.MINUTE_TO_MILLISECONDS);
			requestBody['startDate'] = this.selectedMoments[0].getTime() + timezoneOffsetDifference;
			// Adding maximum milliseconds "999" for timeRange filter
			requestBody['endDate'] = this.selectedMoments[1].getTime() + timezoneOffsetDifference + 999;
		}

		requestBody['start'] = AppConstants.PAGE_START_CONSTANT;
		requestBody['pageSize'] = AppConstants.DEFAULT_PAGE_SIZE;
		// requestBody['pageSize'] = this.appConfigService.appConfig.PDF_REPORT_ALARMS_COUNT;
		return requestBody;
	}

	/**
	 * downloads full report pdf
	 */
	async getFullReport() {
		const requestBody2 = this.getRequestBody("json");
		const response: any = await this.http.post<any>(ApiConstants.URL_ALARM_HISTORY_REPORT, requestBody2).toPromise();
		let totalcount = Number(response.totalcount)
		if (totalcount > this.appConfigService.appConfig.PDF_REPORT_ALARMS_COUNT) {
			//show warning message
			const title = this.translate.instant('FILTER_DATA.PDF_WARNING_TITLE');
			let message = this.translate.instant('FILTER_DATA.PDF_WARNING_MESSAGE');
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
			const typeOfDialogBox = 2;
			const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
			this.dialog.open(ConfirmDialogComponent, {
				data: dialogData
			});
		}
		else {
			const requestBody = this.getRequestBody(AppConstants.PDF);
			requestBody['pageSize'] = this.appConfigService.appConfig.PDF_REPORT_ALARMS_COUNT;
			this.reportsService.getReportDetailsPdf(requestBody);
		}

	}

	/**
	 * Format user data in the form title,value to be shown in custom select box.
	 */
	formatUserData() {
		for (const user of this.users) {
			this.formatedUserData.push({ title: user.name, value: user.id });
		}
	}
	/**
	 * Format branch data in the form title,value to be shown in custom select box.
	 */
	formatBranchData() {
		for (const branch of this.branches) {
			this.formatedBranchData.push({ title: branch.branchName, value: branch._id });
		}
	}
	/**
	 * Format alarm types data in the form of title,value to be shown in custom select box.
	 */
	formatStatusesData() {
		for (const status of this.statusesList) {
			this.formatedStatusesData.push({ title: status, value: status });
		}
	}

	/**
	 * Format alarm types data in the form of title,value to be shown in custom select box.
	 */
	formatSeveritiesData() {
		for (const severity of this.severitiesList) {
			this.formatedSeveritiesData.push({ title: severity, value: severity });
		}
	}

	/**
	 * Format alarm types data in the form of title,value to be shown in custom select box.
	 */
	formatAlarmTypeData() {
		for (const alarmType of this.alarmTypesList) {
			this.formatedAlarmTypesData.push({ title: alarmType, value: alarmType });
		}
	}

	/**
	 * Invoked when branch selection dropdown is toggled.
	 * @param toggle Toggled value true/false.
	 */
	toggleBranchDropdown(toggle: boolean) {
		this.isShowingBranchesDropdown = toggle;
		if (toggle) {
			this.isShowingUserDropdown = !toggle;
		}
	}

	/**
	 * Invoked when user selection dropdown is toggled.
	 * @param toggle Toggled value true/false.
	 */
	toggleUserDropdown(toggle: boolean) {
		this.isShowingUserDropdown = toggle;
		if (toggle) {
			this.isShowingBranchesDropdown = !toggle;
		}
	}

	/**
	 * Clear selected filter and refresh alarm history list
	 */
	clearFilters() {
		this.initFiltersWithDefaultValue();
		this.analyticalDashboardService.clearFilter(false);
		this.filterData();
	}

	initFiltersWithDefaultValue() {
		this.initFilterTimeRange();
		// this.endDate = new Date((new Date().getTime()));
		// this.startDate = new Date(this.endDate - this.appConfigService.appConfig.FILTER_START_DATE_INTERVAL);
		this.selectedBranches = [];
		this.selectedUsers = [];
		this.selectedAlarmTypes = [];
		this.shortAlarmIdText = '';
		this.alarmInfoText = '';
	}

	socketListener() {
		this.socketService.initSocket();
		const socketId = localStorage.getItem('socketId');
		if (!socketId) {
			return;
		}
		const eventNotification = this.socketService.onEvent<any>(socketId);
		if (eventNotification) {
			eventNotification.subscribe((socketEvent) => {
				const data = socketEvent.data;
				switch (socketEvent.type) {
					case WEB_SOCKET_EVENTS.UPDATE_MAP:
						this.updateMap();
						break;
					case WEB_SOCKET_EVENTS.LOG_OUT:
						this.Logout(data);
						break;
					default:
				}
			});
		}
	}

	Logout(data: any) {
		let currentToken = this.authenticationService.token;
		if (currentToken === data.session_token) {
			this.dialog.closeAll();
			this.router.navigate([RouteConstants.PAGES + RouteConstants.LOGIN]);
		}
	}

	async updateMap() {
		await this.branchService.getMapBranches2();
	}

}
