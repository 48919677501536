import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../constants/route-constants';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstants } from '../constants/app-constants';
import { AppConfigService } from 'src/app/app-config.service';
import { DateUtil } from '../util/date-util';
import { AnalyticalDashboardService } from '../services/analytical-dashboard.service';
import { AlarmService } from '../services/alarm.service';
import { VideoStreamService } from '../services/video-stream.service';
import { Utility } from '../util/app-utils';
import { UserSessionStateService } from '../services/user-session-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from '../services/chat.service';
import { Subscription } from 'rxjs';
import { AppLocalStorage } from 'src/app/util/app-local-storage-utils';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	/**
	 * Input  of header component
	 * session contains user's and  client's info.
	 */
	@Input() session: any;
	switchToggle: boolean = false;
	logoUrl: string;
	user: any;
	currentTime: string;
	currentDate: string;
	trialPeriodRemaining: any;
	trialEndTime: any;
	setTrialEndTime = false;
	errorMessage: any;
	sessionStartTime: number;
	sessionInterval = 60000;
	sessionValidityCheckTimer: any = null;
	isShowingUserDropdown: boolean = false;
	// dateFormat = AppConstants.USER_DATE_FORMAT;
	// timeFormat = AppConstants.USER_TIME_FORMAT;
	APP_CONFIG = this.appConfigService.appConfig;
	isSupervisor = false;
	getConfidenceMonitoringSubscriber: any;
	isOperator = false;
	isAdmin = false;
	utility = Utility;
	arabic: boolean = false;

	notificationsCounterSubscriber: Subscription;
	roomId: string;
	systemUsers: Array<any> = [];
	otherUsers: Array<any> = [];
	userMap = new Map();
	isShowNotificatiins: boolean = false;
	isShowChatUser: boolean = false;

	/**
	 * Creates an instance of header component.
	 * @param router navigation between the components.
	 * @param authenticationService provide user authentication utilities.
	 */
	constructor(
		public alarmService: AlarmService,
		private router: Router,
		private authenticationService: AuthenticationService,
		public analyticalDashboardService: AnalyticalDashboardService,
		private appConfigService: AppConfigService,
		public translate: TranslateService,
		private userSessionStateService: UserSessionStateService,
		private videoStreamService: VideoStreamService,
		public chatService: ChatService
	) {
	}

	async ngOnInit() {
		this.isSupervisor = this.authenticationService.hasRole(AppConstants.Supervisor);
		this.isOperator = this.authenticationService.hasRole(AppConstants.Operator);
		this.isAdmin = this.authenticationService.hasRole(AppConstants.Admin);
		this.logoUrl = this.APP_CONFIG.API_SERVER + this.session.activeProfile.client.logUrl;

		// Date/Time calculation supporting multi-languages & calenders.
		const language = AppLocalStorage.getLanguage();
		const dateOptions: Intl.DateTimeFormatOptions = {
			weekday: language === 'ru' ? 'long' : 'short',
			month: 'long',
			day: 'numeric',
			timeZone: this.session.timeZone,
		};

		const timeOptions: Intl.DateTimeFormatOptions = {
			hour: '2-digit',
			minute: 'numeric',
			hour12: language === 'ru' ? false : true,
			timeZone: this.session.timeZone,
		};

		const dateFormat = new Intl.DateTimeFormat(language, dateOptions);
		const timeFormat = new Intl.DateTimeFormat(language, timeOptions);

		this.currentDate = dateFormat.format(new Date());
		this.currentTime = timeFormat.format(new Date());

		setInterval(() => {
			this.currentDate = dateFormat.format(new Date());
			this.currentTime = timeFormat.format(new Date());
			if (!this.setTrialEndTime) {
				if (this.session.activeProfile.client.trialPeriodRemaining) {
					this.trialEndTime = new Date();
					this.trialEndTime = new Date(this.trialEndTime.getTime() + this.session.activeProfile.client.trialPeriodRemaining);
				}
				this.setTrialEndTime = true;
			}
			if (this.trialEndTime) {
				this.trialPeriodRemaining = DateUtil.getTrialPeriodRemaining(Math.abs(this.trialEndTime.getTime() - new Date().getTime()));
				if (this.trialPeriodRemaining.days === 0 && this.trialPeriodRemaining.hours === 0 && this.trialPeriodRemaining.minutes === 0 && this.trialPeriodRemaining.sec === 0) {
					this.authenticationService.logout();
				}
			}
		}, AppConstants.APP_CONFIG_INIT_TIMER);

		this.getConfidenceMonitoringSubscriber = this.userSessionStateService.getConfidenceMonitorigChangeNotification().subscribe((state) => {
			this.switchToggle = state;
		});

		await this.getUsers();
		await this.getNotifications();
		await this.getAllNotifications();
		await this.notificationsListner();

		// ReOpen opendChatRooms on page reload/refresh
		let opendChatRooms = JSON.parse(localStorage.getItem('opendChatRooms') || '[]');
		opendChatRooms.forEach((room: { roomId: string, title: string }) => {
			let data = { _id: room.roomId, title: room.title };
			this.chatService.openChatWindow(data);
		});
		await this.authenticationService.getSystemInfo();
	}

	ngOnDestroy() {
		Utility.Unsubscribe(this.getConfidenceMonitoringSubscriber);
		Utility.Unsubscribe(this.notificationsCounterSubscriber);
		clearInterval(this.sessionValidityCheckTimer);
	}

	/**
	 * Redirects user to dashboard page.
	 */
	goToDashBoard() {
		this.isShowingUserDropdown = false;
		this.isShowNotificatiins = false;
		this.isShowChatUser = false;
		this.router.navigate([RouteConstants.PAGES + RouteConstants.DASHBOARD]);
	}

	/**
	 * Redirects user to report page.
	 */
	goToReportsPage() {
		this.isShowingUserDropdown = false;
		this.isShowNotificatiins = false;
		this.isShowChatUser = false;
		this.router.navigate([RouteConstants.PAGES + RouteConstants.REPORTS]);
	}

	/**
	 * Redirects user to analytical dashboard page.
	 */
	goTOAnalyticalDashboard() {
		this.analyticalDashboardService.chartSelected = 1;
		this.isShowingUserDropdown = false;
		this.isShowNotificatiins = false;
		this.isShowChatUser = false;
		this.router.navigate([RouteConstants.PAGES + RouteConstants.ANALYTICAL_DASHBOARD]);
	}

	/**
	 * End session.
	 * On successful session logout navigates to Login page.
	 */
	logout() {
		this.isShowingUserDropdown = false;
		this.isShowChatUser = false;
		this.authenticationService.logout();
	}

	onChange() {
		this.switchToggle = !this.switchToggle;
		this.userSessionStateService.modifyConfidenceMonitoringState(this.switchToggle);
		if (this.switchToggle === false) {
			this.videoStreamService.setEmptyVideoSources();
		}
		this.isShowingUserDropdown = false;
		this.isShowNotificatiins = false;
		this.isShowChatUser = false;
	}

	async openChat_fromNotifcation(notification: any) {
		await this.chatService.openChatWindow(notification);
	}

	async getUsers() {
		this.systemUsers = await this.authenticationService.getSystemUsers();
		this.systemUsers.forEach((user) => {
			this.userMap.set(user._id, { role: user.role[0], name: `${user.firstName} ${user.lastName}` });
		});

		let CurrentUser = this.authenticationService.getSession();
		let chatUsers = this.systemUsers.filter((user) => user._id !== CurrentUser.userId);

		// Sort chatUsers by rolePosition
		chatUsers.sort((a, b) => a.rolePosition - b.rolePosition);

		let otherUsers = chatUsers.map((user) => ({
			name: `${user.firstName} ${user.lastName}`,
			role: user.role[0],
			id: user._id
		}));

		this.otherUsers = this.groupUsersByRole(otherUsers);
	}

	groupUsersByRole(users: any[]) {
		const grouped = users.reduce((acc, user) => {
			if (!acc[user.role]) {
				acc[user.role] = [];
			}
			acc[user.role].push(user);
			return acc;
		}, {});

		// Sort each group alphabetically in a case-insensitive manner
		for (const role in grouped) {
			grouped[role].sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
		};

		return grouped;
	}

	objectKeys(obj: any) {
		return Object.keys(obj);
	}

	async startUserChat(user: any) {
		user.title = user.role + ': ' + user.name;
		this.chatService.getDirectChat(user.id).subscribe(async (room: any) => {
			if (room) {
				room.title = user.title;
				await this.chatService.openChatWindow(room);
			} else {
				let room = await this.chatService.createPrivateRoom(user);
				await this.chatService.openChatWindow(room);
			}
		}
		);
	}

	async getAllNotifications() {
		let res = await this.chatService.getAllGroupedNotifications();
		if (res && res.success === true && res.data && res.data.notifications.length > 0) {
			let allNotifications = res.data.notifications.map((notification: any) => {
				const representativeUserId = notification.users[0];
				const user = this.userMap.get(representativeUserId);
				return {
					...notification,
					title: notification.roomPurposeId ? notification.roomTitle : `${user.role}: ${user.name}`,
					count: notification.users.length
				};
			});
			allNotifications.forEach(notification => {
				this.chatService.groupedNotificationsCount[notification._id] = notification.count;
			});
		}
	}

	async getNotifications() {
		let res = await this.chatService.getGroupedNotifications();
		if (res && res.success === true && res.data && res.data.notifications.length > 0) {
			this.chatService.notifications = res.data.notifications.map((notification: any) => {
				const representativeUserId = notification.users[0];
				const user = this.userMap.get(representativeUserId);
				return {
					...notification,
					title: notification.roomPurposeId ? notification.roomTitle : `${user.role}: ${user.name}`,
					count: notification.users.length
				};
			});
			this.chatService.notificationsCount = res.data.count;
		} else {
			this.chatService.notificationsCount = 0;
		};

		this.chatService.updateNotificationsDisplayCount();
	}

	async notificationsListner() {
		this.notificationsCounterSubscriber = this.alarmService.notificationListner().subscribe(async (data) => {
			const newNotification = data.notification;
			const representativeUserId = newNotification.details.fromUserId;
			const roomId = newNotification.details.roomId;

			// If this chat room is already open, mark the notification as seen.
			let opendChatRooms = JSON.parse(localStorage.getItem('opendChatRooms') || '[]');
			const roomExists = opendChatRooms.some((room: any) => room.roomId === roomId);
			if (roomExists) {
				const fakeNotification = { _id: roomId };
				await this.chatService.set_RoomNotifications_seen(fakeNotification._id);
				return;
			}

			// To store groupedNotificationsCount for each notification
			if (this.chatService.groupedNotificationsCount[roomId]) {
				this.chatService.groupedNotificationsCount[roomId]++;
			} else {
				this.chatService.groupedNotificationsCount[roomId] = 1;
			}

			const user = this.userMap.get(representativeUserId);

			const existingNotification = this.chatService.notifications.find(n => n._id === roomId);
			if (existingNotification) {
				// Assumes that the existing notification has a `users` array
				existingNotification.users.push(representativeUserId);
				// existingNotification.count = existingNotification.users.length;
				existingNotification.count = this.chatService.groupedNotificationsCount[roomId];
				if (existingNotification.originalIds) {
					existingNotification.originalIds.push(newNotification._id);
				} else {
					existingNotification.originalIds = [newNotification._id];
				}
				// Remove the existing notification from its current position
				this.chatService.notifications = this.chatService.notifications.filter(n => n._id !== roomId);
				// Unshift the updated notification to the beginning/top of the array
				this.chatService.notifications.unshift(existingNotification);
			} else {
				const groupedNotification = {
					_id: roomId,
					users: [representativeUserId],
					roomPurposeId: newNotification.details.roomPurposeId ? newNotification.details.roomPurposeId : null,
					title: newNotification.details.roomPurposeId ? newNotification.details.roomTitle : `${user.role}: ${user.name}`,
					count: this.chatService.groupedNotificationsCount[roomId],
					originalIds: [newNotification._id]
				};
				this.chatService.notifications.unshift(groupedNotification);
				this.chatService.notificationsCount += 1;
				let audio = new Audio('../assets/audio/chat.mp3');
				audio.play();
			}
			const limit = this.appConfigService.appConfig.NOTIFICATIONS_COUNT_LIMIT;
			while (this.chatService.notifications.length > limit) {
				this.chatService.notifications.pop(); // Remove the oldest notification
			}
			// Update the display count as well
			this.chatService.updateNotificationsDisplayCount();
		});
	}

	showUserDropDown() {
		this.isShowNotificatiins = false;
		this.isShowChatUser = false;
		this.isShowingUserDropdown = !this.isShowingUserDropdown;
	}

	checkNotifications() {
		this.isShowChatUser = false;
		this.isShowingUserDropdown = false
		this.isShowNotificatiins = !this.isShowNotificatiins;
	}

	showChatUsers() {
		this.isShowNotificatiins = false;
		this.isShowChatUser = !this.isShowChatUser;
	}

	getTextToShow(text: string) {
		text = text.trim();
		return text.length > 31 ? text.substring(0, 30) + '...' : text;
	}

	async selectNotification(notification: any) {
		await this.openChat_fromNotifcation(notification);
		await this.getNotifications();
	}

	// UI Language Toggle functions:
	// switchToArabic(){
	// 	this.translate.use('ar');
	// 	this.arabic = true;
	// }

	// switchToEnglish(){
	// 	this.translate.use('en');
	// 	this.arabic = false;
	// }

	// useLanguage(language: string): void {
	// 	this.translate.use(language);
	// }

}
