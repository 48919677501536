<div class="map-wrapper">
	<div class="map-container"
		id="map-container"
		*ngIf="options"
		leaflet
		[leafletOptions]="options"
		[leafletLayers]="markers"
		[(leafletCenter)]="center"
		[(leafletZoom)]="zoom"
		(leafletMapReady)="onMapReady($event)">
	</div>

	<div class="search-input-text"
		*ngIf="shouldShowSearchInput && shouldMakeSearchVisible">
		<div class="row">
			<div class="search-bar">
				<mat-form-field class="search-mat-form-field">
					<input type="text"
						matInput
						[matAutocomplete]="auto"
						(keyup)="searchBranch()"
						class="search-input"
						[(ngModel)]="searchText">
					<button (click)="searchBranch()"
						class="search-button">
						<img [src]="utility.getImagePath('search.png')" alt="search"></button>
				</mat-form-field>
				<mat-autocomplete #auto="matAutocomplete">
					<mat-option *ngFor="let branch of branchSearchResult"
						[value]="branch"
						(click)="zoomToBranch(branch)">{{branch.branchName}}</mat-option>
				</mat-autocomplete>
			</div>
		</div>
	</div>

	<div class="toggleMap" *ngIf="containZone && !isAnalytical">
		<button class="btn toggleMapButton" (click)="toggleMap()">
			<span class="toggleMapText">{{'MAP.DISPLAY_ZONE' | translate}}</span>
			<!-- <img [src]="utility.getImagePath('zone.png')" alt="zone" (click)="toggleMap()"> -->
		</button>
	</div>
	
</div>