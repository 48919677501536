<mat-toolbar class='app-header'>
    <div class='app-logo col-4'>
        <img [src]="utility.getImagePath('RAMsysLogo.png')" alt="app-logo" class="app-logo-image"
            (click)="goToDashBoard()">
    </div>
    <div class='client-logo col-4'><img class="client-logo-img" alt="client-logo" [src]="logoUrl">
    </div>

    <div class='user-info col-4'>
        <div *ngIf="trialPeriodRemaining && trialPeriodRemaining.days < 30 " class='trial-period'>
            <span>
                <span class="evaluation-text">{{ "HEADER.EVALUATION_LICENSE_TEXT" | translate }}</span> {{
                "HEADER.TRIAL_DAYS_REMAINING_TEXT" | translate }} {{trialPeriodRemaining.days}}
            </span>
        </div>
        <div class='data-time'>
            <span class="current-date">{{ currentDate }}</span>,
            <span class="current-time">{{ currentTime }}</span>
        </div>

        <!-- Start a user chat -->
        <div (clickOutside)="isShowChatUser=false" appClickOutside>
            <i class="fa fa-commenting mr-3" aria-hidden="true" (click)="showChatUsers()">
            </i>
            <div class="chat-users row" *ngIf="isShowChatUser">
                <ul *ngFor="let role of objectKeys(otherUsers)">
                    <li>
                        <div class="user-role">{{ role }}</div>
                        <div class="hoverable-action chat-users-drop-down" *ngFor='let user of otherUsers[role]'
                            [matTooltip]="user.name" (click)="startUserChat(user)">
                            {{ getTextToShow(user.name) }}</div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Notifications -->
        <div>
            <i class="fa fa-bell mr-3" matBadge="{{ chatService.notificationsCountLimit }}" matBadgeColor="warn"
                aria-hidden="true" matBadgeSize="medium" matBadgeOverlap="true" matBadgePosition="after"
                [matBadgeHidden]="chatService.notificationsCount === 0" [matMenuTriggerFor]="menu" aria-label="menu" (click)="checkNotifications()">
            </i>
            <mat-menu #menu="matMenu" class="menu">
                <button mat-menu-item class="menu-item" *ngIf="chatService.notifications.length === 0">
                    <div class="noMessages">{{ "HEADER.NO_MESSAGES" | translate }}</div>
                </button>
                <button mat-menu-item class="menu-item" *ngFor='let notification of chatService.notifications'
                    (click)="selectNotification(notification)">
                    <span class="countBadge">{{ notification.count }}</span>
                    {{ "HEADER.MESSAGES" | translate }}
                    <ng-container *ngIf="notification.roomPurposeId; else elseBlock">
                        {{ "HEADER.RELATED_TO" | translate }}
                    </ng-container>
                    <ng-template #elseBlock>
                        {{ "HEADER.FROM" | translate }}
                    </ng-template>
                    <span class="newNotification" [matTooltip]="notification.title">{{ notification.title }}
                    </span>
                </button>
            </mat-menu>
        </div>

        <div (clickOutside)="isShowingUserDropdown=false" appClickOutside>
            <div>
                <div class="user-image-icon" [class.active-dropdown]="isShowingUserDropdown"
                    (click)="showUserDropDown()">
                    <img [src]="utility.getImagePath('profile.png')" alt="profile" class="profile-image">
                </div>
            </div>
            <div class="user-dropdown row" *ngIf="isShowingUserDropdown">
                <ul>
                    <li class="user-name-section">
                        <div class="user-full-name select-drop-down-div"
                            [matTooltip]="session?.firstName + ' ' + session?.lastName">
                            {{session?.firstName}} {{session?.lastName}}
                        </div>
                        <div *ngIf="isOperator" class="user-name select-drop-down-div">
                            {{ "HEADER.OPERATOR" | translate }}
                        </div>
                        <div *ngIf="isSupervisor" class="user-name select-drop-down-div">
                            {{ "HEADER.SUPERVISOR" | translate }}
                        </div>
                        <div *ngIf="!isOperator && !isSupervisor" class="user-name select-drop-down-div">
                            {{ "HEADER.ADMIN" | translate }}
                        </div>
                    </li>
                    <li class="component-section">
                        <div class="hoverable-action select-drop-down-div" (click)="goToDashBoard()">{{
                            "HEADER.DASHBOARD" | translate }}</div>
                        <div *ngIf="isOperator || isAdmin" class="hoverable-action li-values select-drop-down-div"
                            (click)="goToReportsPage()">{{ "HEADER.HISTORY" | translate }}</div>
                        <div *ngIf="isSupervisor"
                            class="hoverable-action li-values li-values-last  select-drop-down-div"
                            (click)="goTOAnalyticalDashboard()">{{ "HEADER.ANALYTICAL_DASHBOARD" | translate }}</div>
                        <div *ngIf="isOperator" class="li-values li-values-last  select-drop-down-div">
                            <span class="hoverable-action" (click)="onChange()">{{ "HEADER.CONFIDENCE_MONITORING" |
                                translate }}
                            </span>
                            <mat-slide-toggle class="switch" color="primary" [checked]="switchToggle"
                                [disabled]="disabled" (change)="onChange()">
                            </mat-slide-toggle>
                        </div>
                        <!-- <div class="hoverable-action select-drop-down-div" *ngIf="!arabic" (click)="switchToArabic()">Arabic</div> -->
                        <!-- <div class="hoverable-action select-drop-down-div" *ngIf="arabic" (click)="switchToEnglish()">English</div> -->
                    </li>
                    <li class="hoverable-action logout-action" (click)="logout()">{{ "HEADER.SIGN_OUT" | translate }}
                    </li>

                </ul>
            </div>
        </div>
    </div>
</mat-toolbar>