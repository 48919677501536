import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { AppConstants } from 'src/app/constants/app-constants';
import { Utility } from 'src/app/util/app-utils';

@Component({
	selector: 'app-sites-stats',
	templateUrl: './sites-stats.component.html',
	styleUrls: ['./sites-stats.component.scss']
})
export class SitesStatsComponent implements OnInit {
	filterType = 'SITES';
	public selectedBranch;
	public selectedSitesAsset = this.analyticalDashboard.sitesAsset.MAP;
	checkbox: any;
	appConstants = AppConstants;
	displayedColumns = [
		'Sites',
		'Region',
		'Site Priority',
		'Operator',
		'Status',
		'Asset'
	];
	branchChangeSubscriber: any;
	utility = Utility;

	constructor(public analyticalDashboard: AnalyticalDashboardService) { }

	ngOnInit() { 
		this.changeSitesAsset(2)
	}

	ngDestroy() { 
		this.changeSitesAsset(2)
	}

	mapLoaded() {
		this.analyticalDashboard.selectAlarm(this.selectedBranch);
	}

	/**
	 * called when want to filter sites list
	 */
	changeTableSize() {
		this.analyticalDashboard.changeTableSizeOnAddedFilter(AppConstants.SITES_TABLE_CONTAINER);
	}

	/**
	 * called to change sites asset
	 * To select map, video or asset docs
	 */
	changeSitesAsset(selectedAssetType) {
		this.analyticalDashboard.selectSitesAssetDocs(selectedAssetType);
		this.analyticalDashboard.selectedSitesAsset = selectedAssetType;
	}

	changeSelectedBranch(branch) {
		this.selectedBranch = branch;
	}
}
