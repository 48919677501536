<!-- For Multi select Option -->
<div class='dropdown-wrapper' *ngIf="!isSingleSelect">
	<div class="row">
		<div class="col">
			<div class="dropdown">
				<input [id]="uniqueSelectId" class="dropcheck" type="checkbox" [checked]="showDropDown"
					(click)="onDropdownToggled()">
				<label [for]="uniqueSelectId" class="dropbtn" [class.show-border]="showDropDown">
					<span class="d-flex justify-content-between align-items-center">
						<span class="placeholder-text">
							<span *ngIf="previousSelectedValuesPlaceholder===''">{{placeholder}}</span>
							<span>{{previousSelectedValuesPlaceholder}}</span>
						</span>
						<span>
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</span>
				</label>
				<div class="dropdown-content" *ngIf="showDropDown">
					<!-- Search input field -->
					<input type="text" class="search-input" placeholder="{{ 'FILTER_DATA.SEARCH' | translate }}"
						[(ngModel)]="searchValue" (input)="onSearchInputChange()">
					<section>
						<div class="main-block">
							<div *ngFor="let item of data">
								<div
									class="custom-control custom-checkbox mb-2 mt-2 ml-1 mr-1 p-2  {{item['disabled']  ? 'no-drop-cursor' : null}}">
									<input class="custom-control-input" type="checkbox" value="{{item.value}}" 
									id="{{item.value}}" [attr.disabled]="item['disabled']  ? 'disabled' : null" 
									[(ngModel)]="item.isChecked" (change)="onItemSelectionChange(item)">
									<label class="custom-control-label" [class.selected-value]="item.isChecked==true"
										for="{{item.value}}">
										{{item.title}}
									</label>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-between actions" *ngIf="data && data.length>0">
							<div>
								<button mat-button (click)="clearSelections()">{{ "FILTER_DATA.CLEAR" | translate }}</button>
							</div>
							<div>
								<button mat-button class="save-button" (click)="saveData()">
									{{ "FILTER_DATA.SAVE" | translate }}</button>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- For Single select Option -->
<div class='dropdown-wrapper' *ngIf="isSingleSelect">
	<div class="row">
		<div class="col">
			<div class="dropdown">
				<input [id]="uniqueSelectId" class="dropcheck" type="checkbox" [checked]="showDropDown"
					(click)="onDropdownToggled()">
				<label [for]="uniqueSelectId" class="dropbtn" [class.show-border]="showDropDown">
					<div class="d-flex justify-content-between align-items-center">
						<div class="placeholder-text">
							<span *ngIf="previousSelectedValuesPlaceholder===''">{{placeholder}}</span>
							<span>{{previousSelectedValuesPlaceholder}}</span>
						</div>
						<div>
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</div>
					</div>
				</label>
				<div class="dropdown-content" *ngIf="showDropDown">
					<section>
						<div class="main-block">
							<div *ngFor="let item of data">
								<div
									class="custom-control custom-checkbox mb-2 mt-2 ml-1 mr-1 p-2 {{item['disabled']  ? 'no-drop-cursor' : null}}">
									<input class="custom-control-input" type="checkbox" value="{{item.value}}" 
									id="{{item.value}}" [attr.disabled]="item['disabled']  ? 'disabled' : null" 
									[(ngModel)]="item.isChecked" [checked]="item.value === PreviousSelectedId" 
									(change)="onItemSelectionChange(item); checkSingleSelected(item)">
									<label class="custom-control-label" [class.selected-value]="item.value === PreviousSelectedId" for="{{item.value}}">
										{{item.title}}
									</label>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-between actions" *ngIf="data && data.length>0">
							<div>
								<button mat-button (click)="clearSelections()">{{ "FILTER_DATA.CLEAR" | translate }}</button>
							</div>
							<div>
								<button mat-button class="save-button" (click)="saveData()">
									{{ "FILTER_DATA.SAVE" | translate }}</button>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</div>