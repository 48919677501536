import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/models/api-response.model';
import { Action } from 'src/models/action.model';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { ApiConstants } from '../constants/api-constants';

@Injectable({
	providedIn: 'root'
})
export class WorkflowService {
	questions = [];
	questionIndex = 0;
	actionHistory: Action[];
	actionHistoryChangeListener = new Subject<Action[]>();
	alarmIdSubject = new Subject<any>();
	selectedAlarmId: any;
	socketType: any;
	session_token: any;
	newAction = new Subject<any>();

	constructor(private http: HttpClient, private authService: AuthenticationService) { }

	getManagers(branchId: string) {
		return lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_BRANCHES + '/' + branchId + ApiConstants.URL_MANAGERS));
	}

	getActionHistoryChangeListener() {
		return this.actionHistoryChangeListener.asObservable();
	}

	async fetchActionHistory(alarmId: string) {
		const response: ApiResponse = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS + '/' + alarmId + ApiConstants.URL_ACTIONS));
		if (response.success === true) {
			if (!response.data) {
				this.actionHistory = [];
			} else {
				this.actionHistory = response.data;
			}
		}
	}

	async addActionHistory(data: any) {
		if (Array.isArray(data)) {
			this.actionHistory = [...this.actionHistory, ...data]
		} else {
			this.actionHistory.push(data);
		}
		this.actionHistoryChangeListener.next(this.actionHistory);
		this.newAction.next(data.action);
	}

	async addWorkflowComment(alarmId: string, comment: string) {
		const data = {
			action: comment,
			step: comment,
			timeStamp: new Date().getTime(),
			user: {
				id: this.authService.currentUserSession.activeProfile.client._id,
				firstName: this.authService.currentUserSession.firstName,
				lastName: this.authService.currentUserSession.lastName,
				userName: this.authService.currentUserSession.role.name
			}
		};
		const response: ApiResponse = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_ALARMS + '/' + alarmId + ApiConstants.URL_COMMENT, { comment: comment }));
		if (response.success === true) {
			this.addActionHistory(data);
		}
	}

	///////////////////////////////////////////////////////////////////////////////////////////////////
	async sendMail(alarmId: string) {
		this.session_token = this.authService.getAuthToken();
		const url = ApiConstants.URL_SEND_MAIL;
		const body = { alarmId: alarmId };
		let res = await lastValueFrom(this.http.post<ApiResponse>(url, body,
			{ headers: new HttpHeaders().set('session_token', this.session_token) }));
		return res;
	}

	///////////////////////////////////////////////////////////////////////////////////////////////////
	getNewActionListener() {
		return this.newAction.asObservable();
	}

}


