import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, lastValueFrom } from 'rxjs';
import { ChartSelection } from 'src/models/analytical-chart.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from 'src/models/api-response.model';
import { ApiConstants } from '../constants/api-constants';
import { ReportService } from './report.service';
import { AppConstants } from '../constants/app-constants';
import { AppConfigService } from '../app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Utility } from '../util/app-utils';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../analytical-dashboard/confirm-dialog/confirm-dialog.component';
import { FilterData } from 'src/models/filter-data.model';
import { SocketService } from './socket.service';
import { AlarmService } from './alarm.service';

@Injectable({
	providedIn: 'root'
})
export class AnalyticalDashboardService {
	/**
	 * Selected sites asset and there types
	 */
	sitesAsset = {
		VIDEO: 1,
		MAP: 2,
		ASSET_DOCS: 3,
		ADD_ALARM: 4
	};
	selectedSitesAsset = this.sitesAsset.MAP;
	/**
	 * Chart types shown on the dashboard
	 */
	chartType = {
		ALARM: 1,
		OPERATOR: 2,
		SITES: 3
	};
	chartSelected: any;
	alarmsDataChangeNotification = new Subject<boolean>();
	alarmsHistoryChangeNotification = new Subject<boolean>();
	operatorsStatsChangeNotification = new Subject<boolean>();
	updateNotification = new Subject<boolean>();
	clearAllFilterNotification = new Subject<boolean>();
	branchChangeNotification = new Subject<boolean>();
	SelectedChartNotification = new BehaviorSubject<any>(1);
	alarmsStats: {
		alarmTypes: any;
		alarmStatuses: any;
	};
	branchParams: any = {};
	operatorsStats: any;
	operatorsReport: any;
	sitesStats: any;
	filterParams: any = {
		details: AppConstants.SUPERVISOR_DETAILS,
	};
	operatorsFilterParams: any = {};
	alarmsHistory: any;
	branchDataSource: any;
	selectedOperatorsId = null;
	public onAlarmSelectedNotification = new Subject<any>();
	selectedAlarms = [];
	public onSitesAssetChangeNotification = new Subject<any>();
	isFilterDataLoaded = false;
	filterData: FilterData;
	lastUpdatedTime: Date;
	alarmCount;
	SelectedSeriesForChartOne: any = [];
	SelectedSeriesForChartTwo: any = [];
	SelectedStatusesForTile: any = [];

	initFilter: boolean = false;
	private initFilterChangeNotification = new BehaviorSubject<boolean>(false);

	constructor(
		private http: HttpClient,
		public reportService: ReportService,
		public appConfigService: AppConfigService,
		public translate: TranslateService,
		public dialog: MatDialog,
		private socketService: SocketService,
		private alarmService: AlarmService
	) { }

	/**
	 * get all alarms statistics
	 */
	async fetchAlarmsStatistics(filter?: any) {
		const date = new Date();
		const startDate = new Date(date.getTime() - this.appConfigService.appConfig.FILTER_START_DATE_INTERVAL).getTime();
		const endDate = date.getTime();
		const response: ApiResponse = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARM_STATISTIC + '/' + startDate + '/' + endDate + '?filters=[alarmType,alarmStatus]'));
		if (response.success === true) {
			this.alarmsStats = response.data;
			this.alarmsStats.alarmTypes = this.formatChartData(this.alarmsStats.alarmTypes);
			this.alarmsDataChangeNotification.next(true);
			this.updateNotification.next(true);
		}
	}

	/**
	 * Draw Chart according to filtered data
	 */
	async fetchAlarmsStatisticsChart(alarms?: any, pagination?: any) {
		const response: ApiResponse = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_ALARM_STATISTIC_CHART, { alarms: alarms }));
		this.alarmsStats = response.data;
		this.alarmsStats.alarmTypes = this.formatChartData(this.alarmsStats.alarmTypes);
		if (!pagination) {
			this.alarmsDataChangeNotification.next(true);
		}
	}

	/**
	 * get all operator statistics
	 */
	async fetchOperatorsStatistics(filter?: any) {
		const response: ApiResponse = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_OPERATORS_STATISTIC + '?filters=["region","operatorStatus"]'));
		if (response.success === true) {
			this.operatorsStats = response.data;
			this.operatorsStats.regions = this.formatChartData(this.operatorsStats.regions);
			this.operatorsStatsChangeNotification.next(true);
			this.updateNotification.next(true);
		}
	}

	/**
	 * get all sites statistics
	 */
	async fetchSitesStatistics(filter?: any) {
		const url = ApiConstants.URL_SITES_STATISTIC;
		const response: ApiResponse = await lastValueFrom(this.http.get<ApiResponse>(url));
		if (response.success) {
			this.sitesStats = response.data;
			this.sitesStats.regions = this.formatChartData(this.sitesStats.regions);
			// site sequence of sites priority
			this.sitesStats.branchTypes = this.mapSitesPriorityType(this.sitesStats.branchTypes);
			this.sitesStats.branchTypes = this.formatChartData(this.sitesStats.branchTypes);
			this.branchChangeNotification.next(true);
			this.updateNotification.next(true);
		}
	}

	/**
	 * maps sites priority data in sequence
	 */
	mapSitesPriorityType(branchTypes: any) {
		const sequences = [this.translate.instant('BRANCH_PRIORITY.CRITICAL'), this.translate.instant('BRANCH_PRIORITY.HIGH'), this.translate.instant('BRANCH_PRIORITY.NORMAL'), this.translate.instant('BRANCH_PRIORITY.LOW')];
		const tempBranchType = [];
		sequences.forEach(sequence => {
			branchTypes.forEach((branchType: any) => {
				if (sequence === branchType.name) {
					tempBranchType.push(branchType);
				}
			});
		});
		return tempBranchType;
	}

	/**
	 * send notification to all subscribed component when there is change in alarms data
	 */
	getAlarmsNotification() {
		return this.alarmsDataChangeNotification.asObservable();
	}
	/**
	* send notification to all subscribed component when there is change in operators stats
	*/
	getOperatorsStatsChangeNotification() {
		return this.operatorsStatsChangeNotification.asObservable();
	}

	getUpdateNotification() {
		return this.updateNotification.asObservable();
	}

	/**
	 * called to get alarms types status
	 */
	getAlarmsTypesStats() {
		return this.alarmsStats.alarmTypes;
	}

	/**
	 * called to get alarms status stats
	 */
	getAlarmStatusStats() {
		return this.alarmsStats.alarmStatuses;
	}

	/**
	 * called to get operators regions stats
	 */
	getOperatorsRegionsStats() {
		return this.operatorsStats.regions;
	}

	/**
	 * called to get sites regions stats
	 */
	getSitesRegionsStats() {
		return this.sitesStats.regions;
	}

	/**
	 * called to get sites branch type stats
	 */
	getSitesBranchTypesStats() {
		return this.sitesStats.branchTypes;
	}

	/**
	 * filter operator table
	 */
	async filterOperatorReport(responseFormat: string, statuses?: any, regions?: any) {
		this.operatorsFilterParams.responseFormat = responseFormat;
		if (statuses) {
			this.operatorsFilterParams.statuses = [];
			this.operatorsFilterParams.statuses.push(statuses);
		}
		if (regions) {
			this.operatorsFilterParams.regions = [];
			this.operatorsFilterParams.regions.push(regions);
		}
		const response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_OPERATORS_REPORT, this.operatorsFilterParams));
		if (response.success === true) {
			this.operatorsReport = response.data;
			this.operatorsStatsChangeNotification.next(false);
			return response.data;
		}
	}

	/**
	 * get alarms history data by applying filter
	 */
	async getAlarmsHistoryStats(responseFormat: string, alarmsStatuses: any, alarmTypes: any, areaNames?: any, useFilters = true) {
		this.filterParams.responseFormat = responseFormat;
		if (useFilters) {
			if (alarmsStatuses) {
				this.filterParams.alarmStatuses = [];
				this.filterParams.alarmStatuses.push(alarmsStatuses);
			}
			if (alarmTypes) {
				this.filterParams.alarmTypes = [];
				this.filterParams.alarmTypes.push(alarmTypes);
			}
			if (areaNames) {
				this.filterParams.areaNames = [];
				this.filterParams.areaNames.push(areaNames);
			}
			const response = await lastValueFrom(this.http.post<any>(ApiConstants.URL_ALARMS_HISTORY_REPORT, this.filterParams));
			if (response.success === true) {
				this.alarmCount = response.totalcount;
				this.alarmsHistory = response.data;
				this.alarmsHistoryChangeNotification.next(true);
				return response.data;
			}
		} else {
			const date = new Date();
			this.filterParams = {
				details: AppConstants.SUPERVISOR_DETAILS,
				startDate: new Date(date.getTime() - this.appConfigService.appConfig.FILTER_START_DATE_INTERVAL).getTime(),
				endDate: date.getTime(),
				start: AppConstants.PAGE_START_CONSTANT,
				pageSize: AppConstants.DEFAULT_PAGE_SIZE
			};
			const response: any = await lastValueFrom(this.http.post<any>(ApiConstants.URL_ALARMS_HISTORY_REPORT,
				{ ...this.filterParams }));
			if (response.success === true) {
				this.alarmCount = response.totalcount;
				this.alarmsHistory = response.data;
				this.alarmsHistoryChangeNotification.next(true);
				return response.data;
			}
		}
	}

	getAlarmHistoryFilterOptions() {
		return this.filterParams;
	}

	/**
	 * Download alarms history PDF
	 */
	async getAlarmsHistoryStatsPDF(filterOption: any) {
		const componentRef = Utility.toggleLoadMask(false);
		filterOption = this.mergeAlarmFilterOptions(filterOption);
		await this.http.post<ApiResponse>(ApiConstants.URL_ALARMS_HISTORY_REPORT, { ...filterOption }, {
			responseType: ReportService.BLOB,
			headers: new HttpHeaders().append(AppConstants.CONTENT_TYPE_KEY, AppConstants.CONTENT_TYPE_VALUE)
		}).subscribe(response => {
			Utility.toggleLoadMask(componentRef);
			this.reportService.generatePdfReports(response, this.translate.instant('REPORTS.ALARMS_REPORT_NAME'));
		});
	}

	/**
	 * Download alarms history CSV
	 */
	async getAlarmsHistoryStatsCSV(filterOption: any) {
		const componentRef = Utility.toggleLoadMask(false);
		filterOption = this.mergeAlarmFilterOptions(filterOption);
		await this.http.post<ApiResponse>(ApiConstants.URL_ALARMS_HISTORY_REPORT, { ...filterOption }, {
			responseType: ReportService.BLOB,
			headers: new HttpHeaders().append(AppConstants.CONTENT_TYPE_KEY, AppConstants.CONTENT_TYPE_VALUE)
		}).subscribe(response => {
			Utility.toggleLoadMask(componentRef);
			this.reportService.generateCSVReports(response, this.translate.instant('REPORTS.ALARMS_REPORT_NAME'));
		});
	}


	/**
	 * Download operator history PDF
	 */
	async getOperatorsStatsPDF(filterOption: any) {
		const componentRef = Utility.toggleLoadMask(false);
		if (Utility.isNotEmpty(this.operatorsFilterParams.statuses)) {
			if (!filterOption.statuses) {
				filterOption.statuses = [];
			}
			filterOption.statuses = filterOption.statuses.concat(this.operatorsFilterParams.statuses);
		}
		if (Utility.isNotEmpty(this.operatorsFilterParams.regions)) {
			if (!filterOption.regions) {
				filterOption.regions = [];
			}
			filterOption.regions = filterOption.regions.concat(this.operatorsFilterParams.regions);
		}
		await this.http.post<ApiResponse>(ApiConstants.URL_OPERATORS_REPORT, { ...filterOption },
			{
				responseType: ReportService.BLOB,
				headers: new HttpHeaders().append(AppConstants.CONTENT_TYPE_KEY, AppConstants.CONTENT_TYPE_VALUE)
			}).subscribe(response => {
				Utility.toggleLoadMask(componentRef);
				this.reportService.generatePdfReports(response, this.translate.instant('REPORTS.OPERATORS_REPORT_NAME'));
			});
	}

	/**
	 * Download operator history CSV
	 */
	async getOperatorsStatsCSV(filterOption: any) {
		const componentRef = Utility.toggleLoadMask(false);
		if (Utility.isNotEmpty(this.operatorsFilterParams.statuses)) {
			if (!filterOption.statuses) {
				filterOption.statuses = [];
			}
			filterOption.statuses = filterOption.statuses.concat(this.operatorsFilterParams.statuses);
		}
		if (Utility.isNotEmpty(this.operatorsFilterParams.regions)) {
			if (!filterOption.regions) {
				filterOption.regions = [];
			}
			filterOption.regions = filterOption.regions.concat(this.operatorsFilterParams.regions);
		}
		await this.http.post<ApiResponse>(ApiConstants.URL_OPERATORS_REPORT, { ...filterOption },
			{
				responseType: ReportService.BLOB,
				headers: new HttpHeaders().append(AppConstants.CONTENT_TYPE_KEY, AppConstants.CONTENT_TYPE_VALUE)
			}).subscribe(response => {
				Utility.toggleLoadMask(componentRef);
				this.reportService.generateCSVReports(response, this.translate.instant('REPORTS.OPERATORS_REPORT_NAME'));
			});
	}
	/**
	 * Download sites stats PDF
	 */
	async getSitesStatsPDF(filterOption: any) {
		const componentRef = Utility.toggleLoadMask(false);
		if (Utility.isNotEmpty(this.branchParams.branchStatuses)) {
			if (!filterOption.branchStatuses) {
				filterOption.branchStatuses = [];
			}
			filterOption.branchStatuses = filterOption.branchStatuses.concat(this.branchParams.branchStatuses);
		}
		if (Utility.isNotEmpty(this.branchParams.areaNames)) {
			if (!filterOption.areaNames) {
				filterOption.areaNames = [];
			}
			filterOption.areaNames = filterOption.areaNames.concat(this.branchParams.areaNames);
		}
		if (Utility.isNotEmpty(this.branchParams.branchTypes)) {
			if (!filterOption.branchTypes) {
				filterOption.branchTypes = [];
			}
			filterOption.branchTypes = filterOption.branchTypes.concat(this.branchParams.branchTypes);
		}
		await this.http.post<ApiResponse>(ApiConstants.URL_BRANCH_STATUS_REPORT, { ...filterOption },
			{
				responseType: ReportService.BLOB,
				headers: new HttpHeaders().append(AppConstants.CONTENT_TYPE_KEY, AppConstants.CONTENT_TYPE_VALUE)
			}).subscribe(response => {
				Utility.toggleLoadMask(componentRef);
				this.reportService.generatePdfReports(response, this.translate.instant('REPORTS.SITES_REPORT_NAME'));
			});
	}

	/**
	 * Download sites stats csv
	 */
	async getSitesStatsCSV(filterOption: any) {
		const componentRef = Utility.toggleLoadMask(false);
		if (Utility.isNotEmpty(this.branchParams.branchStatuses)) {
			if (!filterOption.branchStatuses) {
				filterOption.branchStatuses = [];
			}
			filterOption.branchStatuses = filterOption.branchStatuses.concat(this.branchParams.branchStatuses);
		}
		if (Utility.isNotEmpty(this.branchParams.areaNames)) {
			if (!filterOption.areaNames) {
				filterOption.areaNames = [];
			}
			filterOption.areaNames = filterOption.areaNames.concat(this.branchParams.areaNames);
		}
		if (Utility.isNotEmpty(this.branchParams.branchTypes)) {
			if (!filterOption.branchTypes) {
				filterOption.branchTypes = [];
			}
			filterOption.branchTypes = filterOption.branchTypes.concat(this.branchParams.branchTypes);
		}
		await this.http.post<ApiResponse>(ApiConstants.URL_BRANCH_STATUS_REPORT, { ...filterOption },
			{
				responseType: ReportService.BLOB,
				headers: new HttpHeaders().append(AppConstants.CONTENT_TYPE_KEY, AppConstants.CONTENT_TYPE_VALUE)
			}).subscribe(response => {
				Utility.toggleLoadMask(componentRef);
				this.reportService.generateCSVReports(response, this.translate.instant('REPORTS.SITES_REPORT_NAME'));
			});
	}

	/**
	 * get sites table data
	 */
	async getBranchStatusReport() {
		let list = [];
		const response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_BRANCH_STATUS_REPORT, {}));
		if (response.success) {
			this.branchDataSource = response.data;
			list = response.data;
		}
		return list;
	}

	/**
	 * filter sites data table
	 */
	async BranchStatusReportFilter(branchStatuses?: any, areaNames?: any, branchTypes?: any) {
		if (branchStatuses) {
			this.branchParams.branchStatuses = [];
			this.branchParams.branchStatuses.push(branchStatuses);
		}
		if (areaNames) {
			this.branchParams.areaNames = [];
			this.branchParams.areaNames.push(areaNames);
		}
		if (branchTypes) {
			this.branchParams.branchTypes = [];
			this.branchParams.branchTypes.push(branchTypes);
		}
		const response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_BRANCH_STATUS_REPORT, this.branchParams));
		if (response.success) {
			this.branchDataSource = response.data;
		}
		this.branchChangeNotification.next(false);
	}

	/**
	 * send notification to all subscribed component when there is change in alarms history stats
	 */
	getAlarmsHistoryNotification() {
		return this.alarmsHistoryChangeNotification.asObservable();
	}

	/**
	 * called when want to clear all filters options
	 */
	clearFilter(clearAll: any) {
		this.clearAllFilterNotification.next(clearAll);
	}

	/**
	 * when clear filter is called this send a notification to all subscribed component
	 */
	getAllClearNotification() {
		return this.clearAllFilterNotification.asObservable();
	}

	/**
	 * send notification to all subscribed component when there is change in sites stats
	 */
	getBranchNotification() {
		return this.branchChangeNotification.asObservable();
	}

	/**
	 * called to change selected sites component
	 */
	selectChart(chart: ChartSelection) {
		this.chartSelected = chart.chartType;
		this.SelectedChartNotification.next(chart.chartType);
	}

	/**
	 * send notification to all subscribed component when chart is selected
	 */
	OnSelectedChartNotification() {
		return this.SelectedChartNotification.asObservable();
	}

	/**
	* called to get operator stats
	*/
	getOperatorsStats() {
		return this.operatorsStats;
	}

	/**
	 * called to get operator report
	 */
	getOperatorsReport() {
		return this.operatorsReport;
	}

	/**
	 * map chart data as required by highchart
	 */
	formatChartData(chartData: any) {
		chartData = JSON.parse(JSON.stringify(chartData).split('"percentage":').join('"y":'));
		chartData.map((data: any) => {
			if (data) {
				data.y = parseFloat(data.y);
			}
		});
		chartData = chartData.filter(item => item);
		// chartData = chartData.filter(item => (item.count > 0));
		return chartData;
	}

	/**
	 * get alarms status tile options
	 */
	getAlarmsStatusDetails() {
		return this.alarmsStats.alarmStatuses;
	}

	/**
	 * get operator status tile options
	 */
	getOperatorsSummary() {
		return this.operatorsStats.operatorStatuses;
	}

	/**
	 * get sites status tile options
	 */
	getBranchStatuses() {
		return this.sitesStats.branchStatuses;
	}

	/**
	 * called when alarms is selected to zoom on map
	 */
	selectAlarm(alarm: any) {
		this.onAlarmSelectedNotification.next(alarm);
	}

	/**
	 * send notification to all subscribed component when alarm is selected
	 */
	getOnAlarmSelectedNotification() {
		return this.onAlarmSelectedNotification.asObservable();
	}

	/**
	 * check operator status and show dialog box related to that status
	 */
	async isOperatorsOverloaded(operatorId: string) {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_OPERATORS_STATUS + '/' + operatorId));
		if (response.success === true) {
			if (response.data === AppConstants.OVERLOADED_TEXT) {
				// Show dialog box for overloaded operator
				const message = this.translate.instant('ANALYTICAL_ALARM_HISTORY.OPERATOR_ASSIGN_OVERLOADED_TEXT');
				const confirmText = this.translate.instant('ANALYTICAL_FILTERS.ASSIGN_ACTION');
				const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
				const typeOfDialogBox = 1;
				const dialogData = new ConfirmDialogModel(' ', message, confirmText, cancelText, typeOfDialogBox);
				const dialogRef = this.dialog.open(ConfirmDialogComponent, {
					data: dialogData
				});
				dialogRef.afterClosed().subscribe(dialogResult => {
					if (dialogResult) {
						this.actionOnAlarms(this.selectedAlarms, this.selectedOperatorsId, AppConstants.ACTION_ASSIGN);
					}
				});
			} else if (response.data === AppConstants.NOT_AT_DESK_TEXT) {
				// Show dialog box for Not at desk operator
				const message = this.translate.instant('ANALYTICAL_ALARM_HISTORY.OPERATOR_ASSIGN_NOT_AT_DESK_TEXT');
				const confirmText = this.translate.instant('ANALYTICAL_FILTERS.ASSIGN_ACTION');
				const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
				const title = this.translate.instant('ANALYTICAL_FILTERS.ERROR_TITLE');
				const typeOfDialogBox = 1;
				const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
				const dialogRef = this.dialog.open(ConfirmDialogComponent, {
					data: dialogData
				});
				dialogRef.afterClosed().subscribe(dialogResult => {
					if (dialogResult) {
						this.actionOnAlarms(this.selectedAlarms, this.selectedOperatorsId, AppConstants.ACTION_ASSIGN);
					}
				});
			} else {
				// Show dialog box for confirming that user want to assign alarm to operator
				const title = this.translate.instant('ANALYTICAL_FILTERS.ASSIGN_ACTION') + ' ' + this.translate.instant('ANALYTICAL_FILTERS.CHART_TITLE_ALARMS');
				const message = this.translate.instant('ANALYTICAL_FILTERS.ALARMS_ACTION_TEXT') + ' ' + this.translate.instant('ANALYTICAL_FILTERS.ASSIGN_MESSAGE') +
					' ' + this.translate.instant('ANALYTICAL_FILTERS.ACTION_TEXT');
				const confirmText = this.translate.instant('ANALYTICAL_FILTERS.ASSIGN_ACTION');
				const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
				const typeOfDialogBox = 1;
				const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
				const dialogRef = this.dialog.open(ConfirmDialogComponent, {
					data: dialogData
				});
				dialogRef.afterClosed().subscribe(dialogResult => {
					if (dialogResult) {
						this.actionOnAlarms(this.selectedAlarms, this.selectedOperatorsId, AppConstants.ACTION_ASSIGN);
					}
				});
			}
		}
	}

	/**
	 * Performs action on alarms list
	 */
	async actionOnAlarms(alarms: any, userId: any, status: string) {
		if (alarms.length > 0) {
			const userIds = [];
			const alarmIds = [];
			alarms.forEach((alarm) => {
				userIds.push(userId);
				alarmIds.push(alarm.alarmId);
			});
			const response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_ALARMS_ACTIONS, {
				alarmIds: alarmIds,
				userIds: userIds,
				status: status
			}));
			if (response.success === true) {
				this.getAlarmsHistoryStats(AppConstants.JSON_TEXT, false, false, false, false);
			}
		}
	}

	/**
	 * called when sites change between map, video or asset docs
	 */
	selectSitesAssetDocs(assetType: any) {
		this.onSitesAssetChangeNotification.next(assetType);
	}

	/**
	 * send notification to all subscribed component when change in sites asset
	 */
	getSitesSelectedAssetDocs() {
		return this.onSitesAssetChangeNotification.asObservable();
	}

	/**
	 * filter operator table from filters
	 */
	async filterOperatorsReportList(filterParams: any) {
		filterParams = this.mergeOperatorsFilters(filterParams);
		this.operatorsFilterParams = { ...filterParams };
		const response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_OPERATORS_REPORT, { ...filterParams }));
		if (response.success === true) {
			this.operatorsReport = response.data;
			this.operatorsStatsChangeNotification.next(false);
		}
	}

	mergeOperatorsFilters(filterParams: any) {
		if (Utility.isNotEmpty(this.SelectedStatusesForTile)) {
			filterParams.statuses = [...new Set((filterParams.statuses || []).concat(this.SelectedStatusesForTile))];
		}
		if (Utility.isNotEmpty(this.SelectedSeriesForChartOne)) {
			filterParams.regions = [...new Set((filterParams.regions || []).concat(this.SelectedSeriesForChartOne))];
		}
		return filterParams;
	}

	/**
	 * filter alarms table from filter
	 */
	async filterAlarmsHistoryList(filterParams: any, pagination?: boolean) {
		filterParams = this.mergeAlarmFilterOptions(filterParams);
		filterParams.pageSize = filterParams.pageSize;
		this.filterParams = { ...filterParams };
		const response = await lastValueFrom(this.http.post<any>(ApiConstants.URL_ALARMS_HISTORY_REPORT, filterParams));
		if (response.success === true) {
			this.alarmCount = response.totalcount;
			this.alarmsHistory = response.data;
			this.alarmsHistoryChangeNotification.next(true);
			// this.fetchAlarmsStatisticsChart(response.data, pagination);
		}
	}

	// Issue #338 Get alarmsStats & Filtered alarms Chart without pagination effect
	async filterAlarmsHistoryList_withoutPagination(filterParams: any, pagination?: boolean) {
		filterParams = this.mergeAlarmFilterOptions(filterParams);
		let filterParams2 = filterParams;
		filterParams2.pageSize = 0;
		// filterParams2.pageSize = this.appConfigService.appConfig.PDF_REPORT_ALARMS_COUNT;
		const response = await lastValueFrom(this.http.post<any>(ApiConstants.URL_ALARMS_HISTORY_REPORT, filterParams2));
		if (response.success === true) {
			this.alarmCount = response.totalcount;
			this.alarmsHistory = response.data;
			this.fetchAlarmsStatisticsChart(response.data, pagination);
		}
	}

	mergeAlarmFilterOptions(filter: any) {
		if (Utility.isNotEmpty(this.SelectedStatusesForTile)) {
			filter.alarmStatuses = [...new Set((filter.alarmStatuses || []).concat(this.SelectedStatusesForTile))];
		}
		if (Utility.isNotEmpty(this.SelectedSeriesForChartOne)) {
			filter.alarmTypes = [...new Set((filter.alarmTypes || []).concat(this.SelectedSeriesForChartOne))];
		}
		return filter;
	}

	/**
	 * filter sites table from filter
	 */
	async filterSitesList(filterParams: any) {
		filterParams = this.mergeSitesListFilters(filterParams);
		this.branchParams = { ...filterParams };
		const response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_BRANCH_STATUS_REPORT, { ...filterParams }));
		if (response.success === true) {
			this.branchDataSource = response.data;
			this.branchChangeNotification.next(false);
		}
	}

	mergeSitesListFilters(filterParams: any) {
		if (Utility.isNotEmpty(this.SelectedStatusesForTile)) {
			filterParams.branchStatuses = [...new Set((filterParams.branchStatuses || []).concat(this.SelectedStatusesForTile))];
		}
		if (Utility.isNotEmpty(this.SelectedSeriesForChartOne)) {
			filterParams.areaNames = [...new Set((filterParams.areaNames || []).concat(this.SelectedSeriesForChartOne))];
		}
		if (Utility.isNotEmpty(this.SelectedSeriesForChartTwo)) {
			filterParams.branchTypes = [...new Set((filterParams.branchTypes || []).concat(this.SelectedSeriesForChartTwo))];
		}
		return filterParams;
	}

	/**
	 * get list of all operator status
	 */
	async getOperatorStatuses() {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_OPERATORS_STATUSES));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
	 * Get list of branches
	 */
	async getBranches() {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_REGIONS));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
	 * get list of all sites related to supervisor
	 */
	async getSites() {
		const url = ApiConstants.URL_BRANCHES + '?detailed=false&type=map';
		const response = await lastValueFrom(this.http.get<ApiResponse>(url));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
	 * get list of all region related to supervisor
	 */
	async getAllBranchStatuses() {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_SITES_STATUSES));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
	 * get list of all sites related to supervisor
	 */
	async getSitesPriorityType() {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALL_PRIORITY_TYPES));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
	 * get list of all alarms type
	 */
	async getAlarmsTypes() {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS_TYPES));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
	 * get list of all severity type
	 */
	async getAlarmsSeverityTypes() {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS_SEVERITY_TYPES));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
	 * get list of all alarms status type
	 */
	async getAlarmsStatusTypes() {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS_STATUSES_TYPES));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
	 * changes table size to make it responsive
	 * @param tableID is used to change size of table
	 */
	changeTableSizeOnAddedFilter(tableID: any) {
		requestAnimationFrame(() => {
			const paginationDiv = document.getElementById('pagination-container');
			const paginationEle = paginationDiv && paginationDiv.getClientRects();
			const filter = document.getElementById('alarms-filter-component');
			const filterEle = filter.getClientRects();
			const parentElement = document.getElementById('stats-block');
			const parentHeight = parentElement.getClientRects();
			if (parentHeight && filterEle && paginationEle && paginationEle.length > 0) {
				const newHeight = parentHeight[0].height - (filterEle[0].height + (paginationEle[0].height * 2.5));
				document.getElementById(tableID).style.height = newHeight + 'px';
			}
		});
	}

	resetSelectedFilters() {
		this.SelectedStatusesForTile = [];
		this.SelectedSeriesForChartOne = [];
		this.SelectedSeriesForChartTwo = [];
	}

	// Update Analytical-dashboard-filter "initFilter()" function State
	public initFilterState(value: any) {
		this.initFilter = value;
		this.initFilterChangeNotification.next(this.initFilter);
	}

	public getInitFilterState() {
		return this.initFilter;
	}

	public getInitFilterStateChangeNotification() {
		return this.initFilterChangeNotification.asObservable();
	}

	// ASK AI
	async ask_AI(question?: string) {
		try {
			let URL = ApiConstants.URL_ASK_AI + '?question=' + question;
			return await lastValueFrom(this.http.get<ApiResponse>(URL));
		} catch (error) {
			const message = this.translate.instant('AI.ERROR');
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			this.alarmService.openSnackBar(message, confirmText);
		}

	}

}
