import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from './constants/route-constants';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/authentication.service';
import { AppConstants } from './constants/app-constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarUtil } from './util/snackbar-util';
import { AppConfigService } from './app-config.service';
import { LocationStrategy } from '@angular/common';
import { VideoStreamService } from './services/video-stream.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'ramsys';
	errorMessage: any;
	lang: any;

	// This code is for applying custom theme through some API etc.
	// We should keep this code commented unless it is needed.
	// customColors = {
	// 	mainBackground: '#17253c',
	// 	textColor: 'white',
	// 	boxesBackgroundColor: '#1b2d48',
	// 	tableHeaderColor: '#45acdd',
	// 	boxesHeaderBackgroundColor: '#0d57a0',
	// 	boxesHeaderBackgroundColorMedium: '#7d8a91',
	// 	boxesHeaderBackgroundColorLight: '#4c6475',
	// 	errorColor: '#FF0000'
	// };

	constructor(private router: Router, private authenticationService: AuthenticationService,
		public translate: TranslateService, public appConfigService: AppConfigService,
		private locationStrategy: LocationStrategy, public videoStreamService: VideoStreamService,
		public _snackBar: MatSnackBar) {
		translate.setDefaultLang('en');
		translate.addLangs(['en']);
		translate.use(this.translate.getBrowserLang());
	}

	ngOnInit(): void {
		this.router.events.subscribe((event) => {
			document.body.classList.remove('nb-theme-default');
		});
		this.setLanguage();
		const authToken = this.authenticationService.getAuthToken();
		if (authToken !== null && authToken !== '') {
			this.authenticationService.verifySession(authToken).then(
				Response => {
					if (AppConstants.SUCCESS in Response) {
						if (Response[AppConstants.SUCCESS] === true) {
							this.authenticationService.poll();
							this.goToHome();
						} else {
							this.goToLogin();
						}
					} else {
						this.goToLogin();
					}
				},
				Error => {
					this.errorMessage = Error.message;
					this.goToLogin();
				});
		} else {
			this.goToLogin();
		}

		SnackbarUtil.getSnackBarAddedLister().subscribe(error => this.showSnackBar(error));
		this.preventBackButton();

		// This code is for applying custom theme through some API etc.
		// We should keep this code commented unless it is needed.
		// document.documentElement.style.setProperty('--main_background', this.customColors.mainBackground);
		// document.documentElement.style.setProperty('--text_color', this.customColors.textColor);
		// document.documentElement.style.setProperty('--boxes_background_color', this.customColors.boxesBackgroundColor);
		// document.documentElement.style.setProperty('--table_header_color', this.customColors.tableHeaderColor);
		// document.documentElement.style.setProperty('--boxes_header_background_color', this.customColors.boxesHeaderBackgroundColor);
		// document.documentElement.style.setProperty('--boxes_header_background_color_medium',
		// 	this.customColors.boxesHeaderBackgroundColorMedium);
		// document.documentElement.style.setProperty('--boxes_header_background_color_light',
		// 	this.customColors.boxesHeaderBackgroundColorLight);
		// document.documentElement.style.setProperty('--error-message-color',
		// 	this.customColors.errorColor);

		// Detect opened tabs & set tabs count on local storage.
		// Incrementing tabs count:
	}

	async setLanguage() {
		this.lang = await this.authenticationService.getLanguage();
		if (this.lang) {
			this.useLanguage(this.lang);
		}
	}

	useLanguage(language: string): void {
		this.translate.use(language);
	}

	goToLogin() {
		this.router.navigate([RouteConstants.PAGES + RouteConstants.LOGIN]);
	}

	goToHome() {
		if (!this.router.url.startsWith(RouteConstants.PAGES + RouteConstants.HOME)) {
			this.router.navigate([RouteConstants.PAGES + RouteConstants.HOME]);
		}
	}

	showSnackBar(error: any) {
		// const message = error.error.errorMessages[0].message || (error.status + ' ' + error.statusText);
		// const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
		// this.openSnackBar(message, confirmText);
	}

	openSnackBar(message: string, action: string) {
		return this._snackBar.open(message, action, {
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: 'custom-snack-bar',
		});
	}

	// Disable back button
	preventBackButton() {
		history.pushState(null, null, window.location.href);
		this.locationStrategy.onPopState(() => {
			history.pushState(null, null, window.location.href);
		})
	}

	// Detecting Closing Broswer Events:
	public doUnload(): void {
		this.doBeforeUnload();
	}

	public doBeforeUnload(): void {
		// Call Stop Stream API
		// this.videoStreamService.stopStream();
	}

}
