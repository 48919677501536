<div class="row analytical-chart-selection-div">
	<div class="analytical-charts"
		[class.col-4]="dashboard.chartSelected !== dashboard.chartType.SITES"
		[class.col-3]="dashboard.chartSelected === dashboard.chartType.SITES">
		<div class="chart-selection-row"
			*ngFor="let chartMenu of chartMenus">
			<div class="chart-name">{{ chartMenu.label | translate }}</div>
			<div class="pie-chart col"
				[class.selectedChart]="dashboard.chartSelected == chartMenu.chartType"
				(click)="selectChart(chartMenu)">
				<img class="chart-select-icon" alt="chart"
					[src]="chartMenu.image">
			</div>
		</div>
	</div>
	<div class="pie-chart-block"
		[class.col-8]="dashboard.chartSelected !== dashboard.chartType.SITES"
		[class.col-9]="dashboard.chartSelected === dashboard.chartType.SITES">
		<p class="dashboard-text"
			[hidden]="dashboard.chartSelected !== dashboard.chartType.ALARM">
			{{ "ANALYTICAL_CHARTS.ALARMS_TYPE_CAPITAL" | translate }}
		</p>
		<p class="dashboard-text"
			[hidden]="dashboard.chartSelected !== dashboard.chartType.OPERATOR">
			{{ "ANALYTICAL_CHARTS.OPERATORS_CAPITAL" | translate }}
		</p>
		<p class="dashboard-text"
			[hidden]="dashboard.chartSelected !== dashboard.chartType.SITES">
			{{ "ANALYTICAL_CHARTS.SITES_CAPITAL" | translate }}
		</p>
		<div id="pie-chart"
			[hidden]="!isChartChanged"
			[class.alarms-chart]="dashboard.chartSelected == dashboard.chartType.ALARM"
			[class.operators-chart]="dashboard.chartSelected == dashboard.chartType.OPERATOR"
			[class.alarms-chart-legend]="dashboard.chartSelected == dashboard.chartType.ALARM"
			class="chart-div"></div>
		<div id="second-analytical-chart"
			[hidden]="!isSitesPriorityChartShown"
			class="chart-div second-chart-div"></div>

	</div>
</div>