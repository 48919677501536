import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Alarm } from 'src/models/alarm.model';
import { ApiResponse } from 'src/models/api-response.model';
import { Manager } from 'src/models/manager.model';
import { AlarmService } from '../services/alarm.service';
import { WorkflowService } from '../services/workflow.service';
import { Utility } from '../util/app-utils';
import { QuestionService } from './question/question.service';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmStatus } from '../enums/alarm-status.enum';
import { AppConstants } from '../constants/app-constants';
import { AuthenticationService } from '../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { SpeechService } from '../services/speech.service';
import { Subscription } from 'rxjs';
import { ChatService } from '../services/chat.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-workflow',
	templateUrl: './workflow.component.html',
	styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {
	actionPanelOpenState: any;
	alarmPanelOpenState: any;
	closeAll: any = false;
	managers: Manager[];
	question: any;
	alarm: Alarm;
	isActionHistoryExpanded = true;
	isAlarmHistoryExpanded = true;
	utility = Utility;
	isCommentBoxShown: boolean = false;
	isEscalationConfirmationShown = false;
	isSendMailBoxShown: boolean = false;
	isChatShown: boolean = false;
	previousComments: string = '';
	workflowComplete: boolean = false;
	alarmStatus = AlarmStatus;
	EscalatedAlarm: string = '';
	calledFrom = AppConstants.WORKFLOW_TEXT;
	public title: string = '';
	public header: string = '';
	alarmSelectedSubscriber: any;
	questionChangeSubscriber: any;

	// Smart Workflow
	showSmartWorkflow: boolean = false;
	// textToDisplay: string = '';
	actions: any[] = [];
	currentActionIndex = 0;
	currentCharIndex = 0;
	newActionSub: Subscription;
	@ViewChild('actionsList', { static: false }) private actionsList: ElementRef;
	percent: number = 0;

	constructor(
		private workflowService: WorkflowService,
		private alarmService: AlarmService,
		public dialog: MatDialog,
		private questionService: QuestionService,
		private stateService: UserSessionStateService,
		private authService: AuthenticationService,
		public translate: TranslateService,
		private speechService: SpeechService,
		public chatService: ChatService,
		public _snackBar: MatSnackBar
	) {
		// this.translate.get('WORKFLOW.ALARM_ANALYSIS').subscribe((res: string) => {
		// 	this.textToDisplay = res;
		// });
	}

	async ngOnInit() {
		/**
		 * Set constants for confirmation bar
		 */
		this.translate.get('ALARM_STATUS.ESCALATED').subscribe((res: string) => {
			this.EscalatedAlarm = res;
		});

		this.translate.get('WORKFLOW.WORKFLOW_ESCALATE_TITLE_TEXT').subscribe((res: string) => {
			this.title = res;
		});

		this.translate.get('WORKFLOW.WORKFLOW_ESCALATE_HEADER_TEXT').subscribe((res: string) => {
			this.header = res;
		});

		this.alarmSelectedSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe(async (alarm: Alarm) => {
			this.questionService.previousStatus = alarm ? alarm.status : '';
			this.alarm = undefined;
			this.managers = [];
			if (alarm) {
				this.showSmartWorkflow = (alarm.sensor && alarm.sensor.supportiveSystems && alarm.sensor.supportiveSystems.length > 0) ? true : false;
				this.alarm = alarm;
				this.workflowComplete = false;
				const comments = this.stateService.getComments(alarm.id);
				this.previousComments = comments ? comments : '';
				const wasCommentBoxOpen = this.stateService.getCommentBoxOpenState(alarm.id);
				this.setCommentBoxVisibility(wasCommentBoxOpen);
				const wasDirectEscalationOpen = this.stateService.getDirectEscalationOpenState(alarm.id);
				this.setDirectEscalationVisibility(wasDirectEscalationOpen);
				const wasSendMailBoxOpen = this.stateService.getSendMailBoxOpenState(alarm.id);
				this.setSendMailBoxVisibility(wasSendMailBoxOpen);
				const wasChatBoxOpen = this.stateService.getChatBoxOpenState(alarm.id);
				this.setChatBoxVisibility(wasChatBoxOpen);

				// Force re-rendering of question component, so that we don't see
				// same answers / comments that was filled for last alarm selection.
				this.question = null;
				this.questionService.getQuestion(this.alarm.id);
				this.getManagers();

				await this.workflowService.fetchActionHistory(alarm.id);
				let actionsHistory = this.workflowService.actionHistory;
				this.actions = actionsHistory.map(action => ({
					text: action.action,
					completed: false,
					displayedText: ''
				}));

				this.currentActionIndex = 0;
				this.currentCharIndex = 0;
				this.animateActions();
				this.scrollToBottom();
				this.updatePercentage(this.alarm.status);
			}
		});

		this.questionChangeSubscriber = this.questionService.getQuestionChangeListener().subscribe((question) => {
			if (question) {
				this.question = { ...question };
				if (this.question.type === 'list') {
					this.question.selectedId = this.question.data[0].id;
				}
			} else {
				this.workflowComplete = true;
			}
		});

		this.newActionSub = this.workflowService.getNewActionListener().subscribe((action: any) => {
			const newAction = {
				text: action,
				completed: false,
				displayedText: ''
			};
			this.actions.push(newAction);
			this.animateActions();
			this.scrollToBottom();
		});

		this.alarmStatusChangeListener();
	}

	ngOnDestroy() {
		this.alarmSelectedSubscriber = Utility.Unsubscribe(this.alarmSelectedSubscriber);
		this.questionChangeSubscriber = Utility.Unsubscribe(this.questionChangeSubscriber);
		this.newActionSub = Utility.Unsubscribe(this.newActionSub);
	}

	/**
	 * Fetches list of managers of the branch from where the alarm is activated.
	 */
	async getManagers() {
		this.managers = [];
		const response: ApiResponse = await this.workflowService.getManagers(this.alarm.branch.id);
		if (response.success === true) {
			this.managers = response.data;
			for (let i = 0; i < this.managers.length; i++) {
				if (this.managers[i].designation === 'Guard') {
					this.managers[i].designation = this.translate.instant('WORKFLOW.GUARD_ROLE');
				} else if (this.managers[i].designation === 'Branch Guard') {
					this.managers[i].designation = this.translate.instant('WORKFLOW.GUARD_ROLE');
				} else if (this.managers[i].designation === 'Manager') {
					this.managers[i].designation = this.translate.instant('WORKFLOW.MANAGER_ROLE');
				}
			}
		}
	}

	/**
	 * Call service method to change status of alarm to 'Escalated'.
	 */
	escalateAlarm() {
		const data = {
			action: this.translate.instant('WORKFLOW.ALARM_ESCALATED'),
			step: this.translate.instant('WORKFLOW.ALARM_ESCALATED'),
			timeStamp: new Date().getTime(),
			user: {
				id: this.authService.currentUserSession.activeProfile.client._id,
				firstName: this.authService.currentUserSession.firstName,
				lastName: this.authService.currentUserSession.lastName,
				userName: this.authService.currentUserSession.role.name
			}
		};
		this.workflowService.addActionHistory(data);
		this.alarmService.changeStatusAlarmNotification(this.alarmService.selectedAlarm.id, this.translate.instant('ALARM_STATUS.ESCALATED'));
		this.alarmService.changeAlarmHistoryStatus(this.alarmService.selectedAlarm.id, this.translate.instant('ALARM_STATUS.ESCALATED'));
		if (this.authService.hasRole(AppConstants.Admin)) {
			this.alarmService.changeAlarmStatus(this.alarmService.selectedAlarm.id, this.translate.instant('ALARM_STATUS.ESCALATED'));
		} else {
			this.alarmService.escalateAlarm(this.alarm);
		}
		this.isEscalationConfirmationShown = false;
		this.setDirectEscalationVisibility(false)
		this.setCommentBoxVisibility(false);
		this.setSendMailBoxVisibility(false);
		this.setChatBoxVisibility(false);
		this.question = null;
	}

	/**
	 * Call service method to post comment.
	 * @param comment Comment text.
	 */
	addComment(comment: string) {
		if (comment && comment !== '') {
			this.setCommentBoxVisibility(false);
			this.workflowService.addWorkflowComment(this.alarm.id, comment);
		}
	}

	onCommentCancel() {
		// const comments = this.stateService.getComments(this.alarm.id);
		// this.stateService.saveComments(this.alarm.id, comments);
		// this.previousComments = comments ? comments : '';
		this.setCommentBoxVisibility(false);
	}

	/**
	 * Saves comment state when a high priority alarm is activated.
	 * @param comment Comment text.
	 */
	onCommentsTextChange(comment: string) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.stateService.saveComments(alarmId, comment);
		this.stateService.saveCommentBoxOpenState(alarmId, this.isCommentBoxShown);
	}

	private setCommentBoxVisibility(isVisible: boolean) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.isCommentBoxShown = !!isVisible;
		this.stateService.saveCommentBoxOpenState(alarmId, this.isCommentBoxShown);
	}

	private setDirectEscalationVisibility(isVisible: boolean) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.isEscalationConfirmationShown = !!isVisible;
		this.stateService.saveDirectEscalationOpenState(alarmId, this.isEscalationConfirmationShown);
	}

	private setSendMailBoxVisibility(isVisible: boolean) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.isSendMailBoxShown = !!isVisible;
		this.stateService.saveSendMailBoxOpenState(alarmId, this.isSendMailBoxShown);
	}

	private setChatBoxVisibility(isVisible: boolean) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.isChatShown = !!isVisible;
		// this.stateService.saveChatBoxOpenState(alarmId, this.isChatShown);
	}

	/**
	 * Displays comment box.
	 */
	showCommentBox() {
		this.alarmService.checkSelectedAlarmAssigned();
		this.setCommentBoxVisibility(true);
		const alarmId = this.alarmService.selectedAlarm.id;
		const comments = this.stateService.getComments(alarmId);
		this.previousComments = comments ? comments : '';
		this.setDirectEscalationVisibility(false);
		this.setSendMailBoxVisibility(false);
		this.setChatBoxVisibility(false);
		this.showSmartWorkflow = false;
	}

	/**
	 * Displays escalation confirmation message.
	 */
	showEscalationConfirmation() {
		this.alarmService.checkSelectedAlarmAssigned();
		this.setDirectEscalationVisibility(true);
		this.setCommentBoxVisibility(false);
		this.setSendMailBoxVisibility(false);
		this.setChatBoxVisibility(false);
		this.showSmartWorkflow = false;
	}

	// Displays comment box.
	showSendMailBox() {
		this.alarmService.checkSelectedAlarmAssigned();
		this.setSendMailBoxVisibility(true);
		this.setCommentBoxVisibility(false);
		this.setDirectEscalationVisibility(false);
		this.setChatBoxVisibility(false);
		this.showSmartWorkflow = false;
	}

	// Displays Chat box.
	async showChatBox() {
		let res = await this.chatService.getAlarmRoom(this.alarm.id);
		if (res && res.success === true && res.data.length !== 0) {
			await this.getChatRoom(this.alarm);
		} else {
			this.alarmService.checkSelectedAlarmAssigned();
			this.setChatBoxVisibility(true);
			this.setSendMailBoxVisibility(false);
			this.setCommentBoxVisibility(false);
			this.setDirectEscalationVisibility(false);
			this.showSmartWorkflow = false;
		}
	}

	async getChatRoom(alarm: any) {
		let res = await this.chatService.getUserRooms(alarm.id);
		if (res && res.success === true && res.data.rooms.length !== 0) {
			this.alarmService.checkSelectedAlarmAssigned();
			this.setChatBoxVisibility(true);
			this.setSendMailBoxVisibility(false);
			this.setCommentBoxVisibility(false);
			this.setDirectEscalationVisibility(false);
			this.showSmartWorkflow = false;
		} else {
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			const message = this.translate.instant('CHAT.NO_ACCESS') + ' : ' + alarm.shortAlarmId;
			let snackBarRef = this.openSnackBar(message, confirmText);
			snackBarRef.afterDismissed().subscribe(() => { });
		};
	}

	openSnackBar(message: string, action: string) {
		return this._snackBar.open(message, action, {
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: 'custom-snack-bar',
		});
	}

	toggleAudio() {
		this.speechService.allowAudio = !this.speechService.allowAudio;
	}

	animateActions() {
		if (this.currentActionIndex < this.actions.length) {
			const currentAction = this.actions[this.currentActionIndex];
			if (this.currentCharIndex < currentAction.text.length) {
				currentAction.displayedText += currentAction.text[this.currentCharIndex];
				this.currentCharIndex++;
				setTimeout(() => {
					this.animateActions();
				}, 50);
			} else {
				currentAction.completed = true;
				this.currentActionIndex++;
				this.currentCharIndex = 0;
				if (this.currentActionIndex < this.actions.length) {
					setTimeout(() => {
						this.animateActions();
					}, 1500);
				}
			}
		}
	}

	alarmStatusChangeListener() {
		this.alarmService.getChangeAlarmStatusNotification().subscribe((selectedAlarmId) => {
			if (this.alarm.id === selectedAlarmId) {
				let alarmStatus = this.alarmService.getAlarmStatus();
				this.updatePercentage(alarmStatus);
			}
		});
	}

	updatePercentage(status: string) {
		switch (status) {
			case AlarmStatus.ACKNOWLEDGED:
				this.percent = 25;
				break;
			case AlarmStatus.VERIFIED:
				this.percent = 50;
				break;
			case AlarmStatus.ESCALATED:
				this.percent = 75;
				break;
			case AlarmStatus.MAINTENANCE:
				this.percent = 75;
				break;
			case AlarmStatus.RESOLVED:
				this.percent = 100;
				break;
			case AlarmStatus.FALSE:
				this.percent = 100;
				break;
			default:
				this.percent = 0;
		}
	}

	toggle() {
		this.showSmartWorkflow = !this.showSmartWorkflow;
	}

	// Method to scroll to the bottom of the actions list
	private scrollToBottom(): void {
		setTimeout(() => {
			if (this.actionsList && this.actionsList.nativeElement) {
				this.actionsList.nativeElement.scrollTop = this.actionsList.nativeElement.scrollHeight;
			}
		}, 0);
	}

}
