<div class="box-header d-flex align-items-center">
	<div class="icon d-flex align-items-center">
		<img class="alert-icon" alt="list"
			[src]="utility.getImagePath('list.png')">
	</div>
	<div class="ml-2">
		{{ "CONFIDENCE_MONITORING.SITES" | translate }}
	</div>
</div>

<div class="sites-stats-div">
	<div class="filter-content">
		<app-alarms-filter [filterType]="filterType"
			(changeHeight)="changeTableSize()"
			[isConfidenceMonitoring]="isConfidenceMonitoring"></app-alarms-filter>
	</div>

	<div>
		<app-sites-table [displayedColumns]="displayedColumns"></app-sites-table>
	</div>
</div>