import { Subject } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmService } from '../services/alarm.service';
import { ConfidenceMonitoringService } from '../services/confidence-monitoring.service';
import { Utility } from '../util/app-utils';
import { Subscription } from 'rxjs';
import { Alarm } from "src/models/alarm.model";
import { MapService } from '../services/map.service';

enum AssetDocType {
	image = 'img',
	pdf = 'pdf'
}

@Component({
	selector: 'app-asset-viewer',
	templateUrl: './asset-viewer.component.html',
	styleUrls: ['./asset-viewer.component.scss']
})
export class AssetViewerComponent implements OnInit {
	public shouldShowImg = false;
	public shouldShowMap = true;
	public shouldShowPDF = false;
	public assetUrl: string = null;
	utility = Utility;

	public containZone: boolean = false;
	zoneStateSubscription: Subscription;
	selectedAlarmSubscriber: Subscription;
	sitesChangedSubscriber: Subscription;
	selectedSite: any;

	@Input() assetDoc: Subject<any>;

	constructor(
		private alarmService: AlarmService, public mapService: MapService,
		private userSessionStateService: UserSessionStateService,
		public confidenceMonitoringService: ConfidenceMonitoringService) {
		this.assetDoc = null;
	}

	ngOnInit() {
		this.assetDoc.subscribe((asset) => {
			this.initDefaults();
			// If asset is null or undefined it mean's there is no asset doc selected
			if (asset) {
				this.shouldShowMap = false;
				this.shouldShowImg = asset.type === AssetDocType.image;
				this.shouldShowPDF = asset.type === AssetDocType.pdf;
				this.assetUrl = asset.url;
			}
		});

		this.confidenceMonitoringService.getAssetSelectedNotification().subscribe((asset) => {
			this.initDefaults();
			// If asset is null or undefined it mean's there is no asset doc selected
			if (asset) {
				this.shouldShowMap = false;
				this.shouldShowImg = asset.type === AssetDocType.image;
				this.shouldShowPDF = asset.type === AssetDocType.pdf;
				this.assetUrl = asset.url;
			}
		});

		this.zoneStateSubscription = this.userSessionStateService.getZoneStateChangeNotification().subscribe((isContainZone) => {
			if (isContainZone) {
				this.containZone = true;
			}
			else {
				this.containZone = false;
			}
		});

		this.selectedAlarmListener();
		this.selectedSiteListener();
	}

	public initDefaults() {
		// By default show map view
		this.shouldShowMap = true;
		this.shouldShowImg = false;
		this.shouldShowPDF = false;
		this.assetUrl = null;
		this.containZone = false;
	}

	close() {
		const selectedAlarm = this.alarmService.selectedAlarm;
		this.shouldShowMap = true;
		// this.containZone = false;
		this.shouldShowPDF = false;
		this.shouldShowImg = false;
		if (selectedAlarm) {
			const alarmId = this.alarmService.selectedAlarm.id;
			this.userSessionStateService.saveSelectedAssetId(alarmId, null);
		}
		// Send/Change state after closing the asset while using Monitoring Mode 
		// this.mapService.modifyAssetState(selectedAlarm); 
		const confidenceMonitoringMode = this.userSessionStateService.getConfidenceMonitoringState();
		if (confidenceMonitoringMode) {
			this.mapService.modifyAssetState(this.selectedSite);
		}
	}

	ngOnDestroy(): void {
		this.zoneStateSubscription.unsubscribe();
		this.selectedAlarmSubscriber.unsubscribe();
		this.sitesChangedSubscriber.unsubscribe();
	}

	//  called when alarm is selected
	selectedAlarmListener() {
		this.selectedAlarmSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
			if (alarm) {
				this.close();
			}
		});
	}


	// called when site is selected in Confidence Monitoring mode
	selectedSiteListener() {
		this.sitesChangedSubscriber = this.confidenceMonitoringService.getSitesChangedNotification().subscribe(async (branch: any) => {
			this.initDefaults();
		    this.selectedSite = branch;
			this.mapService.modifyAssetState(branch);
		});
	}
}
