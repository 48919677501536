import { Injectable } from '@angular/core';
import { AppSessionStorage } from 'src/app/util/app-session-storage-utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConstants } from 'src/app/constants/api-constants';
import { User } from 'src/models/user';
import { AppLocalStorage } from 'src/app/util/app-local-storage-utils';
import { ApiResponse } from 'src/models/api-response.model';
import { UserSession } from 'src/models/session.model';
import { AppConstants } from '../constants/app-constants';
import { RouteConstants } from '../constants/route-constants';
import { Router } from '@angular/router';
import { SocketService } from './socket.service';
import { AppConfigService } from '../app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	sessionInterval: number;
	sessionValidityCheckTimer: any = null;
	token: string;
	user: User;
	currentUserSession: UserSession = null;

	private _cachedOperators = null;

	closeChatWindow = new Subject<any>();
	enableReply_chat: boolean = false;

	constructor(
		private http: HttpClient,
		private router: Router,
		private socketService: SocketService,
		public translate: TranslateService,
		private appConfigService: AppConfigService,
		public _snackBar: MatSnackBar
	) {
		const sessionIntervalInitializer = setInterval(() => {
			const APP_CONFIG = this.appConfigService;
			if (APP_CONFIG && APP_CONFIG.appConfig && APP_CONFIG.appConfig.SESSION_INTERVAL) {
				this.sessionInterval = APP_CONFIG.appConfig.SESSION_INTERVAL;
				this.poll();
				clearInterval(sessionIntervalInitializer);
			}
		}, AppConstants.APP_CONFIG_INIT_TIMER);
	}

	async verifyUser(username: string, password: string) {
		const body = { 'username': username, 'password': password };
		let response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_LOGIN, body));
		if (response && response.success === true) {
			// this.setSession(response.data);
			// this.setUser(response.data);
		}
		return response;
	}

	// TODO: delete direct authToken injection & create a separate HTTP_INTERCEPTORS
	verifySession(authToken: string) {
		return lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_SESSION_ACTIVE, {
			headers: new HttpHeaders().set('session_token', authToken)
		}));
	}

	getAuthToken() {
		if (this.token === undefined || this.token === '') {
			this.token = AppLocalStorage.getToken();
		}
		return this.token;
	}

	setAuthToken(token: string) {
		this.token = token;
		AppLocalStorage.setToken(token);
	}

	getUser() {
		return this.http.get(ApiConstants.URL_SESSION);
	}

	/**
	 * Get list of operators
	 */
	async getUsers() {
		let response;
		if (this.currentUserSession && this.currentUserSession.role.name === AppConstants.Admin) {
			response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ADMIN_USERS));
		} else {
			response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_RELATED_OPERATORS));
		}
		if (response.success === true) {
			this._cachedOperators = response.data;
		}
		return this._cachedOperators;
	}

	/**
	 * Get list of the not deactivated operators (Active only)
	 */
	async getSupervisorActiveUsers() {
		let response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ACTIVE_RELATED_OPERATORS));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
		* Get list of Online operators only
		*/
	async getOnlineUsers() {
		let response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ONLINE_OPERATORS));
		if (response.success === true) {
			return response.data;
		}
	}

	/**
	* Get list of all system users (operators, supervisors, excutive & guards).
	*/
	async getSystemUsers() {
		let response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_SYSTEM_USERS));
		if (response.success === true) {
			return response.data;
		}
	}

	setUser(user: User) {
		this.user = user;
		AppSessionStorage.setUser(user);
	}

	logoutUser() {
		const authToken = this.token;
		this.token = undefined;
		AppLocalStorage.logoutUser();
		AppSessionStorage.logoutUser();
		return lastValueFrom(this.http.get(ApiConstants.URL_LOGOUT, {
			headers: new HttpHeaders().set('session_token', authToken)
		}));
	}

	setSession(session: UserSession) {
		this.currentUserSession = session;
		AppSessionStorage.setUser(this.currentUserSession);
		localStorage.setItem(AppConstants.ROLE, this.currentUserSession.role.name);
	}

	getSession() {
		return this.currentUserSession;
	}

	getUserTimezone() {
		return this.currentUserSession ? this.currentUserSession.timeZone : 'Asia/Dubai';
	}

	public getUserName() {
		return this.currentUserSession && this.currentUserSession.userName ? this.currentUserSession.userName : null;
	}

	poll() {
		if (this.sessionInterval) {
			this.sessionValidityCheckTimer = setInterval(async () => {
				const authToken = localStorage.getItem('authToken');
				if (authToken !== undefined && authToken !== null) {
					try {
						const response: ApiResponse = await this.verifySession(localStorage.getItem('authToken'));
						if (!(response && response[AppConstants.SUCCESS] === true)) {
							this.logout();
						}
					} catch (Error) {
						this.logout();
					}
				} else {
					clearInterval(this.sessionValidityCheckTimer);
				}
			}, this.sessionInterval);
		}
	}

	/**
	 * End session.
	 * On successful session logout navigates to Login page.
	 */
	logout() {
		// localStorage.removeItem('authToken');
		clearInterval(this.sessionValidityCheckTimer);
		// localStorage.removeItem('socketId');
		// localStorage.removeItem('prevAlarm');
		this.socketService.closeSocket();
		this.closeChatWindowTrigger();
		this._snackBar.dismiss();
		this.logoutUser().then(
			(Response: any) => {
				if (Response[AppConstants.SUCCESS] === true) {
					this.router.navigate([RouteConstants.PAGES + RouteConstants.LOGIN]);
				}
				else if (Response[AppConstants.SUCCESS] === false) {
					this.router.navigate([RouteConstants.PAGES + RouteConstants.LOGIN]);
				}
			},
			Error => {
				this.router.navigate([RouteConstants.PAGES + RouteConstants.LOGIN]);
			});
	}

	closeChatWindowTrigger() {
		this.closeChatWindow.next("");
	}

	closeChatWindowListner() {
		return this.closeChatWindow.asObservable();
	}

	hasRole(role: string) {
		const storedRole = localStorage.getItem('role') || '""';
		return storedRole ? storedRole === role : null;
	}

	getClientLogo() {
		return this.http.get(ApiConstants.URL_GET_CLIENT_LOGO);
	}

	/**
 * Get System Language as configured from backend
 */
	async getLanguage() {
		let response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_GET_SYSTEM_LANGUAGE));
		if (response && response.success === true) {
			AppLocalStorage.setLanguage(response.language);
			return response.language;
		} else {
			// Fallback to the browser's language if the API call fails
			return navigator.language;
		}
	}

	async getSystemInfo() {
		let response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_SYSTEM_INFO));
		if (response && response.success === true) {
			this.enableReply_chat = response.data.closedAlarmChat;
			return response.data;
		}
	}
}
