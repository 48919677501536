import { Component, OnInit } from '@angular/core';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { ConfidenceMonitoringService } from 'src/app/services/confidence-monitoring.service';
import { Utility } from 'src/app/util/app-utils';

@Component({
	selector: 'app-monitoring-sites',
	templateUrl: './monitoring-sites.component.html',
	styleUrls: ['./monitoring-sites.component.scss']
})
export class MonitoringSitesComponent implements OnInit {
	displayedColumns = [
		'Sites',
		'Region',
		'Site Priority',
		'Status',
		'Asset'
	];
	filterType = 'SITES';
	isConfidenceMonitoring = true;
	SITES_TABLE_ID = 'sites-table-container';
	utility = Utility;

	constructor(
		public analyticalDashboard: AnalyticalDashboardService,
		public confidenceMonitoringService: ConfidenceMonitoringService
	) { }

	ngOnInit() { }

	changeTableSize() {
		this.confidenceMonitoringService.changeTableSizeOnAddedFilter(this.SITES_TABLE_ID);
	}

}
